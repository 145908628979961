import checks from "assets/images/backgrounds/checks.png";
import backArrow from "assets/images/icons/back-arrow.png";
import ranks from "assets/images/pages/insights/ranks.png";
import squareBg from "assets/images/pages/insights/square.png";
import Avatar from "components/Avatar";
import TableCard from "components/Cards/TableCard";
import AmountRaised from "components/Pages/Insights/AmountRaised";
import AverageDonation from "components/Pages/Insights/AverageDonation";
import BiggestDonation from "components/Pages/Insights/BiggestDonation";
import LockedCard from "components/Pages/Insights/LockedCard";
import NumberOfDonors from "components/Pages/Insights/NumberOfDonors";
import { AuthContext } from "context/Auth";
import { InsightsContext } from "context/Data/Insights";
import dayjs from "dayjs";
import useDocumentTitle from "hooks/useDocumentTitle";
import useLayoutConfig from "hooks/useLayoutConfig";
import { useContext, useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";

const Insights = () => {
  useLayoutConfig("white", true);
  const { profile } = useContext(AuthContext);
  const { insights, isLoading } = useContext(InsightsContext);

  const [averageDonation, setAverageDonation] = useState(0);
  const [totalDonors, setTotalDonors] = useState(0);
  useDocumentTitle("Muskurahat Foundation | Insights");
  const [isNewAccount, setIsNewAccount] = useState(false);
  const [displayInsights, setDisplayInsights] = useState(true);

  const overlayStyle = {
    backgroundPosition: "center center",
    borderRadius: "50px 50px 35px 35px",
    backgroundColor: "#fff",
    backgroundImage: `url(${checks})`,
    padding: "20px",
    boxShadow: "rgba(0, 0, 0, 0.25) -1px -1px 4px",
  };

  const navigate = useNavigate();

  const handleBackClick = () => {
    navigate(-1);
  };

  const getRank = () => {
    let rank = "-";
    if (profile?.achieved > 7000) {
      rank = "Top 15";
    } else if (profile?.achieved > 5000) {
      rank = "Top 20";
    } else if (profile?.achieved > 3000) {
      rank = "Top 25";
    }
    return rank;
  };

  useEffect(() => {
    if (!profile) return;
    const diffDays = dayjs().diff(dayjs(profile?.createdAt.toDate()), "day");
    setIsNewAccount(diffDays < 6);
    console.log(diffDays);
  }, []);

  useEffect(() => {
    if (isNewAccount) {
      setDisplayInsights(false);
    }
  }, [isNewAccount]);
  console.log(displayInsights, isNewAccount);
  return (
    <>
      <div className="flex justify-center items-center mt-10 relative">
        <img
          src={backArrow}
          alt=""
          onClick={handleBackClick}
          className="absolute left-5 top-1/2 -translate-y-1/2 w-5"
        />
        <h1 className="font-bold text-secondary text-xl text-center flex-grow">
          INSIGHTS
        </h1>
      </div>

      {isLoading ? null : (
        <div className="relative">
          <div
            className={`flex justify-center items-center pt-4 max-w-[310px] m-auto -mb-12 relative ${
              // eslint-disable-next-line no-constant-condition
              displayInsights ? "" : "blur-sm"
            }`}
          >
            <img
              src={ranks}
              alt="Top Image"
              className="max-w-[310px] max-h-full w-full"
            />
            <div className="absolute flex items-center justify-center bottom-[134px] text-white text-sm">
              {insights["top-ranking"] &&
                insights["top-ranking"][0].name.split(" ")[0]}
            </div>
            <div className="absolute flex left-[17px] bottom-[114px] text-white text-sm">
              {insights["top-ranking"] &&
                insights["top-ranking"][1].name.split(" ")[0]}
            </div>
            <div className="absolute flex right-[28px] bottom-[104px] text-white text-sm">
              {insights["top-ranking"] &&
                insights["top-ranking"][2].name.split(" ")[0]}
            </div>
          </div>
          {!displayInsights && (
            <LockedCard title1="TOP 10" title2="LEADERBOARD" />
          )}
        </div>
      )}
      <div className="w-full h-auto pt-8 relative" style={overlayStyle}>
        <div
          className={`p-5 rounded-[20px] h-full`}
          style={{ backgroundImage: `url('${squareBg}')` }}
        >
          <div className="flex items-center justify-between text-lg font-bold ">
            <div className="">
              <Avatar avatarNumber={profile?.avatar} borderColor="" size={60} />
              <p className="text-center text-[10px]">
                {profile?.referenceCode}
              </p>
            </div>
            <div className="text-black text-2xl font-bold ">
              <div className="flex pb-4 justify-between">
                <div>
                  <h4 className="text-sm pr-11 font-bold">Your current rank</h4>
                </div>
                <div className="flex justify-between ">
                  <div>
                    <p className="text-primary text-xs pr-1">{getRank()}</p>
                  </div>
                </div>
              </div>
              <div className="flex justify-between">
                <div>
                  <p className="text-xs font-medium">Biggest donation</p>
                </div>
                <div>
                  <p className="text-primary text-xs">
                    ₹ {profile?.maxDonation || 0}
                  </p>
                </div>
              </div>
              <div className="flex justify-between">
                <div>
                  <p className="text-xs font-medium">Total donors</p>
                </div>
                <div>
                  <p className="text-primary text-xs">{totalDonors}</p>
                </div>
              </div>
              <div className="flex justify-between">
                <div>
                  <p className="text-xs font-medium">Average donation</p>
                </div>
                <div>
                  <p className="text-primary text-xs">₹ {averageDonation}</p>
                </div>
              </div>
              <div className="text-right mt-5">
                <h4 className="text-sm font-bold">
                  {profile?.achieved < 1000 ||
                  profile.achieved === undefined ? (
                    "Level Up To Achieve Higher Ranks"
                  ) : (
                    <>
                      Level achieved -
                      <span className="text-tertiary underline underline-offset-4">
                        {profile?.achieved < 5000
                          ? "STAR"
                          : profile?.achieved < 15000
                          ? "NINJA"
                          : profile?.achieved < 30000
                          ? "WIZARD"
                          : "LEGEND"}
                      </span>
                    </>
                  )}
                </h4>
              </div>
            </div>
          </div>
        </div>
        {isLoading ? null : displayInsights ? (
          <div className="flex flex-col space-y-4 items-center mb-9 pt-4">
            {insights &&
              insights["top-ranking"].map((entry, index) => (
                <TableCard key={index} index={index} {...entry} />
              ))}
          </div>
        ) : null}
        <div className={`mb-5 ${displayInsights ? "" : "mt-5"}`}>
          <AmountRaised isLocked={!displayInsights} />
        </div>
        <div className="flex mb-5 gap-5 items-stretch">
          <div className="w-[calc(50%-10px)]">
            <NumberOfDonors
              setTotalDonors={setTotalDonors}
              isLocked={!displayInsights}
            />
          </div>
          <div className="w-[calc(50%-10px)]">
            <BiggestDonation isLocked={!displayInsights} />
          </div>
        </div>
        <div className="mb-5">
          <AverageDonation
            averageDonation={averageDonation}
            setAverageDonation={setAverageDonation}
            isLocked={!displayInsights}
          />
        </div>
      </div>
    </>
  );
};

export default Insights;
