import Bulb from "assets/images/icons/bulb.png";
import UserLoader from "components/UserLoader";
import {
  convertTransactionsToGraphData,
  getInternshipDaysCompleted,
} from "config/helpers";
import { AuthContext } from "context/Auth";
import { DonationsContext } from "context/Data/Donations";
import { useAnimate, useInView } from "framer-motion";
import { useContext, useEffect, useRef, useState } from "react";
import Chart from "react-apexcharts";
import LockedCard from "../LockedCard";

const useAnimateOpacity = (isHintVisible) => {
  const [scope, animate] = useAnimate();

  useEffect(() => {
    animate(
      ".animate-opacity",
      { opacity: isHintVisible ? 1 : 0 },
      { duration: 0.2 },
    );
  }, [isHintVisible]);
  return scope;
};

const AmountRaised = ({ isLocked }) => {
  const [graphData, setGraphData] = useState([]);
  const [defaultData, setDefaultData] = useState([]);
  const numberOfDays = getInternshipDaysCompleted();
  const { profile } = useContext(AuthContext);
  const [isHintVisible, setIsHintVisibile] = useState(false);
  const scope = useAnimateOpacity(isHintVisible);

  const { donations, isLoading } = useContext(DonationsContext);

  useEffect(() => {
    if (!donations) return;
    const data = convertTransactionsToGraphData(donations, profile.createdAt);
    setGraphData(data);
    setDefaultData(
      [
        52, 309, 1720, 2420, 2910, 3320, 3590, 4300, 4510, 4590, 4720, 4800,
        4920, 5110, 5210, 5420, 5510, 5630, 5820, 6040, 6210, 6520, 6700, 6810,
        6990, 7210, 7420, 7590, 7710, 7800, 7800, 7800, 7800, 7800, 7800, 7800,
        7800, 7800, 7800, 7800, 7800, 7800, 7800, 7800, 7800, 7800, 7800, 7800,
        7800, 7800, 7800, 7800, 7800, 7800, 7800, 7800, 7800, 7800, 7800, 7800,
        7800, 7800, 7800, 7800, 7800, 7800, 7800, 7800, 7800, 7800, 7800, 7800,
        7800, 7800, 7800, 7800, 7800, 7800, 7800, 7800, 7800, 7800, 7800, 7800,
        7800, 7800, 7800, 7800, 7800, 7800, 7800, 7800, 7800, 7800,
      ].slice(0, numberOfDays),
    );
  }, [donations]);

  const toggleHint = () => {
    setIsHintVisibile(!isHintVisible);
  };

  const ref = useRef(null);
  const isInView = useInView(ref, { margin: "0px 0px -80px 0px", once: true });

  return (
    <div
      ref={scope}
      className={`p-5 bg-gray-200 bg-[url('assets/images/pages/insights/square.png')] bg-cover shadow-[1px_3px_8px_0_rgba(0,0,0,0.25)] rounded-[20px] h-full`}
    >
      <div className="flex items-center justify-between text-secondary text-lg font-bold">
        <div className="text-left" ref={ref}>
          <h3 className="text-xl">AMOUNT RAISED</h3>
        </div>
        <div className="relative">
          <img onClick={toggleHint} className="w-[47px] mr-1" src={Bulb} />
          <div className="animate-opacity absolute text-black font-normal text-[8px] w-80 border-tertiary border-solid border-[1px] leading-4 -top-[85px] -right-5 bg-white shadow-[1px_3px_8px_0_rgba(0,0,0,0.25)] rounded-xl p-3 after:absolute after:right-10 after:top-full after:-mt-[6px] after:w-3 after:h-3 after:border-[1px] after:border-solid after:border-tertiary after:rotate-45 after:bg-white after:border-t-0 after:border-l-0">
            Total funds raised by you compared to{" "}
            <span className="font-medium">Average Funds</span> raised by an
            intern from your batch
          </div>
        </div>
      </div>

      {isLoading ? (
        <UserLoader />
      ) : (
        <div className="relative">
          <div className={isLocked ? "blur-sm" : ""}>
            {isInView && (
              <Chart
                type="area"
                width="100%"
                series={[
                  {
                    name: "Funds raised by you",
                    data: graphData,
                  },
                  {
                    name: "Per intern average",
                    data: defaultData,
                  },
                ]}
                options={{
                  colors: ["#E22226", "#564292"],

                  chart: {
                    height: 350,
                    type: "area",
                    toolbar: false,
                  },
                  tooltip: {
                    x: {
                      formatter: function (val) {
                        return val;
                      },
                    },
                  },
                  dataLabels: {
                    enabled: false,
                  },
                  stroke: {
                    curve: "smooth",
                    width: 1,
                  },
                  xaxis: {
                    type: "category",
                    labels: {
                      formatter: function (val) {
                        return val === 1 || val === graphData.length ? val : "";
                      },
                    },
                  },
                  legend: {
                    show: false,
                  },
                }}
              />
            )}
            <div className="border-t-[1px] border-black flex justify-between pt-3">
              <div className="flex text-[10px] items-center gap-2">
                <div className="w-4 h-4 rounded-full bg-primary"></div>

                <div>
                  <div>Funds raised by you</div>
                  <div className="text-primary font-semibold">
                    ₹{/* add all values from array */}
                    {donations
                      .map((donation) => donation.amount)
                      .reduce((acc, curr) => acc + curr, 0)}
                  </div>
                </div>
              </div>
              <div className="flex text-[10px] items-center gap-2">
                <div className="w-4 h-4 rounded-full bg-secondary"></div>

                <div>
                  <div>Per intern average</div>
                  <div className="text-secondary font-semibold">
                    ₹{" "}
                    {
                      // round to integer
                      Math.round(
                        defaultData.reduce((acc, curr) => acc + curr, 0) /
                          defaultData.length,
                      )
                    }
                  </div>
                </div>
              </div>
            </div>
          </div>
          {isLocked && <LockedCard title1="AMOUNT" title2="RAISED" />}
        </div>
      )}
    </div>
  );
};

export default AmountRaised;
