import backArrow from "assets/images/icons/back-arrow.png";
import whiteBackArrow from "assets/images/icons/back-arrow-white.png";
import { LayoutContext } from "context/Layout";
import { useContext, useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import star from "assets/images/pages/rewards/star.png";
import ninja from "assets/images/pages/rewards/ninja.png";
import wizard from "assets/images/pages/rewards/wizard.png";
import legend from "assets/images/pages/rewards/legend.png";
import background from "assets/images/pages/rewards/background.webp";

import { Swiper, SwiperSlide, useSwiper } from "swiper/react";
import useLayoutConfig from "hooks/useLayoutConfig";
import useDocumentTitle from "hooks/useDocumentTitle";

const Navigation = ({ activeIndex }) => {
  const swiper = useSwiper();

  const getClasses = (activeIndex, item) => {
    let classes = "";
    if (activeIndex === item) {
      classes += `bg-transparent border-2 border-solid`;
      if (activeIndex === 0) {
        classes += ` border-[#000000]`;
      }
    } else {
      if (activeIndex === 0) {
        classes += `bg-black`;
      } else {
        classes += `bg-white`;
      }
    }
    return classes;
  };

  // after every 4 seconds, slide to next slide
  useEffect(() => {
    const interval = setInterval(() => {
      swiper?.isEnd ? clearInterval(interval) : swiper?.slideNext();
    }, 4000);
    return () => clearInterval(interval);
  }, [swiper]);

  return (
    <div className="mt-5 flex gap-2 justify-center">
      {[0, 1, 2, 3].map((item, index) => (
        <button
          onClick={() => swiper.slideTo(item)}
          key={index}
          className={`w-2 h-2 rounded-full ${getClasses(activeIndex, item)}`}
        />
      ))}
    </div>
  );
};

const Rewards = () => {
  const navigate = useNavigate();
  useDocumentTitle("Muskurahat Foundation | Rewards");

  const { setBackground } = useContext(LayoutContext);
  const [activeIndex, setActiveIndex] = useState(0);
  const [titleColor, setTitleColor] = useState("black");
  useLayoutConfig("white", false);

  const handleBackClick = () => {
    navigate(-1);
  };

  const data = [
    {
      id: 1,
      content: (
        <div className="p-5 flex flex-col h-full">
          <div className="relative">
            <div>
              <span className="text-[36px] text-tertiary font-extrabold">
                STAR
              </span>
              <br />
              <span className="text-xl font-medium">(₹1,000 - ₹5,000)</span>
            </div>
            <img className="w-[100px] absolute top-0 right-0" src={star} />
          </div>
          <div className="grow flex flex-col justify-center">
            <ul className="h-4/6 flex flex-col justify-evenly ml-4">
              {["20% Stipend", "Certificate of completion"].map((item) => (
                <li
                  className="text-[20px] before:content-['\2022'] before:text-tertiary before:font-bold before:inline-block before:w-4 before:-ml-4"
                  key={item}
                >
                  {item}
                </li>
              ))}
            </ul>
          </div>
        </div>
      ),
    },
    {
      id: 2,
      content: (
        <div className="p-5 flex flex-col h-full">
          <div className="relative">
            <div>
              <span className="text-[36px] text-tertiary font-extrabold">
                NINJA
              </span>
              <br />
              <span className="text-xl font-medium">(₹5,000 - ₹15,000)</span>
            </div>
            <img className="w-[100px] absolute top-0 right-0" src={ninja} />
          </div>
          <div className="grow flex flex-col justify-center">
            <ul className="h-4/6 flex flex-col justify-evenly ml-4">
              {[
                "20% Stipend",
                "Certificate of completion",
                "LinkedIn recommendation from our President",
                "Certificate for Crowdfunding course",
              ].map((item) => (
                <li
                  className="text-[16px] before:content-['\2022'] before:text-tertiary before:font-bold before:inline-block before:w-4 before:-ml-4"
                  key={item}
                >
                  {item}
                </li>
              ))}
            </ul>
          </div>
        </div>
      ),
    },
    {
      id: 3,
      content: (
        <div className="p-5 flex flex-col h-full">
          <div className="relative">
            <div>
              <span className="text-[36px] text-tertiary font-extrabold">
                WIZARD
              </span>
              <br />
              <span className="text-xl font-medium">(₹15,000 - ₹30,000)</span>
            </div>
            <img className="w-[100px] absolute top-0 right-0" src={wizard} />
          </div>
          <div className="grow flex flex-col justify-center">
            <ul className="h-4/6 flex flex-col justify-evenly ml-4">
              {[
                "20% Stipend",
                "Certificate of completion",
                "LinkedIn recommendation from our President",
                "Certificate for Crowdfunding course",
                "Social Media Shoutout",
                "Internship Opportunity (As per intern's qualifications and vacancy)",
              ].map((item) => (
                <li
                  className="text-[14px] before:content-['\2022'] before:text-tertiary before:font-bold before:inline-block before:w-4 before:-ml-4"
                  key={item}
                >
                  {item}
                </li>
              ))}
            </ul>
          </div>
        </div>
      ),
    },
    {
      id: 4,
      content: (
        <div className="p-5 flex flex-col h-full">
          <div className="relative">
            <div>
              <span className="text-[36px] text-tertiary font-extrabold">
                LEGEND
              </span>
              <br />
              <span className="text-xl font-medium">(₹30,000+)</span>
            </div>
            <img className="w-[100px] absolute top-0 right-0" src={legend} />
          </div>
          <div className="grow flex flex-col justify-center">
            <ul className="h-4/6 flex flex-col justify-evenly ml-4">
              {[
                "20% Stipend",
                "Certificate of completion",
                "LinkedIn recommendation from our President",
                "Certificate for Crowdfunding course",
                "Social Media Shoutout",
                "Internship Opportunity (As per intern's qualifications and vacancy)",
                "Letter of Recommendation from President of Muskurahat",
              ].map((item) => (
                <li
                  className="text-[12px] before:content-['\2022'] before:text-tertiary before:font-bold before:inline-block before:w-4 before:-ml-4"
                  key={item}
                >
                  {item}
                </li>
              ))}
            </ul>
          </div>
        </div>
      ),
    },
  ];

  const backgroundColorMap = {
    0: "white",
    1: "red",
    2: "purple",
    3: "yellow",
  };

  useEffect(() => {
    setBackground(backgroundColorMap[activeIndex]);
    setTitleColor(activeIndex === 0 ? "black" : "white");
  }, [activeIndex]);

  return (
    <div className="flex justify-between flex-col h-screen">
      <div className="flex justify-center items-center mt-10 relative">
        <img
          src={activeIndex === 0 ? backArrow : whiteBackArrow}
          alt=""
          onClick={handleBackClick}
          className="absolute left-5 top-1/2 -translate-y-1/2 w-5"
        />
        <h1
          className={`font-bold text-${titleColor} text-xl text-center flex-grow`}
        >
          REWARDS
        </h1>
      </div>

      <div className="px-5">
        <Swiper
          onActiveIndexChange={(swiper) => setActiveIndex(swiper.activeIndex)}
          slidesPerView={1}
        >
          {data.map((item, index) => (
            <SwiperSlide key={index} style={{ padding: 5 }}>
              <div
                className={`rounded-xl w-full h-[400px] shadow-[0px_2px_4px_rgba(0,0,0,0.25)] bg-[url('assets/images/backgrounds/white.png')] bg-top bg-cover relative`}
              >
                {item.content}
              </div>
            </SwiperSlide>
          ))}
          <Navigation activeIndex={activeIndex} />
        </Swiper>
      </div>
      <div>
        <img className="w-full" src={background} />
      </div>
      {/*  */}
    </div>
  );
};

export default Rewards;
