const DonationsIcon = ({ isActive }) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    viewBox="0 0 521.92 403.89"
    height="24px"
    width="24px"
    className="m-auto"
  >
    <g id="Layer_2" data-name="Layer 2">
      <g id="Layer_1-2" data-name="Layer 1">
        <path
          fill="none"
          strokeLinecap="round"
          strokeLinejoin="round"
          strokeWidth="22px"
          className={isActive ? "stroke-primary" : "stroke-black"}
          d="M312.81,86.66s16.34-68.42,99.06-67.4,114.68,81.11,91.92,148.08c-6,17.66-20.81,34.34-46.85,62.09C430.21,257.9,319.74,343,312.81,343c-9.18,0-108.82-45.82-152.56-88.55-2.4-2.35,39.49-17.36,33-34-17-43.74-67.7-8.66-69.14-10.74-27.72-40.35-37-86.24-5.37-139.35s74.55-60.25,104.17-59.23S310.51,43,312.81,86.66Z"
        />
        <path
          fill="none"
          strokeLinecap="round"
          strokeLinejoin="round"
          strokeWidth="20px"
          className={isActive ? "stroke-primary" : "stroke-black"}
          d="M20.21,305.73s18.39-51.32,34.47-71.24S109,177,109,177s12.59,28.52,15.12,32.67-12,9.5-22.74,20.23,47.49-50.56,84.26-22.22-28.34,38.3-39.83,52.09-14.55,19.91-14.55,19.91,22.38-35.36,33.07-27.2,65.44,37.5,63.29,43.84c0,0-10.32,24.73-20,39s-16.34,27.58-26.05,35.75c0,0-19.91,7.15-39.83,5.1s-31.15,8.17-40.34,16.86S10,341,10,341Z"
        />
      </g>
    </g>
  </svg>
);

export default DonationsIcon;
