import first from "assets/images/pages/community/1.webp";
import second from "assets/images/pages/community/2.webp";

const communityStories = [
  {
    title:
      "How the crowdfunding internship has contributed in building my professional skills",
    background: "#E22226",
    image: first,
    link: "/user/community/1",
    author: "Anushka Solanki",
    date: "29/11/2023",
    body: `<p>My internship experience with Muskurahat has been nothing short of amazing. I've learned a lot and encountered both highs and lows, creating a bittersweet yet enriching journey.</p><p>Navigating through the highs and lows of this experience, there were moments of happiness and, yes, a few setbacks. Learning to handle rejection was perhaps the most challenging part of this journey. However, I take pride in the fact that I am now halfway through mastering this art.</p><p>Overall, I couldn't have asked for a better experience. It has not only enhanced my professional skills but has also contributed to my personal growth. I am leaving this internship with a deep sense of accomplishment and gratitude for the opportunity to contribute to such a meaningful cause.</p><p>Thank you for this incredible journey.</p>`,
  },
  {
    title:
      "A new perspective on life and making a positive impact through this internship",
    background: "#564292",
    image: second,
    link: "/user/community/2",
    author: "Ethi Verma",
    date: "29/11/2023",
    body: `<p>I want to express how profoundly life-changing my internship with Muskurahat has been. After three years of intense preparation for civil services, I found myself in need of a break, a respite from the isolation that had become all too familiar. Little did I know that the break I needed would manifest in an opportunity to work with your team for such a great cause.</p><p>Interacting with people and contributing to a cause as noble as supporting underprivileged children has been more rejuvenating than I could have imagined. The experience has not only allowed me to recharge and gain a new perspective on life but has also rekindled my passion for making a positive impact on the world.</p><p>Thank you for providing me with this transformative experience. It has been a much-needed pause in my journey and a reminder of the profound impact one can have when working towards a meaningful goal.</p>`,
  },
];

export default communityStories;
