import checks from "assets/images/backgrounds/checks.png";
import backArrow from "assets/images/icons/back-arrow.png";
import Storyteller from "assets/images/pages/hacks/Storyteller.png";
import Transperancy from "assets/images/pages/hacks/Transperancy.png";
import Highnet from "assets/images/pages/hacks/Highnet.png";
import segment from "assets/images/pages/hacks/segment.png";
import Options from "assets/images/pages/hacks/Options.png";
import value from "assets/images/pages/hacks/value.png";
import Leveraging from "assets/images/pages/hacks/Leveraging.png";
import useLayoutConfig from "hooks/useLayoutConfig";
import { useNavigate } from "react-router-dom";

const overlayStyle = {
  backgroundPosition: "center center",
  borderRadius: "50px 50px 35px 35px",
  backgroundColor: "#fff",
  backgroundImage: `url(${checks})`,
  paddingBottom: 10,
  marginTop: 30,
  boxShadow: "0px -4px 4px 0px rgba(0, 0, 0, 0.25)",
  padding: "50px 0px",
  zIndex: 9,
  position: "relative",
};

const HackTwo = () => {
  const navigate = useNavigate();
  useLayoutConfig("white", true);
  const handleBackClick = () => {
    navigate(-1);
  };

  return (
    <>
      <div className="flex justify-center items-center mt-10 relative">
        <img
          src={backArrow}
          alt=""
          onClick={handleBackClick}
          className="absolute left-5 top-1/2 -translate-y-1/2 w-5"
        />
        <h1
          className={`font-bold text-secondary text-xl text-center flex-grow`}
        >
          CROWDFUNDING
          <br />
          <span className="text-tertiary">HACKS FOR YOU</span>
        </h1>
      </div>
      <div className="text-center p-5 mt-10 mx-5 bg-tertiary rounded-[10px] shadow-[0px_2px_4px_rgba(0,0,0,0.25)]">
        <h2 className="text-xs text-white font-bold">
          UNLOCKING <span className="text-secondary">BIGGER DONATIONS</span>
        </h2>
        <p className="text-[10px] mt-5 text-white">
          Securing substantial donations is an essential part of advancing your
          NGO&apos;s mission. While the prospect of soliciting larger amounts
          may seem daunting, the right strategies can make it both achievable
          and impactful.
        </p>
      </div>
      <div style={overlayStyle}>
        <div className="relative">
          <div className="w-3/4 mt-5 pb-2 bg-cover bg-[url('assets/images/pages/hacks/note1.png')]">
            <h2 className="font-bold pt-5 pb-7 pl-5 text-secondary text-lg text-left">
              <span className="relative text-secondary before:content-[' '] before:bg-contain before:block before:absolute before:bg-[url('assets/images/icons/underline.webp')] before:w-full before:h-[10px] before:bg-no-repeat before:-bottom-2 inline-block">
                SEGMENT YOUR DONORS
              </span>
            </h2>
          </div>
          <img src={segment} className="w-24 absolute right-0 top-0" />
          <div className="mx-5 bg-white rounded-[10px] shadow-[0px_2px_4px_rgba(0,0,0,0.25)] p-5">
            <ul className="ml-3">
              <li className="text-xs font-bold text-primary before:content-['\2022'] relative before:top-0 before:-left-2 before:-mt-3 before:absolute before:text-3xl before:text-tertiary before:font-bold before:inline-block before:w-5 before:-ml-4">
                Strategy -
              </li>
              <li className="text-xs mt-2">
                Divide your donor base into segments based on their capacity to
                give.
              </li>
              <li className="text-xs mt-4 font-bold text-primary before:content-['\2022'] relative before:top-0 before:-left-2 before:-mt-3 before:absolute before:text-3xl before:text-tertiary before:font-bold before:inline-block before:w-5 before:-ml-4">
                Why -
              </li>
              <li className="text-xs mt-2">
                This approach allows for a tailored and more effective
                fundraising strategy for larger donors.
              </li>
              <li className="text-xs mt-4 font-bold text-primary before:content-['\2022'] relative before:top-0 before:-left-2 before:-mt-3 before:absolute before:text-3xl before:text-tertiary before:font-bold before:inline-block before:w-5 before:-ml-4">
                Tip -
              </li>
              <li className="text-xs mt-2">
                Research and identify potential major donors within your current
                network.
              </li>
            </ul>
          </div>
        </div>

        <div className="relative">
          <div className="flex justify-between mt-16">
            <div></div>
            <div className="w-2/3 pb-2 bg-cover bg-[url('assets/images/pages/hacks/note2.png')]">
              <h2 className="font-bold pt-5 pb-7 pr-5 text-tertiary text-lg text-right">
                HIGH NET WORTH
                <span className="relative text-secondary before:content-[' '] before:bg-contain before:block before:absolute before:bg-[url('assets/images/icons/underline.webp')] before:w-full before:h-[10px] before:bg-no-repeat before:-bottom-2 inline-block">
                  INDIVIDUALS (HNIS)
                </span>
              </h2>
            </div>
          </div>
          <img src={Highnet} className="w-20 absolute left-5 -top-2" />

          <div className="mx-5 mt-3 bg-white rounded-[10px] shadow-[0px_2px_4px_rgba(0,0,0,0.25)] p-5">
            <ul className="ml-3">
              <li className="text-xs font-bold text-primary before:content-['\2022'] relative before:top-0 before:-left-2 before:-mt-3 before:absolute before:text-3xl before:text-tertiary before:font-bold before:inline-block before:w-5 before:-ml-4">
                Strategy -
              </li>
              <li className="text-xs mt-2">
                Focus on professionals, business owners, and individuals with
                good incomes who often engage in charitable giving.
              </li>
              <li className="text-xs mt-4 font-bold text-primary before:content-['\2022'] relative before:top-0 before:-left-2 before:-mt-3 before:absolute before:text-3xl before:text-tertiary before:font-bold before:inline-block before:w-5 before:-ml-4">
                Why -
              </li>
              <li className="text-xs mt-2">
                HNIs find meaning in charity and are willing to donate
                generously.
              </li>
              <li className="text-xs mt-4 font-bold text-primary before:content-['\2022'] relative before:top-0 before:-left-2 before:-mt-3 before:absolute before:text-3xl before:text-tertiary before:font-bold before:inline-block before:w-5 before:-ml-4">
                Tip -
              </li>
              <li className="text-xs mt-2">
                Identify and approach potential HNIs within your network and
                communities.
              </li>
            </ul>
          </div>
        </div>

        <div className="relative">
          <div className="flex mt-16">
            <div className="w-2/3 pb-2 bg-cover bg-[url('assets/images/pages/hacks/note3.png')]">
              <h2 className="font-bold pt-5 pb-7 pl-5 text-primary text-lg text-left">
                <span className="relative text-secondary before:content-[' '] before:bg-contain before:block before:absolute before:bg-[url('assets/images/icons/underline.webp')] before:w-full before:h-[10px] before:bg-no-repeat before:-bottom-2 inline-block">
                  BE A STORYTELLER
                </span>
              </h2>
            </div>
          </div>
          <img src={Storyteller} className="w-20 absolute right-5 -top-2" />

          <div className="mx-5 mt-3 bg-white rounded-[10px] shadow-[0px_2px_4px_rgba(0,0,0,0.25)] p-5">
            <ul className="ml-3">
              <li className="text-xs font-bold text-primary before:content-['\2022'] relative before:top-0 before:-left-2 before:-mt-3 before:absolute before:text-3xl before:text-tertiary before:font-bold before:inline-block before:w-5 before:-ml-4">
                Strategy -
              </li>
              <li className="text-xs mt-2">
                Craft a compelling narrative that demonstrates the impact of
                larger donations.
              </li>
              <li className="text-xs mt-4 font-bold text-primary before:content-['\2022'] relative before:top-0 before:-left-2 before:-mt-3 before:absolute before:text-3xl before:text-tertiary before:font-bold before:inline-block before:w-5 before:-ml-4">
                Why -
              </li>
              <li className="text-xs mt-2">
                A powerful story can evoke emotions and inspire larger
                contributions.
              </li>
              <li className="text-xs mt-4 font-bold text-primary before:content-['\2022'] relative before:top-0 before:-left-2 before:-mt-3 before:absolute before:text-3xl before:text-tertiary before:font-bold before:inline-block before:w-5 before:-ml-4">
                Tip -
              </li>
              <li className="text-xs mt-2">
                Authentic and relatable stories can hold attention.
              </li>
            </ul>
          </div>
        </div>

        <div className="relative">
          <div className="flex justify-between mt-16">
            <div></div>
            <div className="w-3/4 pb-2 bg-cover bg-[url('assets/images/pages/hacks/note2.png')]">
              <h2 className="font-bold pt-5 pb-7 pr-5 text-tertiary text-lg text-right">
                HIGHLIGHT TRANSPARENCY
                <br />
                <span className="relative text-secondary before:content-[' '] before:bg-contain before:block before:absolute before:bg-[url('assets/images/icons/underline.webp')] before:w-full before:h-[10px] before:bg-no-repeat before:-bottom-2 inline-block">
                  AND ACCOUNTABILITY
                </span>
              </h2>
            </div>
          </div>
          <img src={Transperancy} className="w-28 absolute left-5 -top-2" />

          <div className="mx-5 mt-3 bg-white rounded-[10px] shadow-[0px_2px_4px_rgba(0,0,0,0.25)] p-5">
            <ul className="ml-3">
              <li className="text-xs font-bold text-primary before:content-['\2022'] relative before:top-0 before:-left-2 before:-mt-3 before:absolute before:text-3xl before:text-tertiary before:font-bold before:inline-block before:w-5 before:-ml-4">
                Strategy -
              </li>
              <li className="text-xs mt-2">
                Emphasise your organisation&apos;s financial transparency and
                accountability.
              </li>
              <li className="text-xs mt-4 font-bold text-primary before:content-['\2022'] relative before:top-0 before:-left-2 before:-mt-3 before:absolute before:text-3xl before:text-tertiary before:font-bold before:inline-block before:w-5 before:-ml-4">
                Why -
              </li>
              <li className="text-xs mt-2">
                Donors, especially major ones, want to ensure their money is
                well-managed and effectively used.
              </li>
              <li className="text-xs mt-4 font-bold text-primary before:content-['\2022'] relative before:top-0 before:-left-2 before:-mt-3 before:absolute before:text-3xl before:text-tertiary before:font-bold before:inline-block before:w-5 before:-ml-4">
                Tip -
              </li>
              <li className="text-xs mt-2">
                You will find all important documents and reports on
                muskurahat.org.in/financials
              </li>
            </ul>
          </div>
        </div>

        <div className="relative">
          <div className="flex mt-16">
            <div className="w-2/3 pb-2 bg-cover bg-[url('assets/images/pages/hacks/note3.png')]">
              <h2 className="font-bold pt-5 pb-7 pl-5 text-primary text-lg text-left">
                OFFER CUSTOMISED
                <br />
                <span className="relative text-secondary before:content-[' '] before:bg-contain before:block before:absolute before:bg-[url('assets/images/icons/underline.webp')] before:w-full before:h-[10px] before:bg-no-repeat before:-bottom-2 inline-block">
                  GIVING OPTIONS
                </span>
              </h2>
            </div>
          </div>
          <img src={Options} className="w-24 absolute right-5 top-0" />

          <div className="mx-5 mt-3 bg-white rounded-[10px] shadow-[0px_2px_4px_rgba(0,0,0,0.25)] p-5">
            <ul className="ml-3">
              <li className="text-xs font-bold text-primary before:content-['\2022'] relative before:top-0 before:-left-2 before:-mt-3 before:absolute before:text-3xl before:text-tertiary before:font-bold before:inline-block before:w-5 before:-ml-4">
                Strategy -
              </li>
              <li className="text-xs mt-2">
                Provide flexible donation options that allow major donors to
                give in ways that suit their preferences.
              </li>
              <li className="text-xs mt-4 font-bold text-primary before:content-['\2022'] relative before:top-0 before:-left-2 before:-mt-3 before:absolute before:text-3xl before:text-tertiary before:font-bold before:inline-block before:w-5 before:-ml-4">
                Why -
              </li>
              <li className="text-xs mt-2">
                Customization demonstrates respect for their individual giving
                choices.
              </li>
              <li className="text-xs mt-4 font-bold text-primary before:content-['\2022'] relative before:top-0 before:-left-2 before:-mt-3 before:absolute before:text-3xl before:text-tertiary before:font-bold before:inline-block before:w-5 before:-ml-4">
                Tip -
              </li>
              <li className="text-xs mt-2">
                Offer options like you can donate on their behalf, bank
                transfers, etc.
              </li>
            </ul>
          </div>
        </div>

        <div className="relative">
          <div className="flex justify-between mt-16">
            <div></div>
            <div className="w-3/5 pb-2 bg-cover bg-[url('assets/images/pages/hacks/note2.png')]">
              <h2 className="font-bold pt-5 pb-7 pr-5 text-tertiary text-lg text-right">
                LEVERAGING
                <br />
                <span className="relative text-secondary before:content-[' '] before:bg-contain before:block before:absolute before:bg-[url('assets/images/icons/underline.webp')] before:w-full before:h-[8px] before:bg-no-repeat before:-bottom-3 inline-block">
                  TAX EXEMPTION
                </span>
              </h2>
            </div>
          </div>
          <img src={Leveraging} className="w-20 absolute left-10 -top-2" />

          <div className="mx-5 mt-3 bg-white rounded-[10px] shadow-[0px_2px_4px_rgba(0,0,0,0.25)] p-5">
            <ul className="ml-3">
              <li className="text-xs font-bold text-primary before:content-['\2022'] relative before:top-0 before:-left-2 before:-mt-3 before:absolute before:text-3xl before:text-tertiary before:font-bold before:inline-block before:w-5 before:-ml-4">
                Strategy -
              </li>
              <li className="text-xs mt-2">
                Highlight your NGO&apos;s 80G certification and the tax benefits
                it offers to businesses, companies, and HNIs through charitable
                donations.
              </li>
              <li className="text-xs mt-4 font-bold text-primary before:content-['\2022'] relative before:top-0 before:-left-2 before:-mt-3 before:absolute before:text-3xl before:text-tertiary before:font-bold before:inline-block before:w-5 before:-ml-4">
                Why -
              </li>
              <li className="text-xs mt-2">
                Many HNIs seek tax benefits as an incentive for their charitable
                giving.
              </li>
              <li className="text-xs mt-4 font-bold text-primary before:content-['\2022'] relative before:top-0 before:-left-2 before:-mt-3 before:absolute before:text-3xl before:text-tertiary before:font-bold before:inline-block before:w-5 before:-ml-4">
                Tip -
              </li>
              <li className="text-xs mt-2">
                Provide clear information on how donors can benefit from tax
                exemption.
              </li>
            </ul>
          </div>
        </div>

        <div className="relative">
          <div className="flex mt-16">
            <div className="w-2/3 pb-2 bg-cover bg-[url('assets/images/pages/hacks/note3.png')]">
              <h2 className="font-bold pt-5 pb-7 pl-5 text-primary text-lg text-left">
                DELIVER VALUE
                <br />
                <span className="relative text-secondary before:content-[' '] before:bg-contain before:block before:absolute before:bg-[url('assets/images/icons/underline.webp')] before:w-full before:h-[10px] before:bg-no-repeat before:-bottom-2 inline-block">
                  TO DONORS
                </span>
              </h2>
            </div>
          </div>
          <img src={value} className="w-28 absolute right-5 top-0" />

          <div className="mx-5 mt-3 bg-white rounded-[10px] shadow-[0px_2px_4px_rgba(0,0,0,0.25)] p-5">
            <ul className="ml-3">
              <li className="text-xs font-bold text-primary before:content-['\2022'] relative before:top-0 before:-left-2 before:-mt-3 before:absolute before:text-3xl before:text-tertiary before:font-bold before:inline-block before:w-5 before:-ml-4">
                Strategy -
              </li>
              <li className="text-xs mt-2">
                Understand that you provide value to those who have the means to
                give.
              </li>
              <li className="text-xs mt-4 font-bold text-primary before:content-['\2022'] relative before:top-0 before:-left-2 before:-mt-3 before:absolute before:text-3xl before:text-tertiary before:font-bold before:inline-block before:w-5 before:-ml-4">
                Why -
              </li>
              <li className="text-xs mt-2">
                HNIs often find meaning in helping others, and your efforts are
                a valuable contribution to our mission.
              </li>
              <li className="text-xs mt-4 font-bold text-primary before:content-['\2022'] relative before:top-0 before:-left-2 before:-mt-3 before:absolute before:text-3xl before:text-tertiary before:font-bold before:inline-block before:w-5 before:-ml-4">
                Tip -
              </li>
              <li className="text-xs mt-2">
                Share stories that illustrate the impact of their donations on
                our organisation&apos;s mission.
              </li>
            </ul>
          </div>
        </div>
      </div>

      <div className="bg-secondary pt-20 -mt-8 pb-6 -mb-8 text-center">
        <div className="bg-white mx-5 rounded-[10px] shadow-[0px_2px_4px_rgba(0,0,0,0.25)] p-5">
          <h3 className="text-primary font-bold">CONCLUSION</h3>
          <p className="text-[10px] mt-4">
            Securing larger donations is a multifaceted process that involves
            personalization, storytelling, and walking an extra mile.
          </p>
        </div>
        <div className="mx-5 rounded-[10px] py-5">
          <div className="border-white border-t-[1px] max-w-[75%] m-auto" />
          <div className="flex justify-between mt-5">
            <button
              className="bg-tertiary text-white py-2 px-3 text-xs rounded-lg disabled:bg-[#d9d9d9] disabled:cursor-not-allowed"
              onClick={() => {
                navigate("/user/hacks/hack-1");
              }}
            >
              BACK
            </button>

            <button
              className="bg-primary text-white py-2 px-3 text-xs rounded-lg disabled:bg-[#d9d9d9] disabled:cursor-not-allowed"
              onClick={() => {
                navigate("/user/hacks/hack-3");
              }}
            >
              NEXT HACK
            </button>
          </div>
        </div>
      </div>
    </>
  );
};

export default HackTwo;
