const Step1 = ({ name, setName, profile, setProfile, setStep }) => {
  return (
    <>
      <h2 className="text-white font-normal text-lg mb-5">
        What should we call you?
      </h2>
      <input
        className="w-full h-12 rounded-[20px] px-4 mb-5 text-center"
        value={name}
        onChange={(e) => setName(e.target.value)}
      />
      <button
        disabled={!name}
        onClick={() => {
          setProfile({ ...profile, name });
          setStep(1);
        }}
        className="bg-secondary disabled:bg-[#aaa] w-fit rounded-[11px] px-6 py-4 shadow-[1px_3px_8px_0_rgba(0,0,0,0.5)] text-xs text-white font-bold"
      >
        NEXT
      </button>
    </>
  );
};

export default Step1;
