import { getCasuallyFormattedDate } from "config/helpers";

const Donation = ({ donation, i }) => {
  return (
    <div className="bg-white shadow-[0px_2px_2px_rgba(0,0,0,0.25)] mb-5 rounded-lg p-5 flex justify-between">
      <div className="flex gap-5 items-center">
        <div
          className={`w-8 h-8 flex content-between justify-between text-white text-sm rounded-lg 
      ${
        i % 3 === 0
          ? "bg-tertiary"
          : i % 2 === 0
          ? "bg-secondary"
          : "bg-primary"
      }
      
      `}
        >
          <span className="m-auto">
            {donation.name.split(" ")[0][0] +
              donation.name.split(" ")[donation.name.split(" ").length - 1][0]}
          </span>
        </div>
        <div className="">
          <h3 className="font-semibold text-sm">{donation.name}</h3>
          <span className="text-quaternary text-[10px]">
            {getCasuallyFormattedDate(donation.timestamp)}
          </span>
        </div>
      </div>
      <div className="flex flex-col">
        <span className="text-primary font-semibold text-left">
          + {donation.amount}/-
        </span>
        <a
          target="_blank"
          href={`https://api.muskurahat.org.in/receipt/${donation.id}`}
          className="text-secondary text-[10px] underline text-center"
          rel="noreferrer"
        >
          Receipt
        </a>
      </div>
    </div>
  );
};

export default Donation;
