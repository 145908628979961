const assignments = {
  "assignment-1": {
    title: "Assignment 1",
    description:
      "Note: Make sure to finish the modules before taking this assignment",
    questions: [
      {
        question: "What is the main goal of Jia Jiang's social experiment?",
        options: [
          "To overcome his fear of rejection",
          "Failure is a necessary step to success",
          "To learn more about human psychology and asking for what you want",
          "To learn how to handle rejection in a professional setting",
          "All of the above",
        ],
        answer: "To overcome his fear of rejection",
      },
      {
        question:
          "What did Jia Jiang learn from his social experiment about asking for what you want?",
        options: [
          "How to phrase requests in a way that increases the chances of success",
          "How to handle rejection in a positive and constructive way",
          "How to read body language and nonverbal cues",
          "All of the above",
        ],
        answer:
          "How to phrase requests in a way that increases the chances of success",
      },
      {
        question:
          "How does Jia Jiang suggest dealing with rejection in a professional setting?",
        options: [
          "By focusing on the rejection itself",
          "By learning from the experience and using it as an opportunity for growth",
          "By giving up on the goal",
          "By becoming aggressive",
        ],
        answer:
          "By learning from the experience and using it as an opportunity for growth",
      },
      {
        question:
          "According to Jia Jiang, what is the key takeaway from his social experiment?",
        options: [
          "To overcome his fear of rejection",
          "Failure is a necessary step to success",
          "Rejection is a sign that you are not good enough",
          "Asking for what you want is always the right thing to do, regardless of the response.",
        ],
        answer:
          "Asking for what you want is always the right thing to do, regardless of the response.",
      },
      {
        question:
          "Why is following up with donor important according to Sabri Suby?",
        options: [
          "It helps to maintain a relationship with them",
          "It helps to increase the chances of securing funding",
          "It helps to improve the campaign's visibility",
          "All of the above",
        ],
        answer: "All of the above",
      },
      {
        question:
          "What is the main strategy Sabri Suby suggests for following up with donors?",
        options: [
          "Being persistent but not aggressive",
          "Waiting for them to initiate contact",
          "Sending out occasional updates",
          "Constantly reaching out with updates and requests for funding",
        ],
        answer: "Being persistent but not aggressive",
      },
      {
        question:
          "According to Sabri Suby, why is it important to be subtle and aggressive at the same time in following up with donors?",
        options: [
          "It helps to maintain a balance between persistence and respect for their time and consideration",
          "It helps to increase the chances of securing funding",
          "It helps to improve the campaign's visibility",
          "It helps to maintain a relationship with them",
        ],
        answer:
          "It helps to maintain a balance between persistence and respect for their time and consideration",
      },
    ],
  },
};

export default assignments;
