import Bulb from "assets/images/icons/bulb.png";
import UserLoader from "components/UserLoader";

import {
  convertTransactionsToGraphData2,
  getInternshipDaysCompleted,
} from "config/helpers";
import { AuthContext } from "context/Auth";
import { DonationsContext } from "context/Data/Donations";
import { useAnimate, useInView } from "framer-motion";
import { useContext, useEffect, useRef, useState } from "react";
import Chart from "react-apexcharts";
import LockedCard from "../LockedCard";

const useAnimateOpacity = (isHintVisible) => {
  const [scope, animate] = useAnimate();

  useEffect(() => {
    animate(
      ".animate-opacity",
      { opacity: isHintVisible ? 1 : 0 },
      { duration: 0.2 },
    );
  }, [isHintVisible]);
  return scope;
};

const AverageDonation = ({ averageDonation, setAverageDonation, isLocked }) => {
  const [graphData, setGraphData] = useState([]);
  const [defaultData, setDefaultData] = useState([]);
  const numberOfDays = getInternshipDaysCompleted();
  const { profile } = useContext(AuthContext);
  const [isHintVisible, setIsHintVisibile] = useState(false);
  const scope = useAnimateOpacity(isHintVisible);
  const { donations, isLoading } = useContext(DonationsContext);

  useEffect(() => {
    if (!donations) return;
    const data = convertTransactionsToGraphData2(donations, profile.createdAt);

    const tempAvgDonation = Math.ceil(
      donations.reduce((acc, val) => acc + val.amount, 0) / donations.length,
    );

    setAverageDonation(tempAvgDonation);
    if (isNaN(tempAvgDonation)) setAverageDonation(0);

    setGraphData(data);
    setDefaultData(
      [
        520, 451, 530, 590, 550, 490, 485, 505, 510, 515, 518, 521, 530, 525,
        511, 530, 518, 515, 516, 505, 499, 520, 530, 535, 540, 539, 536, 540,
        545, 550, 550, 550, 550, 550, 550, 550, 550, 550, 550, 550, 550, 550,
        550, 550, 550, 550, 550, 550, 550, 550, 550, 550, 550, 550, 550, 550,
        550, 550, 550, 550, 550, 550, 550, 550, 550, 550, 550, 550, 550, 550,
        550, 550, 550, 550, 550, 550, 550, 550, 550, 550, 550, 550, 550, 550,
        550, 550, 550, 550, 550, 550, 550, 550, 550, 550, 550, 550, 550, 550,
        550, 550, 550, 550, 550, 550, 550, 550, 550, 550, 550, 550, 550, 550,
        550, 550, 550, 550, 550, 550, 550, 550, 550, 550, 550, 550, 550, 550,
        550, 550, 550, 550, 550, 550, 550, 550, 550, 550, 550, 550, 550, 550,
        550, 550, 550, 550, 550, 550, 550, 550, 550, 550, 550, 550, 550, 550,
        550, 550, 550, 550, 550, 550, 550, 550, 550, 550, 550, 550, 550, 550,
        550, 550, 550, 550, 550, 550, 550, 550, 550, 550, 550, 550, 550, 550,
        550, 550, 550, 550, 550, 550, 550, 550, 550, 550, 550, 550,
      ].slice(0, numberOfDays),
    );
  }, [donations]);

  const toggleHint = () => {
    setIsHintVisibile(!isHintVisible);
  };
  const ref = useRef(null);
  const isInView = useInView(ref, { margin: "0px 0px -80px 0px", once: true });

  return (
    <div
      ref={scope}
      className={`p-5 bg-gray-200 bg-[url('assets/images/pages/insights/square.png')] bg-cover shadow-[1px_3px_8px_0_rgba(0,0,0,0.25)] rounded-[20px] h-full`}
    >
      <div className="flex items-center justify-between text-secondary text-lg font-bold">
        <div className="text-left" ref={ref}>
          <h3 className="text-xl">AVERAGE DONATION</h3>
        </div>
        <div className="relative">
          <img onClick={toggleHint} className="w-[47px] mr-1" src={Bulb} />
          <div className="animate-opacity absolute text-black font-normal text-[8px] w-56 border-tertiary border-solid border-[1px] leading-4 -top-[105px] -right-5 bg-white shadow-[1px_3px_8px_0_rgba(0,0,0,0.25)] rounded-xl p-4 after:absolute after:right-10 after:top-full after:-mt-[6px] after:w-3 after:h-3 after:border-[1px] after:border-solid after:border-tertiary after:rotate-45 after:bg-white after:border-t-0 after:border-l-0">
            Provides a measure of the average amount contributed by each donor
            to the fundraising campaign.
            <br />
            <br />
            <span className="text-secondary">Average Donation =</span>
            <div className="relative w-[100px] inline-block">
              <div className="absolute w-full h-full top-[-15px] left-[5px]">
                <span className="text-primary border-b-[0.8px] border-solid border-[#000000] pb-[2px]">
                  Total Funds Raised
                </span>
                <br />
                <span className="text-primary">Total Number Of Donors</span>
              </div>
            </div>
          </div>
        </div>
      </div>

      {isLoading ? (
        <UserLoader />
      ) : (
        <div className="relative">
          <div className={isLocked ? "blur-sm" : ""}>
            {isInView && (
              <Chart
                type="area"
                width="100%"
                series={[
                  {
                    name: "Your average donation",
                    data: graphData,
                  },
                  {
                    name: "Per intern average",
                    data: defaultData,
                  },
                ]}
                options={{
                  colors: ["#E22226", "#564292"],

                  chart: {
                    height: 350,
                    type: "area",
                    toolbar: false,
                  },
                  dataLabels: {
                    enabled: false,
                  },
                  stroke: {
                    curve: "smooth",
                    width: 1,
                  },
                  tooltip: {
                    x: {
                      formatter: function (val) {
                        return val;
                      },
                    },
                  },
                  xaxis: {
                    type: "category",
                    labels: {
                      formatter: function (val) {
                        return val === 1 || val === graphData.length ? val : "";
                      },
                    },
                  },
                  legend: {
                    show: false,
                  },
                }}
              />
            )}

            <div className="border-t-[1px] border-black flex justify-between pt-3">
              <div className="flex text-[10px] items-center gap-2">
                <div className="w-4 h-4 rounded-full bg-primary"></div>
                <div>
                  <div>Your average donation</div>
                  <div className="text-primary font-semibold">
                    ₹ {averageDonation}
                  </div>
                </div>
              </div>
              <div className="flex text-[10px] items-center gap-2">
                <div className="w-4 h-4 rounded-full bg-secondary"></div>
                <div>
                  <div>Per intern average</div>
                  <div className="text-secondary font-semibold">
                    ₹{" "}
                    {Math.ceil(
                      defaultData.reduce((acc, val) => acc + val, 0) /
                        defaultData.length,
                    )}
                  </div>
                </div>
              </div>
            </div>
          </div>
          {isLocked && <LockedCard title1="AVERAGE" title2="DONATION" />}
        </div>
      )}
    </div>
  );
};

export default AverageDonation;
