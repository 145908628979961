import emoji from "assets/images/icons/emoji.png";
import gif from "assets/images/icons/gif.png";
import image from "assets/images/icons/image.png";
import { storage } from "config/firebase";
import { getDownloadURL, ref, uploadBytesResumable } from "firebase/storage";
import { useRef, useState } from "react";
import EmojiPickerCustom from "./emojiPickerCustom";
import GifPicker from "./Gif/gifPicker";
import ImageUpload from "./imageUpload";
import SelectedGif from "./Gif/selectedGif";

const CustomTextareaInput = ({
  message,
  setMessage,
  setImageURL,
  setGifId,
  gifId,
  fileToBeUploaded,
  setFileToBeUploaded,
}) => {
  const [showHelperText, setShowHelperText] = useState(false);
  const [emojiPicker, setEmojiPicker] = useState(false);
  const [uploadPerentage, setUploadPercentage] = useState(0); // [0, 100
  const [task, setTask] = useState(null); // task is an object of type UploadTask from firebase
  const [gifPicker, setGifPicker] = useState(false);
  const [fileSizeError, setFileSizeError] = useState(false); // [0, 100]

  const toggleGifPicker = () => {
    setGifPicker(!gifPicker);
  };

  const toggleShowHelperText = () => {
    if (!showHelperText) setShowHelperText(!showHelperText);
  };

  const toggleEmojiPicker = () => {
    setEmojiPicker(!emojiPicker);
  };

  const onGifSelect = (id) => {
    setGifId(id);
    toggleGifPicker();
  };

  function onClick(emojiData) {
    setMessage(
      (message) =>
        message + (emojiData.isCustom ? emojiData.unified : emojiData.emoji),
    );
    toggleEmojiPicker();
  }

  const openGifPicker = () => {
    handleCancelImage();
    toggleGifPicker();
  };

  const hiddenFileInput = useRef(null);

  const openChooseFile = () => {
    setGifId(null);
    hiddenFileInput.current.click();
  };

  const handleCancelGif = () => {
    setGifId(null);
  };

  const handleCancelImage = () => {
    setFileToBeUploaded(null);
    setUploadPercentage(0);
    setImageURL(null);
    // Cancel the upload
    task?.cancel();
  };

  const handleChange = (event) => {
    const fileUploaded = event.target.files[0];
    if (!fileUploaded) return;

    if (fileUploaded.size > 1200000) {
      setFileSizeError(true);
      return;
    } else {
      setFileSizeError(false);
    }
    setFileToBeUploaded(fileUploaded);

    const storageRef = ref(
      storage,
      `community-posts/${new Date()}-${fileUploaded.name}`,
    );

    const uploadTask = uploadBytesResumable(storageRef, fileUploaded);
    setTask(uploadTask);

    uploadTask.on(
      "state_changed",
      (snapshot) => {
        // Observe state change events such as progress, pause, and resume
        // Get task progress, including the number of bytes uploaded and the total number of bytes to be uploaded
        const progress =
          (snapshot.bytesTransferred / snapshot.totalBytes) * 100;
        setUploadPercentage(progress);
      },
      (error) => {
        console.log(error);
        // Handle unsuccessful uploads
      },
      () => {
        // Handle successful uploads on complete
        // For instance, get the download URL: https://firebasestorage.googleapis.com/...
        getDownloadURL(uploadTask.snapshot.ref).then((downloadURL) => {
          setImageURL(downloadURL);
        });
      },
    );
  };

  return (
    <>
      <div className="flex flex-grow flex-col justify-start items-start relative">
        <div className="relative w-full">
          <textarea
            maxLength={200}
            value={message}
            onChange={(e) => setMessage(e.target.value)}
            onClick={toggleShowHelperText}
            placeholder="SHARE YOUR THOUGHTS"
            className={`placeholder:text-xs w-full text-xs overflow-hidden placeholder:font-light placeholder:italic placeholder:text-[#727272] resize-none placeholder:leading-10 active:bg-[#EEEEEE] px-1 min-w-[170px] ${
              showHelperText
                ? "bg-[#EEEEEE] placeholder:text-[#EEEEEE] h-16"
                : ""
            }`}
          />

          {showHelperText && (
            <span className="absolute bottom-[10px] right-[3px] text-[6px] text-[#696969]">
              (Max 200 characters)
            </span>
          )}
        </div>
        {showHelperText && (
          <>
            <div>
              <button className="p-2 pl-0" onClick={toggleEmojiPicker}>
                <img src={emoji} className="w-5" />
              </button>
              <button className="p-2" onClick={openChooseFile}>
                <img src={image} className="w-5" />
              </button>
              <input
                type="file"
                accept="image/*"
                onChange={handleChange}
                ref={hiddenFileInput}
                style={{ display: "none" }} // Make the file input element invisible
              />
              <button className="p-2" onClick={openGifPicker}>
                <img src={gif} className="w-5" />
              </button>
            </div>
            {fileSizeError && (
              <p className="text-primary text-xs">
                File size should be less than 500 kb
              </p>
            )}

            <ImageUpload
              uploadPerentage={uploadPerentage}
              fileToBeUploaded={fileToBeUploaded}
              handleCancelImage={handleCancelImage}
            />
            <SelectedGif gifId={gifId} handleCancelGif={handleCancelGif} />
          </>
        )}
      </div>

      {gifPicker && (
        <GifPicker
          toggleGifPicker={toggleGifPicker}
          onGifSelect={onGifSelect}
        />
      )}
      <EmojiPickerCustom
        emojiPicker={emojiPicker}
        toggleEmojiPicker={toggleEmojiPicker}
        onClick={onClick}
      />
    </>
  );
};

export default CustomTextareaInput;
