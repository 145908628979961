// ./hooks/useLayoutConfig.js
import { LayoutContext } from "context/Layout";
import { useContext, useEffect } from "react";

const useLayoutConfig = (background = "red", showBottomMenu = false) => {
  const { setBackground, setShowBottomMenu } = useContext(LayoutContext);

  useEffect(() => {
    setBackground(background);
    setShowBottomMenu(showBottomMenu);
  }, []);

  return;
};

export default useLayoutConfig;
