import CustomCarousel from "components/CustomCarousel";
import { SwiperSlide } from "swiper/react";
import { Link } from "react-router-dom";
import communityStories from "config/community-stories";

const CommunityCarousel = () => {
  return (
    <CustomCarousel slidesPerView={1.5}>
      {communityStories.map((item, index) => (
        <SwiperSlide key={index}>
          <Link to={item.link}>
            <div
              style={{
                background: `url('${item.image}'), linear-gradient(0deg, ${item.background} 0%, rgba(226, 34, 38, 0) 60%)`,
                backgroundSize: "cover",
              }}
              className={`my-2 p-4 text-center rounded-[15px] shadow-[1px_3px_8px_0_#00000040] h-[164px] bg-center bg-blend-overlay flex flex-col justify-end`}
            >
              <h1 className="text-white font-bold text-xs">{item.title}</h1>
            </div>
          </Link>
        </SwiperSlide>
      ))}
    </CustomCarousel>
  );
};

export default CommunityCarousel;
