import StartHereCarousel from "components/Pages/StartHere";
import useDocumentTitle from "hooks/useDocumentTitle";
import useLayoutConfig from "hooks/useLayoutConfig";

const StartHere = () => {
  useLayoutConfig("white", true);
  useDocumentTitle("Muskurahat Foundation | Start Here");

  return <StartHereCarousel />;
};

export default StartHere;
