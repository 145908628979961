import { db } from "config/firebase";
import { collection, getDocs } from "firebase/firestore";
import { createContext, useEffect, useState } from "react";

export const TrainingModulesContext = createContext();

const TrainingModulesProvider = ({ children }) => {
  const [modules, setModules] = useState([]);
  const [isLoading, setIsLoading] = useState(false);

  useEffect(() => {
    const setup = async () => {
      setIsLoading(true);
      const ref = collection(db, "training-modules");
      const snapshot = await getDocs(ref);
      const tempModules = snapshot.docs.map((doc) => ({
        id: doc.id,
        ...doc.data(),
      }));
      setModules(tempModules);
      setIsLoading(false);
    };
    setup();
  }, []);

  return (
    <TrainingModulesContext.Provider value={{ modules, isLoading }}>
      {children}
    </TrainingModulesContext.Provider>
  );
};

export default TrainingModulesProvider;
