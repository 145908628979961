import checks from "assets/images/backgrounds/checks.png";
import backArrow from "assets/images/icons/back-arrow.png";
import power from "assets/images/pages/hacks/power.png";
import extend from "assets/images/pages/hacks/extend.png";
import community from "assets/images/pages/hacks/community.png";
import network from "assets/images/pages/hacks/network.png";
import growth from "assets/images/pages/hacks/growth.png";
import useLayoutConfig from "hooks/useLayoutConfig";
import { useNavigate } from "react-router-dom";

const overlayStyle = {
  backgroundPosition: "center center",
  borderRadius: "50px 50px 35px 35px",
  backgroundColor: "#fff",
  backgroundImage: `url(${checks})`,
  paddingBottom: 10,
  marginTop: 30,
  boxShadow: "0px -4px 4px 0px rgba(0, 0, 0, 0.25)",
  padding: "50px 0px",
  zIndex: 9,
  position: "relative",
};

const HackFour = () => {
  const navigate = useNavigate();
  useLayoutConfig("white", true);
  const handleBackClick = () => {
    navigate(-1);
  };

  return (
    <>
      <div className="flex justify-center items-center mt-10 relative">
        <img
          src={backArrow}
          alt=""
          onClick={handleBackClick}
          className="absolute left-5 top-1/2 -translate-y-1/2 w-5"
        />
        <h1
          className={`font-bold text-secondary text-xl text-center flex-grow`}
        >
          CROWDFUNDING
          <br />
          <span className="text-tertiary">HACKS FOR YOU</span>
        </h1>
      </div>
      <div className="text-center p-5 mt-10 mx-5 bg-tertiary rounded-[10px] shadow-[0px_2px_4px_rgba(0,0,0,0.25)]">
        <h2 className="text-xs text-white font-bold">
          OFFERING VALUE AND <br />
          <span className="text-secondary"> EXPANDING YOUR NETWORK</span>
        </h2>
        <p className="text-[10px] mt-5 text-white">
          Expanding your network and offering value to people are essential
          skills for a successful fundraising career. As an intern, you have
          unique opportunities to connect with a diverse range of individuals.
          Here are strategies to help you offer value to others and expand your
          network, along with some valuable mindset pointers.
        </p>
      </div>
      <div style={overlayStyle}>
        <div className="relative">
          <div className="w-3/4 mt-5 pb-2 bg-cover bg-[url('assets/images/pages/hacks/note1.png')]">
            <h2 className="font-bold pt-5 pb-7 pl-5 text-primary text-lg text-left">
              UTILIZE YOUR <br />
              <span className="relative text-secondary before:content-[' '] before:bg-contain before:block before:absolute before:bg-[url('assets/images/icons/underline.webp')] before:w-full before:h-[10px] before:bg-no-repeat before:-bottom-2 inline-block">
                NETWORK&apos;S NETWORK
              </span>
            </h2>
          </div>
          <img src={network} className="w-24 absolute right-0 top-0" />
          <div className="mx-5 bg-white rounded-[10px] shadow-[0px_2px_4px_rgba(0,0,0,0.25)] p-5">
            <ul className="ml-3">
              <li className="text-xs font-bold text-primary before:content-['\2022'] relative before:top-0 before:-left-2 before:-mt-3 before:absolute before:text-3xl before:text-tertiary before:font-bold before:inline-block before:w-5 before:-ml-4">
                Strategy -
              </li>
              <li className="text-xs mt-2">
                Tap into your existing network&apos;s connections, including
                parents, friends, and acquaintances.
              </li>
              <li className="text-xs mt-4 font-bold text-primary before:content-['\2022'] relative before:top-0 before:-left-2 before:-mt-3 before:absolute before:text-3xl before:text-tertiary before:font-bold before:inline-block before:w-5 before:-ml-4">
                Why -
              </li>
              <li className="text-xs mt-2">
                Many interns have found significant donations within their
                extended network.
              </li>
              <li className="text-xs mt-4 font-bold text-primary before:content-['\2022'] relative before:top-0 before:-left-2 before:-mt-3 before:absolute before:text-3xl before:text-tertiary before:font-bold before:inline-block before:w-5 before:-ml-4">
                Tip -
              </li>
              <li className="text-xs mt-2">
                Reach out to your network and ask for introductions to potential
                donors.
              </li>
            </ul>
          </div>
        </div>

        <div className="relative">
          <div className="flex justify-between mt-16">
            <div></div>
            <div className="w-2/3 pb-2 bg-cover bg-[url('assets/images/pages/hacks/note2.png')]">
              <h2 className="font-bold pt-5 pb-7 pr-5 text-tertiary text-lg text-right">
                LEVERAGE <br />
                <span className="relative text-secondary before:content-[' '] before:bg-contain before:block before:absolute before:bg-[url('assets/images/icons/underline.webp')] before:w-full before:h-[10px] before:bg-no-repeat before:-bottom-2 inline-block">
                  COMMUNITIES
                </span>
              </h2>
            </div>
          </div>
          <img src={community} className="w-20 absolute left-5 -top-2" />

          <div className="mx-5 mt-3 bg-white rounded-[10px] shadow-[0px_2px_4px_rgba(0,0,0,0.25)] p-5">
            <ul className="ml-3">
              <li className="text-xs font-bold text-primary before:content-['\2022'] relative before:top-0 before:-left-2 before:-mt-3 before:absolute before:text-3xl before:text-tertiary before:font-bold before:inline-block before:w-5 before:-ml-4">
                Strategy -
              </li>
              <li className="text-xs mt-2">
                Utilize the communities you are part of, whether on campus,
                through social groups, or in professional organizations.
              </li>
              <li className="text-xs mt-4 font-bold text-primary before:content-['\2022'] relative before:top-0 before:-left-2 before:-mt-3 before:absolute before:text-3xl before:text-tertiary before:font-bold before:inline-block before:w-5 before:-ml-4">
                Why -
              </li>
              <li className="text-xs mt-2">
                Communities offer a fertile ground for networking and building
                connections.
              </li>
              <li className="text-xs mt-4 font-bold text-primary before:content-['\2022'] relative before:top-0 before:-left-2 before:-mt-3 before:absolute before:text-3xl before:text-tertiary before:font-bold before:inline-block before:w-5 before:-ml-4">
                Tip -
              </li>
              <li className="text-xs mt-2">
                Actively engage in community events and activities to expand
                your reach.
              </li>
            </ul>
          </div>
        </div>

        <div className="relative">
          <div className="flex mt-16">
            <div className="w-2/3 pb-2 bg-cover bg-[url('assets/images/pages/hacks/note3.png')]">
              <h2 className="font-bold pt-5 pb-7 pl-5 text-primary text-lg text-left">
                THE POWER OF ONE
                <br />
                <span className="relative text-secondary before:content-[' '] before:bg-contain before:block before:absolute before:bg-[url('assets/images/icons/underline.webp')] before:w-full before:h-[10px] before:bg-no-repeat before:-bottom-2 inline-block">
                  CONNECTION:
                </span>
              </h2>
            </div>
          </div>
          <img src={power} className="w-20 absolute right-5 -top-2" />

          <div className="mx-5 mt-3 bg-white rounded-[10px] shadow-[0px_2px_4px_rgba(0,0,0,0.25)] p-5">
            <ul className="ml-3">
              <li className="text-xs font-bold text-primary before:content-['\2022'] relative before:top-0 before:-left-2 before:-mt-3 before:absolute before:text-3xl before:text-tertiary before:font-bold before:inline-block before:w-5 before:-ml-4">
                Strategy -
              </li>
              <li className="text-xs mt-2">
                Recognize that connecting with one influential person can grant
                you access to vast networks.
              </li>
              <li className="text-xs mt-4 font-bold text-primary before:content-['\2022'] relative before:top-0 before:-left-2 before:-mt-3 before:absolute before:text-3xl before:text-tertiary before:font-bold before:inline-block before:w-5 before:-ml-4">
                Why -
              </li>
              <li className="text-xs mt-2">
                A single key connection can open doors to new opportunities and
                resources.
              </li>
              <li className="text-xs mt-4 font-bold text-primary before:content-['\2022'] relative before:top-0 before:-left-2 before:-mt-3 before:absolute before:text-3xl before:text-tertiary before:font-bold before:inline-block before:w-5 before:-ml-4">
                Tip -
              </li>
              <li className="text-xs mt-2">
                Seek out and nurture relationships with individuals who have
                broad networks.
              </li>
            </ul>
          </div>
        </div>

        <div className="relative">
          <div className="flex justify-between mt-16">
            <div></div>
            <div className="w-2/4 pb-2 bg-cover bg-[url('assets/images/pages/hacks/note2.png')]">
              <h2 className="font-bold pt-5 pb-7 pr-5 text-tertiary text-lg text-right">
                EXTEND YOUR NETWORK
                <br />
                <span className="relative text-secondary before:content-[' '] before:bg-contain before:block before:absolute before:bg-[url('assets/images/icons/underline.webp')] before:w-full before:h-[10px] before:bg-no-repeat before:-bottom-2 inline-block">
                  BEYOND WHAT&apos;S VISIBLE
                </span>
              </h2>
            </div>
          </div>
          <img src={extend} className="w-28 absolute left-5 -top-2" />

          <div className="mx-5 mt-3 bg-white rounded-[10px] shadow-[0px_2px_4px_rgba(0,0,0,0.25)] p-5">
            <ul className="ml-3">
              <li className="text-xs font-bold text-primary before:content-['\2022'] relative before:top-0 before:-left-2 before:-mt-3 before:absolute before:text-3xl before:text-tertiary before:font-bold before:inline-block before:w-5 before:-ml-4">
                Strategy -
              </li>
              <li className="text-xs mt-2">
                Understand that your networks extend far beyond what you can
                immediately see or identify.
              </li>
              <li className="text-xs mt-4 font-bold text-primary before:content-['\2022'] relative before:top-0 before:-left-2 before:-mt-3 before:absolute before:text-3xl before:text-tertiary before:font-bold before:inline-block before:w-5 before:-ml-4">
                Why -
              </li>
              <li className="text-xs mt-2">
                A broad perspective on your networks can reveal hidden
                opportunities and connections.
              </li>
              <li className="text-xs mt-4 font-bold text-primary before:content-['\2022'] relative before:top-0 before:-left-2 before:-mt-3 before:absolute before:text-3xl before:text-tertiary before:font-bold before:inline-block before:w-5 before:-ml-4">
                Tip -
              </li>
              <li className="text-xs mt-2">
                Continually explore and be open to connections that may not be
                obvious at first.
              </li>
            </ul>
          </div>
        </div>

        <div className="relative">
          <div className="flex mt-16">
            <div className="w-2/3 pb-2 bg-cover bg-[url('assets/images/pages/hacks/note3.png')]">
              <h2 className="font-bold pt-5 pb-7 pl-5 text-primary text-lg text-left">
                EMBRACE OPTIMISM
                <br />
                <span className="relative text-secondary before:content-[' '] before:bg-contain before:block before:absolute before:bg-[url('assets/images/icons/underline.webp')] before:w-full before:h-[10px] before:bg-no-repeat before:-bottom-2 inline-block">
                  AND GROWTH
                </span>
              </h2>
            </div>
          </div>
          <img src={growth} className="w-24 absolute right-5 top-0" />

          <div className="mx-5 mt-3 bg-white rounded-[10px] shadow-[0px_2px_4px_rgba(0,0,0,0.25)] p-5">
            <ul className="ml-3">
              <li className="text-xs font-bold text-primary before:content-['\2022'] relative before:top-0 before:-left-2 before:-mt-3 before:absolute before:text-3xl before:text-tertiary before:font-bold before:inline-block before:w-5 before:-ml-4">
                Strategy -
              </li>
              <li className="text-xs mt-2">
                Cultivate optimism and a willingness to grow, recognizing the
                abundance of opportunities around you.
              </li>
              <li className="text-xs mt-4 font-bold text-primary before:content-['\2022'] relative before:top-0 before:-left-2 before:-mt-3 before:absolute before:text-3xl before:text-tertiary before:font-bold before:inline-block before:w-5 before:-ml-4">
                Why -
              </li>
              <li className="text-xs mt-2">
                Optimism and a growth mindset enable you to see and seize
                opportunities others might overlook.
              </li>
              <li className="text-xs mt-4 font-bold text-primary before:content-['\2022'] relative before:top-0 before:-left-2 before:-mt-3 before:absolute before:text-3xl before:text-tertiary before:font-bold before:inline-block before:w-5 before:-ml-4">
                Tip -
              </li>
              <li className="text-xs mt-2">
                Maintain a positive outlook and approach each interaction with
                an open mind.
              </li>
            </ul>
          </div>
        </div>
      </div>

      <div className="bg-secondary pt-20 -mt-8 pb-6 -mb-8 text-center">
        <div className="bg-white mx-5 rounded-[10px] shadow-[0px_2px_4px_rgba(0,0,0,0.25)] p-5">
          <h3 className="text-primary font-bold">CONCLUSION</h3>
          <p className="text-[10px] mt-4">
            Expanding your network and offering value to others are integral to
            your success as a fundraising intern. By utilizing your
            network&apos;s network, leveraging communities, appreciating the
            power of one key connection, extending your networks beyond the
            visible, and embracing optimism and growth, you&apos;ll not only
            create valuable opportunities but also build a network of meaningful
            relationships that can lead to significant support for your cause.
            Remember, the world is full of opportunities, and your optimism and
            willingness to grow will help you recognize and seize them.
          </p>
        </div>
        <div className="mx-5 rounded-[10px] py-5">
          <div className="border-white border-t-[1px] max-w-[75%] m-auto" />
          <div className="flex justify-between mt-5">
            <button
              className="bg-tertiary text-white py-2 px-3 text-xs rounded-lg disabled:bg-[#d9d9d9] disabled:cursor-not-allowed"
              onClick={() => {
                navigate("/user/hacks/hack-3");
              }}
            >
              BACK
            </button>

            <button
              className="bg-primary text-white py-2 px-3 text-xs rounded-lg disabled:bg-[#d9d9d9] disabled:cursor-not-allowed"
              onClick={() => {
                navigate("/user/hacks/hack-5");
              }}
            >
              NEXT HACK
            </button>
          </div>
        </div>
      </div>
    </>
  );
};

export default HackFour;
