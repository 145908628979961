import { db } from "config/firebase";
import { AuthContext } from "context/Auth";
import { doc, updateDoc } from "firebase/firestore";
import { useContext } from "react";
import { useNavigate } from "react-router-dom";
import "swiper/css";
import { Swiper, SwiperSlide, useSwiper } from "swiper/react";

const Buttons = ({
  buttonText,
  nextArrowStyle,
  buttonStyle,
  previousArrowStyle,
}) => {
  const swiper = useSwiper();
  const { profile } = useContext(AuthContext);

  return (
    <>
      {previousArrowStyle ? (
        <p
          onClick={() => {
            swiper.slidePrev();
            updateDoc(doc(db, "users", profile.id), {
              story: swiper.activeIndex,
            });
          }}
          style={previousArrowStyle}
        >
          <span>&#10094;</span>
        </p>
      ) : null}
      {nextArrowStyle ? (
        <p
          style={nextArrowStyle}
          onClick={() => {
            swiper.slideNext();
            updateDoc(doc(db, "users", profile.id), {
              story: swiper.activeIndex,
            });
          }}
        >
          <span>&#10095;</span>
        </p>
      ) : null}
      {buttonStyle ? (
        <button
          style={buttonStyle}
          onClick={() => {
            swiper.slideNext();
            updateDoc(doc(db, "users", profile.id), {
              story: swiper.activeIndex,
            });
          }}
        >
          {buttonText || "HEAR HEAR"}
        </button>
      ) : null}
    </>
  );
};

const StartHereCarousel = () => {
  const navigate = useNavigate();

  const items = [
    {
      id: 1,
      src: require("assets/images/pages/start-here/0.webp"),
      mobileSrc: require("assets/images/pages/start-here/0-mobile.webp"),
      button: (
        <Buttons
          buttonText="HEAR HEAR"
          nextArrowStyle={{
            position: "absolute",
            bottom: "-4px",
            right: "100px",
            fontSize: 18,
            color: "#fff",
            cursor: "pointer",
          }}
          buttonStyle={{
            position: "absolute",
            top: "40%",
            left: "58px",
            width: "300px",
            height: "300px",
            background: "#F6A71C00",
            color: "#ffffff00",
            border: "none",
            padding: "8px 24px",
            font: "Poppins",
            fontWeight: 600,
          }}
        />
      ),
      mobileBtn: (
        <Buttons
          buttonText="HEAR HEAR"
          nextArrowStyle={{
            position: "absolute",
            bottom: "-4px",
            right: "20px",
            fontSize: 18,
            color: "#fff",
          }}
          buttonStyle={{
            fontSize: 12,
            position: "absolute",
            border: "none",
            padding: "8px 24px",
            font: "Poppins",
            fontWeight: 600,
            transform: "translateX(-50%)",
            width: "300px",
            height: "300px",
            top: "20px",
            left: "20px",
            background: "rgb(246 167 28 / 0%)",
            color: "rgb(255 255 255 / 0%)",
          }}
        />
      ),
    },
    {
      id: 2,
      src: require("assets/images/pages/start-here/1.webp"),
      mobileSrc: require("assets/images/pages/start-here/1-mobile.webp"),
      button: (
        <Buttons
          buttonText="LET'S GO"
          previousArrowStyle={{
            position: "absolute",
            bottom: "-4px",
            right: "130px",
            fontSize: 18,
            color: "#000",
            cursor: "pointer",
          }}
          nextArrowStyle={{
            position: "absolute",
            bottom: "-4px",
            right: "100px",
            fontSize: 18,
            color: "#000",
            cursor: "pointer",
          }}
          buttonStyle={{
            width: "auto",
            position: "absolute",
            bottom: "150px",
            left: "50%",
            background: "#F6A71C",
            color: "#fff",
            border: "none",
            padding: "8px 24px",
            font: "Poppins",
            fontWeight: 600,
            boxShadow: "4px 4px 9px #00000033",
            transform: "translateX(-50%)",
          }}
        />
      ),
      mobileBtn: (
        <Buttons
          buttonText="LET'S GO"
          previousArrowStyle={{
            position: "absolute",
            bottom: "-4px",
            right: "50px",
            fontSize: 18,
            color: "#000",
          }}
          nextArrowStyle={{
            position: "absolute",
            bottom: "-4px",
            right: "20px",
            fontSize: 18,
            color: "#000",
          }}
          buttonStyle={{
            fontSize: 12,
            width: "auto",
            position: "absolute",
            bottom: "50px",
            left: "50%",
            background: "#F6A71C",
            color: "#fff",
            border: "none",
            padding: "8px 24px",
            font: "Poppins",
            fontWeight: 600,
            boxShadow: "4px 4px 9px #00000033",
            transform: "translateX(-50%)",
          }}
        />
      ),
    },
    {
      id: 3,
      src: require("assets/images/pages/start-here/2.webp"),
      mobileSrc: require("assets/images/pages/start-here/2-mobile.webp"),
      button: (
        <Buttons
          buttonText="NEXT"
          previousArrowStyle={{
            position: "absolute",
            bottom: "-4px",
            right: "130px",
            fontSize: 18,
            color: "#000",
            cursor: "pointer",
          }}
          nextArrowStyle={{
            position: "absolute",
            bottom: "-4px",
            right: "100px",
            fontSize: 18,
            color: "#000",
            cursor: "pointer",
          }}
          buttonStyle={{
            width: "auto",
            position: "absolute",
            bottom: "20px",
            left: "50%",
            background: "#F6A71C",
            color: "#fff",
            border: "none",
            padding: "8px 24px",
            font: "Poppins",
            fontWeight: 600,
            boxShadow: "4px 4px 9px #00000033",
            transform: "translateX(-50%)",
          }}
        />
      ),
      mobileBtn: (
        <Buttons
          buttonText="NEXT"
          previousArrowStyle={{
            position: "absolute",
            bottom: "-4px",
            right: "50px",
            fontSize: 18,
            color: "#000",
          }}
          nextArrowStyle={{
            position: "absolute",
            bottom: "-4px",
            right: "20px",
            fontSize: 18,
            color: "#000",
          }}
          buttonStyle={{
            fontSize: 12,
            width: "auto",
            position: "absolute",
            bottom: "30px",
            left: "50%",
            background: "#F6A71C",
            color: "#fff",
            border: "none",
            padding: "8px 24px",
            font: "Poppins",
            fontWeight: 600,
            boxShadow: "4px 4px 9px #00000033",
            transform: "translateX(-50%)",
          }}
        />
      ),
    },
    {
      id: 4,
      src: require("assets/images/pages/start-here/3.webp"),
      mobileSrc: require("assets/images/pages/start-here/3-mobile.webp"),
      button: (
        <Buttons
          buttonText="LETS GET STARTED"
          previousArrowStyle={{
            position: "absolute",
            bottom: "-4px",
            right: "130px",
            fontSize: 18,
            color: "#000",
            cursor: "pointer",
          }}
          nextArrowStyle={{
            position: "absolute",
            bottom: "-4px",
            right: "100px",
            fontSize: 18,
            color: "#000",
            cursor: "pointer",
          }}
          buttonStyle={{
            width: "auto",
            position: "absolute",
            top: "60%",
            left: "108px",
            background: "#F6A71C",
            color: "#fff",
            border: "none",
            padding: "8px 24px",
            font: "Poppins",
            fontWeight: 600,
            boxShadow: "4px 4px 9px #00000033",
          }}
        />
      ),
      mobileBtn: (
        <Buttons
          buttonText="LETS GET STARTED"
          previousArrowStyle={{
            position: "absolute",
            bottom: "-4px",
            right: "50px",
            fontSize: 18,
            color: "#000",
          }}
          nextArrowStyle={{
            position: "absolute",
            bottom: "-4px",
            right: "20px",
            fontSize: 18,
            color: "#000",
          }}
          buttonStyle={{
            fontSize: 12,
            width: "auto",
            position: "absolute",
            top: "170px",
            left: "30px",
            background: "#F6A71C",
            color: "#fff",
            border: "none",
            padding: "8px 24px",
            font: "Poppins",
            fontWeight: 600,
            boxShadow: "4px 4px 9px #00000033",
          }}
        />
      ),
    },
    {
      id: 5,
      src: require("assets/images/pages/start-here/4.webp"),
      mobileSrc: require("assets/images/pages/start-here/4-mobile.webp"),
      button: (
        <>
          <Buttons
            buttonText="NEXT"
            previousArrowStyle={{
              position: "absolute",
              bottom: "-4px",
              right: "130px",
              fontSize: 18,
              color: "#000",
              cursor: "pointer",
            }}
            nextArrowStyle={{
              position: "absolute",
              bottom: "-4px",
              right: "100px",
              fontSize: 18,
              color: "#000",
              cursor: "pointer",
            }}
            buttonStyle={{
              width: "auto",
              position: "absolute",
              bottom: "65px",
              left: "50%",
              background: "#F6A71C",
              color: "#fff",
              border: "none",
              padding: "8px 24px",
              font: "Poppins",
              fontWeight: 600,
              boxShadow: "4px 4px 9px #00000033",
              transform: "translateX(-50%)",
            }}
          />

          <div
            style={{
              position: "absolute",
              bottom: "165px",
              display: "flex",
              flexWrap: "wrap",
              left: "50%",
              transform: "translateX(-50%)",
              width: "100%",
              justifyContent: "center",
              gap: "45px",
            }}
          >
            <a
              target="_blank"
              className="img-button"
              href="https://drive.google.com/file/d/1-GgdoTiJcW6uRgDJ1Iu7F63WkxzT2EI9/view?usp=sharing"
              rel="noreferrer"
            >
              <img
                alt="cause"
                style={{
                  width: "180px",
                  background: "#00000000",
                  color: "#fff",
                  border: "none",
                  font: "Poppins",
                  fontWeight: 600,
                  boxShadow: "4px 4px 9px #00000033",
                }}
                src={require("assets/images/pages/start-here/1-button.webp")}
              />
            </a>
            <a
              target="_blank"
              className="img-button"
              href="https://www.instagram.com/muskurahat_foundation/"
              rel="noreferrer"
            >
              <img
                alt="cause"
                style={{
                  width: "180px",
                  background: "#00000000",
                  color: "#fff",
                  border: "none",
                  font: "Poppins",
                  fontWeight: 600,
                  boxShadow: "4px 4px 9px #00000033",
                }}
                src={require("assets/images/pages/start-here/2-button.webp")}
              />
            </a>
            <a
              className="img-button"
              // open in new tab
              target="_blank"
              href="https://drive.google.com/file/d/10UNw8Mjoq9eXF1xyUGCrbpG67uHuRFHr/view?usp=sharing"
              rel="noreferrer"
            >
              <img
                alt="cause"
                style={{
                  width: "180px",
                  background: "#00000000",
                  color: "#fff",
                  border: "none",
                  font: "Poppins",
                  fontWeight: 600,
                  boxShadow: "4px 4px 9px #00000033",
                }}
                src={require("assets/images/pages/start-here/3-button.webp")}
              />
            </a>
            <a
              className="img-button"
              href="https://keep.google.com"
              target="_blank"
              rel="noreferrer"
            >
              <img
                alt="cause"
                style={{
                  width: "180px",
                  background: "#00000000",
                  color: "#fff",
                  border: "none",
                  font: "Poppins",
                  fontWeight: 600,
                  boxShadow: "4px 4px 9px #00000033",
                }}
                src={require("assets/images/pages/start-here/4-button.webp")}
              />
            </a>
          </div>
        </>
      ),
      mobileBtn: (
        <>
          <Buttons
            buttonText="NEXT"
            previousArrowStyle={{
              position: "absolute",
              bottom: "-4px",
              right: "50px",
              fontSize: 18,
              color: "#000",
            }}
            nextArrowStyle={{
              position: "absolute",
              bottom: "-4px",
              right: "20px",
              fontSize: 18,
              color: "#000",
            }}
            buttonStyle={{
              fontSize: 12,
              width: "auto",
              position: "absolute",
              bottom: "25px",
              left: "50%",
              background: "#F6A71C",
              color: "#fff",
              border: "none",
              padding: "8px 24px",
              font: "Poppins",
              fontWeight: 600,
              boxShadow: "4px 4px 9px #00000033",
              transform: "translateX(-50%)",
            }}
          />

          <div
            style={{
              position: "absolute",
              bottom: "90px",
              display: "flex",
              flexWrap: "wrap",
              left: "50%",
              transform: "translateX(-50%)",
              width: "100%",
              justifyContent: "center",
              gap: "20px",
            }}
          >
            <a
              target="_blank"
              className="img-button"
              href="https://drive.google.com/file/d/1-GgdoTiJcW6uRgDJ1Iu7F63WkxzT2EI9/view?usp=sharing"
              rel="noreferrer"
            >
              <img
                alt="cause"
                style={{
                  width: "113px",
                  height: "130px",
                  background: "#00000000",
                  color: "#fff",
                  border: "none",
                  font: "Poppins",
                  fontWeight: 600,
                  boxShadow: "4px 4px 9px #00000033",
                }}
                src={require("assets/images/pages/start-here/1-button.webp")}
              />
            </a>
            <a
              target="_blank"
              className="img-button"
              href="https://www.instagram.com/muskurahat_foundation/"
              rel="noreferrer"
            >
              <img
                alt="cause"
                style={{
                  width: "113px",
                  height: "130px",
                  background: "#00000000",
                  color: "#fff",
                  border: "none",
                  font: "Poppins",
                  fontWeight: 600,
                  boxShadow: "4px 4px 9px #00000033",
                }}
                src={require("assets/images/pages/start-here/2-button.webp")}
              />
            </a>
            <a
              target="_blank"
              className="img-button"
              href="https://drive.google.com/file/d/10UNw8Mjoq9eXF1xyUGCrbpG67uHuRFHr/view?usp=sharing"
              rel="noreferrer"
            >
              <img
                alt="cause"
                style={{
                  width: "113px",
                  height: "130px",
                  background: "#00000000",
                  color: "#fff",
                  border: "none",
                  font: "Poppins",
                  fontWeight: 600,
                  boxShadow: "4px 4px 9px #00000033",
                }}
                src={require("assets/images/pages/start-here/3-button.webp")}
              />
            </a>
            <a
              className="img-button"
              href="https://keep.google.com/"
              target="_blank"
              rel="noreferrer"
            >
              <img
                alt="cause"
                style={{
                  width: "113px",
                  height: "130px",
                  background: "#00000000",
                  color: "#fff",
                  border: "none",
                  font: "Poppins",
                  fontWeight: 600,
                  boxShadow: "4px 4px 9px #00000033",
                }}
                src={require("assets/images/pages/start-here/4-button.webp")}
              />
            </a>
          </div>
        </>
      ),
    },
    {
      id: 6,
      src: require("assets/images/pages/start-here/5.webp"),
      mobileSrc: require("assets/images/pages/start-here/5-mobile.webp"),
      button: (
        <Buttons
          buttonText="NEXT"
          previousArrowStyle={{
            position: "absolute",
            bottom: "-4px",
            right: "130px",
            fontSize: 18,
            color: "#000",
            cursor: "pointer",
          }}
          nextArrowStyle={{
            position: "absolute",
            bottom: "-4px",
            right: "100px",
            fontSize: 18,
            color: "#000",
            cursor: "pointer",
          }}
          buttonStyle={{
            width: "auto",
            position: "absolute",
            bottom: "120px",
            left: "103px",
            background: "#F6A71C",
            color: "#fff",
            border: "none",
            padding: "8px 24px",
            font: "Poppins",
            fontWeight: 600,
            boxShadow: "4px 4px 9px #00000033",
          }}
        />
      ),
      mobileBtn: (
        <Buttons
          buttonText="NEXT"
          previousArrowStyle={{
            position: "absolute",
            bottom: "-4px",
            right: "50px",
            fontSize: 18,
            color: "#000",
          }}
          nextArrowStyle={{
            position: "absolute",
            bottom: "-4px",
            right: "20px",
            fontSize: 18,
            color: "#000",
          }}
          buttonStyle={{
            fontSize: 12,
            width: "auto",
            position: "absolute",
            bottom: "25px",
            left: "50%",
            background: "#F6A71C",
            color: "#fff",
            border: "none",
            padding: "8px 24px",
            font: "Poppins",
            fontWeight: 600,
            boxShadow: "4px 4px 9px #00000033",
            transform: "translateX(-50%)",
          }}
        />
      ),
    },
    {
      id: 7,
      src: require("assets/images/pages/start-here/6.webp"),
      mobileSrc: require("assets/images/pages/start-here/6-mobile.webp"),
      button: (
        <>
          <Buttons
            previousArrowStyle={{
              position: "absolute",
              bottom: "-4px",
              right: "130px",
              fontSize: 18,
              color: "#000",
              cursor: "pointer",
            }}
          />

          <button
            className="story-button"
            style={{
              width: "400px",
              height: "400px",
              position: "absolute",
              bottom: "20px",
              left: "53px",
              background: "#F6A71C00",
              color: "#ffffff00",
              border: "none",
              padding: "8px 24px",
              font: "Poppins",
              fontWeight: 600,
            }}
            onClick={() => {
              navigate("/user/dashboard");
            }}
          >
            BACK TO THE DASHBOARD
          </button>
        </>
      ),
      mobileBtn: (
        <>
          <Buttons
            previousArrowStyle={{
              position: "absolute",
              bottom: "-4px",
              right: "50px",
              fontSize: 18,
              color: "#000",
            }}
          />

          <button
            className="story-button"
            style={{
              fontSize: 12,
              position: "absolute",
              border: "none",
              padding: "8px 24px",
              font: "Poppins",
              fontWeight: 600,
              width: "300px",
              height: "300px",
              top: "200px",
              left: "20px",
              background: "rgb(246 167 28 / 0%)",
              color: "rgb(255 255 255 / 0%)",
            }}
            onClick={() => {
              navigate("/user/dashboard");
            }}
          >
            BACK TO THE DASHBOARD
          </button>
        </>
      ),
    },
  ];

  return (
    <div>
      {window.innerWidth < 768 ? (
        <Swiper slidesPerView={1}>
          {items.map((item) => {
            return (
              <SwiperSlide key={item.id}>
                <img
                  className="h-[calc(100vh-80px)] w-full"
                  src={item.mobileSrc}
                />
                {item.mobileBtn}
              </SwiperSlide>
            );
          })}
        </Swiper>
      ) : (
        <Swiper slidesPerView={1}>
          {items.map((item) => {
            return (
              <SwiperSlide key={item.id}>
                <img src={item.src} className="h-screen" />
                {item.button}
              </SwiperSlide>
            );
          })}
        </Swiper>
      )}
    </div>
  );
};

export default StartHereCarousel;
