import CustomCarousel from "components/CustomCarousel";
import UserLoader from "components/UserLoader";
import { DonationsContext } from "context/Data/Donations";
import { useContext } from "react";
import { SwiperSlide } from "swiper/react";
import smile from "assets/images/icons/smile.png";

const AmountCarousel = () => {
  const { donations, isLoading } = useContext(DonationsContext);
  const dummyArray =
    donations.length < 4
      ? Array.from({ length: 4 - donations.length }, (_, i) => i)
      : [];

  return (
    <CustomCarousel slidesPerView={3.5}>
      {isLoading ? (
        <UserLoader />
      ) : donations.length > 0 ? (
        <>
          {[...donations]
            .map((item, index) => (
              <SwiperSlide key={index}>
                <div className="my-2 p-4 text-center bg-white rounded-[8px] shadow-[1px_3px_8px_0_#00000040] h-[64px]">
                  <h1
                    className="text-primary font-bold"
                    style={{ fontSize: "14px" }}
                  >
                    + {item.amount} /-
                  </h1>
                  <p className="text-quaternary" style={{ fontSize: "9px" }}>
                    {item.name.split(" ")[0]}
                  </p>
                </div>
              </SwiperSlide>
            ))
            .reverse()}
          {dummyArray.map((item, index) => (
            <SwiperSlide key={index}>
              <div className="my-2 p-4 text-center bg-white rounded-[8px] shadow-[1px_3px_8px_0_#00000040] h-[64px] flex justify-center items-center">
                <img src={smile} className="w-5 h-5" />
              </div>
            </SwiperSlide>
          ))}
        </>
      ) : (
        <div className="text-center">Nothing here</div>
      )}
    </CustomCarousel>
  );
};

export default AmountCarousel;
