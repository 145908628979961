import graph from "assets/images/Dashboard/C2.png";
import sidebar from "assets/images/Dashboard/c1.png";
import correct from "assets/images/Dashboard/c3.png";
import chat from "assets/images/Dashboard/c4.png";
import wrapper from "assets/images/Dashboard/c5.png";
import send from "assets/images/Dashboard/send.png";
import checks from "assets/images/backgrounds/checks.png";
import backArrow from "assets/images/icons/back-arrow.png";
import UserCards from "components/Cards/UserCards";
import ReactionPopup from "components/ReactionPopup";
import { db } from "config/firebase";
import modules from "config/modules";

import { AuthContext } from "context/Auth";
import { TrainingModuleDiscussionContext } from "context/Data/TrainingModuleDiscussion";
import NotificationContext from "context/Notification";
import {
  addDoc,
  collection,
  doc,
  increment,
  serverTimestamp,
  writeBatch,
} from "firebase/firestore";
import useDocumentTitle from "hooks/useDocumentTitle";
import useLayoutConfig from "hooks/useLayoutConfig";
import { useContext, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";

const TrainingModules = () => {
  const { profile, setProfile } = useContext(AuthContext);
  useLayoutConfig("white", true);
  const { setNotificationMessage } = useContext(NotificationContext);

  const [message, setMessage] = useState("");

  const [isReactionPopupVisible, setIsReactionPopupVisible] = useState(false);
  const { discussion } = useContext(TrainingModuleDiscussionContext);

  let { id } = useParams();
  const module = modules[id];
  useDocumentTitle(`Muskurahat Foundation | Training Module - ${id}`);

  const navigate = useNavigate();

  const handleBackClick = () => {
    navigate(-1);
  };

  const overlayStyle = {
    backgroundPosition: "center center",
    backgroundColor: "#fff",
    backgroundImage: `url(${checks})`,
  };

  const addMessage = async () => {
    if (message) {
      await addDoc(collection(db, "training-modules", id, "discussion"), {
        message,
        user: {
          referenceCode: profile.referenceCode,
          avatar: profile.avatar,
          id: profile.id,
          name: profile.name,
        },
        timestamp: serverTimestamp(),
        isApproved: false,
      });

      setNotificationMessage("Your message is sent for an approval");
      setMessage("");
    }
  };

  const addReaction = async (reaction) => {
    const batch = writeBatch(db);

    const reactionRef = doc(
      collection(db, "training-modules", id, "reactions"),
      profile.id,
    );

    batch.set(reactionRef, {
      user: {
        referenceCode: profile.referenceCode,
        avatar: profile.avatar,
        id: profile.id,
      },
      timestamp: serverTimestamp(),
      reaction: reaction,
    });

    // create json with all reactions

    const reactionMap = {
      reaction1: {
        reaction1: increment(1),
      },
      reaction2: {
        reaction2: increment(1),
      },
      reaction3: {
        reaction3: increment(1),
      },
      reaction4: {
        reaction4: increment(1),
      },
      reaction5: {
        reaction5: increment(1),
      },
    };

    const moduleRef = doc(db, "training-modules", id);

    batch.set(
      moduleRef,
      {
        reactions: reactionMap[reaction],
      },
      { merge: true },
    );

    await batch.commit();

    setIsReactionPopupVisible(false);
  };

  const updateTrainingModuleProgress = async () => {
    setProfile({
      ...profile,
      trainingModules: profile.trainingModules
        ? [...profile.trainingModules, { id, complete: true }]
        : [{ id, complete: true }],
    });
    setIsReactionPopupVisible(true);
  };

  return (
    <div className="main bg-white relative">
      <div style={overlayStyle} className="shadow-lg">
        <div className="text-white text-center py-12">
          <img
            src={backArrow}
            alt=""
            onClick={handleBackClick}
            className="absolute left-5 top-8 -translate-y-1/2 w-5"
          />
          <div className="relative flex lg:justify-between md:justify-between">
            <img
              src={sidebar}
              alt="Your Image"
              className="w-64 h-auto lg:w-[25rem] md:w-[18rem]"
            />
            <div className="absolute top-0 left-0 w-full h-full text-left justify-center">
              <h2 className="font-bold  pt-5 text-secondary text-lg pl-4 lg:text-2xl lg:pt-[3.25rem] lg:pl-[2rem]">
                {module.title}
              </h2>
              <h2 className="font-bold  text-primary text-lg pl-4 lg:text-2xl lg:pl-[2rem] before:content-[' '] before:bg-contain before:block before:absolute before:bg-[url('assets/images/icons/underline.webp')] before:w-full before:h-[10px] before:bg-no-repeat before:-bottom-2 relative inline-block">
                {module.specialTitle}
              </h2>
            </div>
            <div>
              <img
                src={graph}
                alt=""
                className="lg:w-[207px] lg:pr-[30px] md:w-[137px] md:pr-[30px]"
              />
            </div>
          </div>
          <div>
            <iframe
              className="w-80 h-64 mx-auto rounded-lg"
              src={module.videoLink}
              title="YouTube video player"
              allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
              allowFullScreen
            ></iframe>
          </div>
          <div className="text-secondary font-bold text-base text-center w-64 mx-auto pt-6 uppercase">
            <h2>{module.videoTitle}</h2>
          </div>
          <div className="flex justify-around mx-auto pt-8">
            <button
              disabled={
                profile &&
                profile.trainingModules?.find(
                  (trainingModule) => trainingModule.id === id,
                )?.complete
              }
              className="disabled:bg-gray-300 bg-tertiary w-32 h-11 shadow-md rounded-2xl flex items-center justify-between p-2 relative"
            >
              <div
                className="text-white text-xs font-bold leading-4 cursor-pointer"
                onClick={updateTrainingModuleProgress}
              >
                I FINISHED THIS VIDEO
              </div>
              <img src={correct} alt="" className="w-9 h-9 mr-[-30px]" />
            </button>
            <div className="bg-primary w-32 h-11 shadow-md rounded-2xl flex items-center justify-between p-2">
              <div
                className="text-white text-xs font-bold leading-4 p-5"
                onClick={() => {
                  let discussion = document.getElementById("discussion");
                  discussion.scrollIntoView({ behavior: "smooth" });
                }}
              >
                DISCUSS
              </div>
              <img src={chat} alt="" className="w-9 h-9 ml-2" />
            </div>
          </div>
          <div className="w-80 mx-auto">
            <h1 className="font-bold text-black text-sm text-left pt-8">
              Description
            </h1>
            <p className="font-base text-black text-xs sm:text-sm md:text-base text-justify pt-4 leading-4">
              {module.description}
            </p>
          </div>
        </div>
      </div>
      <div className="relative" id="discussion">
        <img src={wrapper} className="w-3/5 h-full mx-auto" alt="" />

        <h1 className="text-tertiary text-lg lg:text-2xl absolute top-1/2 left-1/2 font-bold transform -translate-x-1/2 -translate-y-1/2">
          <span className="block text-center">GROUP</span>
          <span className="text-secondary text-center text-lg font-bold block">
            DISCUSSION
          </span>
        </h1>
      </div>
      <div>
        {!discussion[id].length ? (
          <h1 className="text-center mb-10">No discussion yet</h1>
        ) : (
          discussion[id].map((message, index) => (
            <UserCards
              key={index}
              referenceCode={message.user.referenceCode}
              name={message.user.name}
              avatar={message.user.avatar}
              message={message.message}
              color={message.color}
              timestamp={message.timestamp}
              isActive={false}
            />
          ))
        )}
      </div>
      <div className="relative w-80 mx-auto">
        <input
          value={message}
          onChange={(e) => setMessage(e.target.value)}
          onKeyDown={(e) => {
            if (e.key === "Enter") {
              addMessage();
            }
          }}
          className="w-full h-12 border border-black rounded-3xl px-4 pr-10 text-xs"
          type="text"
          placeholder="SHARE YOUR THOUGHTS"
        />
        <button onClick={addMessage}>
          <img
            src={send}
            alt="Send"
            className="absolute top-0 right-0 h-full w-12 text-white flex items-center justify-center rounded-r-2xl cursor-pointer"
          />
        </button>
      </div>

      {isReactionPopupVisible && (
        <ReactionPopup
          addReaction={addReaction}
          closePopup={() => setIsReactionPopupVisible(false)}
        />
      )}
    </div>
  );
};

export default TrainingModules;
