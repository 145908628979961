const ImageUpload = ({
  fileToBeUploaded,
  handleCancelImage,
  uploadPerentage,
}) =>
  fileToBeUploaded && (
    <div className="inline-block w-full max-w-[170px] border-solid border-[1px] border-black rounded-lg relative">
      <button
        onClick={handleCancelImage}
        className="absolute bg-gray-200 w-5 h-5 rounded-full flex items-center justify-center right-0 top-0"
      >
        <i className="fa-solid fa-close text-black text-xs"></i>
      </button>
      <img src={URL.createObjectURL(fileToBeUploaded)} className="rounded-lg" />
      <div
        style={{ width: `${uploadPerentage}%` }}
        className={`border-[2px] border-blue-400 border-solid`}
      ></div>
      <div className="text-center text-xs">
        {Math.floor(uploadPerentage)}% uploaded
      </div>
    </div>
  );

export default ImageUpload;
