import Avatar from "components/Avatar";
import { db } from "config/firebase";
import { collection, doc, increment, updateDoc } from "firebase/firestore";
import { motion } from "framer-motion";
import { useState } from "react";
import GifContainer from "../CustomTextareaInput/Gif/GifContainer";

const Post = ({ item }) => {
  const [isReactionOpen, setIsReactionOpen] = useState(false);
  const [isReactionCountExpanded, setIsReactionCountExpanded] = useState(false);

  const toggleReaction = () => {
    setIsReactionOpen(!isReactionOpen);
    setIsReactionCountExpanded(false);
  };

  const toggleReactionCountExpansion = () => {
    setIsReactionCountExpanded(!isReactionCountExpanded);
  };

  const addReaction = async (reaction) => {
    await updateDoc(doc(collection(db, "community-posts"), item.id), {
      [reaction]: increment(1),
    });
    item[`${reaction}`] = item[`${reaction}`] || 0 + 1;

    setIsReactionOpen(false);
  };

  const reactionsMap = () => {
    const reactions = [
      { name: "love", count: item.love || 0, icon: "❤️" },
      { name: "like", count: item.like || 0, icon: "👍" },
      { name: "wow", count: item.wow || 0, icon: "😮" },
      { name: "claps", count: item.claps || 0, icon: "👏" },
      { name: "namaste", count: item.namaste || 0, icon: "🙏" },
    ];

    const sortedReactions = reactions.sort((a, b) => b.count - a.count);
    return sortedReactions;
  };

  const getMostReacted = () => {
    return reactionsMap()[1].count > 0
      ? reactionsMap().slice(0, 2)
      : reactionsMap()[0].count > 0
      ? reactionsMap().slice(0, 1)
      : [];
  };

  const getAllReactions = () => {
    return reactionsMap();
  };

  return (
    <>
      <div className="relative">
        <motion.div
          whileTap={{ scale: 0.9 }}
          onTap={toggleReaction}
          className="flex bg-white p-5 shadow-[0px_2px_2px_rgba(0,0,0,0.25)] rounded-[20px] justify-between mb-5"
        >
          <Avatar avatarNumber={item.user.avatar} borderColor={"#F6A71C"} />
          <div className="w-4/5">
            <h3 className="font-bold text-[10px]">
              {item?.user?.name?.split(" ")[0]}
            </h3>
            <p
              className="text-[10px] font-semibold text-black break-all mb-3"
              dangerouslySetInnerHTML={{
                __html: item.message.replace(/#\w+/g, (match) => {
                  return `<span class="text-secondary">${match}</span>`;
                }),
              }}
            ></p>
            {item.imageURL ? <img src={item.imageURL} /> : null}
            {item.gifId ? <GifContainer id={item.gifId} /> : null}
          </div>
        </motion.div>
        {getMostReacted().length > 0 ? (
          !isReactionOpen ? (
            isReactionCountExpanded ? (
              <motion.div
                onTap={toggleReactionCountExpansion}
                className="absolute bg-white shadow-[0px_2px_2px_rgba(0,0,0,0.25)] rounded-xl flex gap-2 bottom-0 right-0 translate-y-3 -translate-x-10"
              >
                <div className="flex flex-col">
                  {getAllReactions().map((reaction, index) => (
                    <div key={index} className="px-2 py-1">
                      {reaction.icon}
                    </div>
                  ))}
                </div>
                <div className="px-2 flex flex-col rounded-xl bg-[#ECECEC]">
                  {getAllReactions().map((reaction, index) => (
                    <div key={index} className="pr-3 pl-2 py-1 text-left">
                      {reaction.count}
                    </div>
                  ))}
                </div>
              </motion.div>
            ) : (
              <motion.div
                onTap={toggleReactionCountExpansion}
                className="px-2 absolute bg-white shadow-[0px_2px_2px_rgba(0,0,0,0.25)] rounded-full flex bottom-0 right-0 translate-y-3 -translate-x-10"
              >
                {getMostReacted().map((reaction, index) => (
                  <div key={index} className="p-1">
                    {reaction.icon}
                  </div>
                ))}
              </motion.div>
            )
          ) : (
            <></>
          )
        ) : (
          <></>
        )}
        {isReactionOpen && (
          <>
            <motion.div
              onClick={toggleReaction}
              className="absolute w-full h-full rounded-[20px] bg-[#0000005e] left-0 top-0 flex justify-center content-center"
            ></motion.div>

            <div className="absolute flex justify-center content-center left-1/2 top-1/2 -translate-x-1/2 -translate-y-1/2">
              <div className="bg-white flex p-2 justify-between  m-auto rounded-full">
                <button onClick={() => addReaction("love")} className="p-1">
                  ❤️
                </button>
                <button onClick={() => addReaction("like")} className="p-1">
                  👍
                </button>
                <button onClick={() => addReaction("wow")} className="p-1">
                  😮
                </button>
                <button onClick={() => addReaction("claps")} className="p-1">
                  👏
                </button>
                <button onClick={() => addReaction("namaste")} className="p-1">
                  🙏
                </button>
              </div>
            </div>
          </>
        )}
      </div>
    </>
  );
};

export default Post;
