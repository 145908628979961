import { db } from "config/firebase";
import { collection, doc, getDoc } from "firebase/firestore";
import { createContext, useEffect, useState } from "react";

export const InsightsContext = createContext();

const InsightsProvider = ({ children }) => {
  const [insights, setInsights] = useState([]);
  const [isLoading, setIsLoading] = useState(false);

  useEffect(() => {
    const setup = async () => {
      setIsLoading(true);
      const ref = doc(collection(db, "insights"), "insights");
      const snapshot = await getDoc(ref);
      const tempInsights = snapshot.data();
      setInsights(tempInsights);
      setIsLoading(false);
    };
    setup();
  }, []);

  return (
    <InsightsContext.Provider value={{ insights, isLoading }}>
      {children}
    </InsightsContext.Provider>
  );
};

export default InsightsProvider;
