import checks from "assets/images/backgrounds/checks.png";
import backArrow from "assets/images/icons/back-arrow.png";
import Avatar from "components/Avatar";
import CommunityCarousel from "components/Pages/Community/CommunityCarousel";
import CustomTextareaInput from "components/Pages/Community/CustomTextareaInput";
import Post from "components/Pages/Community/Post";
import { db } from "config/firebase";
import { AuthContext } from "context/Auth";
import { CommunityPostsContext } from "context/Data/CommunityPosts";
import NotificationContext from "context/Notification";
import { addDoc, collection, serverTimestamp } from "firebase/firestore";
import useDocumentTitle from "hooks/useDocumentTitle";
import useLayoutConfig from "hooks/useLayoutConfig";
import { useContext, useState } from "react";
import { useNavigate } from "react-router-dom";

const Community = () => {
  useLayoutConfig("white", true);
  const { setNotificationMessage } = useContext(NotificationContext);
  const { profile } = useContext(AuthContext);
  const navigate = useNavigate();

  const handleBackClick = () => {
    navigate(-1);
  };

  const [message, setMessage] = useState("");
  const { posts } = useContext(CommunityPostsContext);
  const [isMessegeAdding, setIsMessegeAdding] = useState(false);
  const [imageURL, setImageURL] = useState(null);
  const [gifId, setGifId] = useState(null);
  const [fileToBeUploaded, setFileToBeUploaded] = useState(null);

  const overlayStyle = {
    backgroundPosition: "center center",
    borderRadius: "50px 50px 35px 35px",
    backgroundColor: "#fff",
    backgroundImage: `url(${checks})`,
    paddingBottom: 10,
    marginTop: 30,
    boxShadow: "0px -4px 4px 0px rgba(0, 0, 0, 0.25)",
    padding: "40px 20px",
  };

  useDocumentTitle("Muskurahat Foundation | Community Board");

  const addPost = async () => {
    if (message) {
      setIsMessegeAdding(true);
      await addDoc(collection(db, "community-posts"), {
        message,
        imageURL,
        gifId,
        user: {
          name: profile.name,
          referenceCode: profile.referenceCode,
          avatar: profile.avatar,
          id: profile.id,
        },
        timestamp: serverTimestamp(),
        isApproved: false,
        isPinned: false,
      });

      setNotificationMessage("Your post is sent for an approval");
      setMessage("");
      setImageURL(null);

      setFileToBeUploaded(null);
      setGifId(null);

      setIsMessegeAdding(false);
    }
  };

  return (
    <>
      <div className="flex justify-center items-center mt-10 relative mb-14">
        <img
          src={backArrow}
          alt=""
          onClick={handleBackClick}
          className="absolute left-5 top-2 w-5"
        />
        <h1 className="font-bold text-secondary text-xl text-center flex-grow">
          <span className="text-tertiary">COMMUNITY</span>
          <br /> BOARD
        </h1>
      </div>

      <CommunityCarousel />

      <div style={overlayStyle}>
        <div className="flex flex-wrap justify-center items-start bg-white p-5 shadow-[0px_2px_4px_rgba(0,0,0,0.25)] rounded-[30px] mb-5 relative gap-2">
          <Avatar avatarNumber={profile?.avatar} borderColor={"#F6A71C"} />
          <CustomTextareaInput
            message={message}
            setMessage={setMessage}
            setImageURL={setImageURL}
            fileToBeUploaded={fileToBeUploaded}
            setFileToBeUploaded={setFileToBeUploaded}
            gifId={gifId}
            setGifId={setGifId}
          />

          <button
            disabled={isMessegeAdding}
            onClick={addPost}
            className="bg-secondary text-white px-5 py-2 rounded-[10px] text-xs"
          >
            POST
          </button>
        </div>

        {posts.map((item, i) => (
          <Post item={item} key={i} />
        ))}
      </div>
    </>
  );
};

export default Community;
