import checks from "assets/images/backgrounds/checks.png";
import backArrow from "assets/images/icons/back-arrow.png";
import engagement from "assets/images/pages/hacks/engagement.png";
import shyness from "assets/images/pages/hacks/shyness.png";
import response from "assets/images/pages/hacks/response.png";
import chat from "assets/images/pages/hacks/chat.png";
import useLayoutConfig from "hooks/useLayoutConfig";
import { useNavigate } from "react-router-dom";

const overlayStyle = {
  backgroundPosition: "center center",
  borderRadius: "50px 50px 35px 35px",
  backgroundColor: "#fff",
  backgroundImage: `url(${checks})`,
  paddingBottom: 10,
  marginTop: 30,
  boxShadow: "0px -4px 4px 0px rgba(0, 0, 0, 0.25)",
  padding: "50px 0px",
  zIndex: 9,
  position: "relative",
};

const HackFive = () => {
  const navigate = useNavigate();
  useLayoutConfig("white", true);
  const handleBackClick = () => {
    navigate(-1);
  };

  return (
    <>
      <div className="flex justify-center items-center mt-10 relative">
        <img
          src={backArrow}
          alt=""
          onClick={handleBackClick}
          className="absolute left-5 top-1/2 -translate-y-1/2 w-5"
        />
        <h1
          className={`font-bold text-secondary text-xl text-center flex-grow`}
        >
          CROWDFUNDING
          <br />
          <span className="text-tertiary">HACKS FOR YOU</span>
        </h1>
      </div>
      <div className="text-center p-5 mt-10 mx-5 bg-tertiary rounded-[10px] shadow-[0px_2px_4px_rgba(0,0,0,0.25)]">
        <h2 className="text-xs text-white font-bold">
          <span className="text-secondary">CAPTURING ATTENTION </span> <br />
          FOR YOUR CAUSE
        </h2>
        <p className="text-[10px] mt-5 text-white">
          Getting people&apos;s attention for your cause is a vital step in
          successful fundraising. To make a meaningful impact, you need to
          engage and inspire individuals to support your mission. Here are
          effective strategies to help you capture and hold people&apos;s
          attention for your cause.
        </p>
      </div>
      <div style={overlayStyle}>
        <div className="relative">
          <div className="w-3/4 mt-5 pb-2 bg-cover bg-[url('assets/images/pages/hacks/note1.png')]">
            <h2 className="font-bold pt-5 pb-7 pl-5 text-primary text-lg text-left">
              LACK OF ENGAGEMENT <br />
              <span className="relative text-secondary before:content-[' '] before:bg-contain before:block before:absolute before:bg-[url('assets/images/icons/underline.webp')] before:w-full before:h-[10px] before:bg-no-repeat before:-bottom-2 inline-block">
                ON PERSONAL CHAT
              </span>
            </h2>
          </div>
          <img src={chat} className="w-24 absolute right-0 top-0" />
          <div className="mx-5 bg-white rounded-[10px] shadow-[0px_2px_4px_rgba(0,0,0,0.25)] p-5">
            <ul className="ml-3">
              <li className="text-xs font-bold text-primary before:content-['\2022'] relative before:top-0 before:-left-2 before:-mt-3 before:absolute before:text-3xl before:text-tertiary before:font-bold before:inline-block before:w-5 before:-ml-4">
                Strategy -
              </li>
              <li className="text-xs mt-2">
                Craft an effective pitch by making your initial message a
                compelling introduction to your cause. Share an
                attention-grabbing statistic or a powerful story to capture
                their interest.
              </li>
              <li className="text-xs mt-4 font-bold text-primary before:content-['\2022'] relative before:top-0 before:-left-2 before:-mt-3 before:absolute before:text-3xl before:text-tertiary before:font-bold before:inline-block before:w-5 before:-ml-4">
                Why -
              </li>
              <li className="text-xs mt-2">
                An engaging initial message is more likely to capture the
                recipient&apo;s interest and can lead to better engagement, as
                it showcases the importance of your cause right from the start.
              </li>
              <li className="text-xs mt-4 font-bold text-primary before:content-['\2022'] relative before:top-0 before:-left-2 before:-mt-3 before:absolute before:text-3xl before:text-tertiary before:font-bold before:inline-block before:w-5 before:-ml-4">
                Tip -
              </li>
              <li className="text-xs mt-2">
                A well-crafted initial message can significantly improve
                engagement on personal chat.
              </li>
            </ul>
          </div>
        </div>

        <div className="relative">
          <div className="flex justify-between mt-16">
            <div></div>
            <div className="w-2/3 pb-2 bg-cover bg-[url('assets/images/pages/hacks/note2.png')]">
              <h2 className="font-bold pt-5 pb-7 pr-5 text-tertiary text-lg text-right">
                &quot;I&apos;LL LOOK INTO IT LATER&ldquo;
                <span className="relative text-secondary before:content-[' '] before:bg-contain before:block before:absolute before:bg-[url('assets/images/icons/underline.webp')] before:w-full before:h-[10px] before:bg-no-repeat before:-bottom-2 inline-block">
                  RESPONSES
                </span>
              </h2>
            </div>
          </div>
          <img src={response} className="w-20 absolute left-5 -top-2" />

          <div className="mx-5 mt-3 bg-white rounded-[10px] shadow-[0px_2px_4px_rgba(0,0,0,0.25)] p-5">
            <ul className="ml-3">
              <li className="text-xs font-bold text-primary before:content-['\2022'] relative before:top-0 before:-left-2 before:-mt-3 before:absolute before:text-3xl before:text-tertiary before:font-bold before:inline-block before:w-5 before:-ml-4">
                Strategy -
              </li>
              <li className="text-xs mt-2">
                Follow up promptly when someone expresses interest but says
                they&apos;ll look into it later. Suggest a specific date or time
                for your next message to maintain their engagement.
              </li>
              <li className="text-xs mt-4 font-bold text-primary before:content-['\2022'] relative before:top-0 before:-left-2 before:-mt-3 before:absolute before:text-3xl before:text-tertiary before:font-bold before:inline-block before:w-5 before:-ml-4">
                Why -
              </li>
              <li className="text-xs mt-2">
                Prompt follow-ups show that you&apos;re serious about your cause
                and care about their interest, making it more likely they will
                engage.
              </li>
              <li className="text-xs mt-4 font-bold text-primary before:content-['\2022'] relative before:top-0 before:-left-2 before:-mt-3 before:absolute before:text-3xl before:text-tertiary before:font-bold before:inline-block before:w-5 before:-ml-4">
                Tip -
              </li>
              <li className="text-xs mt-2">
                Timely follow-ups can increase the likelihood of a response and
                keep the conversation going.
              </li>
            </ul>
          </div>
        </div>

        <div className="relative">
          <div className="flex mt-16">
            <div className="w-2/3 pb-2 bg-cover bg-[url('assets/images/pages/hacks/note3.png')]">
              <h2 className="font-bold pt-5 pb-7 pl-5 text-primary text-lg text-left">
                OVERCOMING EXCUSES
                <br />
                <span className="relative text-secondary before:content-[' '] before:bg-contain before:block before:absolute before:bg-[url('assets/images/icons/underline.webp')] before:w-full before:h-[10px] before:bg-no-repeat before:-bottom-2 inline-block">
                  TO AVOID ENGAGEMENT
                </span>
              </h2>
            </div>
          </div>
          <img src={engagement} className="w-20 absolute right-5 -top-2" />

          <div className="mx-5 mt-3 bg-white rounded-[10px] shadow-[0px_2px_4px_rgba(0,0,0,0.25)] p-5">
            <ul className="ml-3">
              <li className="text-xs font-bold text-primary before:content-['\2022'] relative before:top-0 before:-left-2 before:-mt-3 before:absolute before:text-3xl before:text-tertiary before:font-bold before:inline-block before:w-5 before:-ml-4">
                Strategy -
              </li>
              <li className="text-xs mt-2">
                Address common concerns by preparing responses for typical
                excuses or objections potential donors might have. These
                prepared answers will help you navigate objections and keep the
                conversation flowing.
              </li>
              <li className="text-xs mt-4 font-bold text-primary before:content-['\2022'] relative before:top-0 before:-left-2 before:-mt-3 before:absolute before:text-3xl before:text-tertiary before:font-bold before:inline-block before:w-5 before:-ml-4">
                Why -
              </li>
              <li className="text-xs mt-2">
                Prepared responses demonstrate professionalism and indicate that
                you&apos;ve considered and are ready to address their concerns,
                increasing your credibility.
              </li>
              <li className="text-xs mt-4 font-bold text-primary before:content-['\2022'] relative before:top-0 before:-left-2 before:-mt-3 before:absolute before:text-3xl before:text-tertiary before:font-bold before:inline-block before:w-5 before:-ml-4">
                Tip -
              </li>
              <li className="text-xs mt-2">
                Handling objections with prepared responses can boost your
                confidence and maintain the conversation&apos;s momentum.
              </li>
            </ul>
          </div>
        </div>

        <div className="relative">
          <div className="flex justify-between mt-16">
            <div></div>
            <div className="w-2/4 pb-2 bg-cover bg-[url('assets/images/pages/hacks/note2.png')]">
              <h2 className="font-bold pt-5 pb-7 pr-5 text-tertiary text-lg text-right">
                OVERCOMING SHYNESS
                <br />
                <span className="relative text-secondary before:content-[' '] before:bg-contain before:block before:absolute before:bg-[url('assets/images/icons/underline.webp')] before:w-full before:h-[10px] before:bg-no-repeat before:-bottom-2 inline-block">
                  AND AWKWARDNESS
                </span>
              </h2>
            </div>
          </div>
          <img src={shyness} className="w-28 absolute left-5 -top-2" />

          <div className="mx-5 mt-3 bg-white rounded-[10px] shadow-[0px_2px_4px_rgba(0,0,0,0.25)] p-5">
            <ul className="ml-3">
              <li className="text-xs font-bold text-primary before:content-['\2022'] relative before:top-0 before:-left-2 before:-mt-3 before:absolute before:text-3xl before:text-tertiary before:font-bold before:inline-block before:w-5 before:-ml-4">
                Strategy -
              </li>
              <li className="text-xs mt-2">
                Build confidence by engaging in communication training and
                role-playing exercises to become more comfortable and confident
                in your pitch. As you gain experience, you&apos;ll focus on the
                cause&apos;s impact instead of feeling self-conscious.
              </li>
              <li className="text-xs mt-4 font-bold text-primary before:content-['\2022'] relative before:top-0 before:-left-2 before:-mt-3 before:absolute before:text-3xl before:text-tertiary before:font-bold before:inline-block before:w-5 before:-ml-4">
                Why -
              </li>
              <li className="text-xs mt-2">
                Practice and experience help you overcome shyness and
                awkwardness, allowing you to make a more effective pitch.
                Confidence in your cause is contagious and can inspire potential
                donors.
              </li>
              <li className="text-xs mt-4 font-bold text-primary before:content-['\2022'] relative before:top-0 before:-left-2 before:-mt-3 before:absolute before:text-3xl before:text-tertiary before:font-bold before:inline-block before:w-5 before:-ml-4">
                Tip -
              </li>
              <li className="text-xs mt-2">
                Persistent effort out of your comfort zone will unlock your
                potential and develop your calibre.
              </li>
            </ul>
          </div>
        </div>
      </div>

      <div className="bg-secondary pt-20 -mt-8 pb-6 -mb-8 text-center">
        <div className="bg-white mx-5 rounded-[10px] shadow-[0px_2px_4px_rgba(0,0,0,0.25)] p-5">
          <h3 className="text-primary font-bold">CONCLUSION</h3>
          <p className="text-[10px] mt-4">
            Expanding your network and offering value to others are integral to
            your success as a fundraising intern. By utilizing your
            network&apos;s network, leveraging communities, appreciating the
            power of one key connection, extending your networks beyond the
            visible, and embracing optimism and growth, you&apos;ll not only
            create valuable opportunities but also build a network of meaningful
            relationships that can lead to significant support for your cause.
            Remember, the world is full of opportunities, and your optimism and
            willingness to grow will help you recognize and seize them.
          </p>
        </div>
        <div className="mx-5 rounded-[10px] py-5">
          <div className="border-white border-t-[1px] max-w-[75%] m-auto" />
          <div className="flex justify-between mt-5">
            <button
              className="bg-tertiary text-white py-2 px-3 text-xs rounded-lg disabled:bg-[#d9d9d9] disabled:cursor-not-allowed"
              onClick={() => {
                navigate("/user/hacks/hack-4");
              }}
            >
              BACK
            </button>

            <button
              className="bg-primary text-white py-2 px-3 text-xs rounded-lg disabled:bg-[#d9d9d9] disabled:cursor-not-allowed"
              onClick={() => {
                navigate("/user/hacks/hack-6");
              }}
            >
              NEXT HACK
            </button>
          </div>
        </div>
      </div>
    </>
  );
};

export default HackFive;
