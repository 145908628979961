const modules = {
  "module-1": {
    title: "HOW TO DEAL",
    specialTitle: "WITH REJECTIONS",
    videoLink: "https://www.youtube.com/embed/-vZXgApsPCQ?si=5chlz4uslzSvSCP0",
    videoTitle: "How crowdfunding enables ideas to become reality!",
    description:
      "With live audience participation, Simon demonstrates what crowdfunding is, how to crowdfund your idea and why you should use crowdfunding to make your idea a reality. Raising Money, working with uncertainty, reducing complexity, are all challenges you will face when trying to make an idea happen, learn how to tackle those with ease.",
  },
  "module-2": {
    title: "HOW TO FOLLOW",
    specialTitle: "UP STRATEGICALLY",
    videoLink: "https://www.youtube.com/embed/5yKWu-awBwU?si=oKwpGSgJB-BlK3Ts",
    videoTitle: "How trading enables ideas to become reality!",
    description:
      "With live audience participation, Simon demonstrates what crowdfunding is, how to crowdfund your idea and why you should use crowdfunding to make your idea a reality. Raising Money, working with uncertainty, reducing complexity, are all challenges you will face when trying to make an idea happen, learn how to tackle those with ease.",
  },
};

export default modules;
