import classes from "./index.module.css";

const UserLoader = () => {
  return (
    <div className={classes.container}>
      <div className={classes.dot}></div>
      <div className={classes.dot}></div>
      <div className={classes.dot}></div>
    </div>
  );
};

export default UserLoader;
