import { db } from "config/firebase";
import { AuthContext } from "context/Auth";
import {
  collection,
  getDocs,
  limit,
  orderBy,
  query,
  where,
} from "firebase/firestore";
import { createContext, useContext, useEffect, useState } from "react";

export const CommunityPostsContext = createContext();

const CommunityPostsProvider = ({ children }) => {
  const [posts, setPosts] = useState([]);
  const { profile } = useContext(AuthContext);

  useEffect(() => {
    if (!profile) return;

    const setup = async () => {
      const ref = collection(db, "community-posts");
      const q = query(
        ref,
        where("isApproved", "==", true),
        orderBy("isPinned", "desc"),
        orderBy("timestamp", "desc"),
        limit(10),
      );

      const snapshot = await getDocs(q);
      const tempPosts = snapshot.docs.map((doc) => ({
        id: doc.id,
        ...doc.data(),
      }));

      const q2 = query(
        ref,
        where("user.id", "==", profile.id),
        where("isApproved", "==", true),
        orderBy("timestamp", "desc"),
      );
      const snapshot2 = await getDocs(q2);
      const tempPosts2 = snapshot2.docs.map((doc) => ({
        id: doc.id,
        ...doc.data(),
      }));
      const tempPosts3 = tempPosts.concat(tempPosts2);
      // sort by timestamp
      tempPosts3.sort((a, b) => b.timestamp - a.timestamp);

      setPosts(tempPosts3);
    };
    setup();
  }, [profile]);

  return (
    <CommunityPostsContext.Provider value={{ posts }}>
      {children}
    </CommunityPostsContext.Provider>
  );
};

export default CommunityPostsProvider;
