// Import the functions you need from the SDKs you need
import { initializeApp } from "firebase/app";
import { getAnalytics } from "firebase/analytics";
import { getFirestore } from "firebase/firestore";
import { getStorage } from "firebase/storage";
import { getAuth } from "firebase/auth";

const firebaseConfig = {
  apiKey: "AIzaSyBXyuYSelzgtvdHY1m_MxHTjd-gHMFJb8g",
  authDomain: "muskurahat-foundation.firebaseapp.com",
  projectId: "muskurahat-foundation",
  storageBucket: "muskurahat-foundation.appspot.com",
  messagingSenderId: "823760337625",
  appId: "1:823760337625:web:350e3d9810a39346f41cc8",
  measurementId: "G-NPS4S33Q5Z",
};

const app = initializeApp(firebaseConfig);
export const db = getFirestore(app);
export const auth = getAuth(app);
export const analytics = getAnalytics(app);
export const storage = getStorage(app);
