import Bulb from "assets/images/icons/bulb.png";
import Counter from "components/Animation/Counter";
import { AuthContext } from "context/Auth";
import { DonationsContext } from "context/Data/Donations";
import { useAnimate } from "framer-motion";
import { useContext, useEffect, useState } from "react";
import ApexChart from "react-apexcharts";
import { Link } from "react-router-dom";

const useHeightAnimation = (expanded) => {
  const [scope, animate] = useAnimate();

  useEffect(() => {
    animate(
      ".animate-height",
      { height: expanded ? "auto" : 0 },
      { duration: 0.3 },
    );
    animate(".arrow", { rotate: expanded ? 180 : 0 }, { duration: 0.2 });
  }, [expanded]);

  return scope;
};

const Chart = ({ expanded, toggleExpand }) => {
  const { profile } = useContext(AuthContext);
  const { donations, isLoading } = useContext(DonationsContext);

  const scope = useHeightAnimation(expanded);

  const [percentage, setPercentage] = useState(0);

  useEffect(() => {
    if (!profile) return;
    let tempPercentage =
      (donations?.reduce((acc, curr) => acc + curr.amount, 0) || 0) *
      (100 / profile.goal);
    if (tempPercentage < 5) {
      tempPercentage = 5;
    }

    setPercentage(tempPercentage);
  }, [profile, donations]);

  return (
    <div ref={scope}>
      <div className="relative max-w-md m-auto">
        <ApexChart
          options={{
            chart: {
              height: 450,
              type: "radialBar",
              offsetY: -20,
              sparkline: {
                enabled: true,
              },
            },

            plotOptions: {
              radialBar: {
                startAngle: -90,
                endAngle: 90,
                track: {
                  background: "#564292",
                  strokeWidth: "70%",
                  margin: 5, // margin is in pixels
                  dropShadow: {
                    enabled: false,
                  },
                },
                hollow: {
                  size: "50%",
                },
                dataLabels: {
                  name: {
                    show: false,
                  },
                  value: {
                    show: false,
                    offsetY: -2,
                    fontSize: "22px",
                  },
                },
              },
            },
            grid: {
              padding: {
                top: -10,
              },
            },
            colors: ["#ffffff"],
            fill: {
              type: "gradient",
              gradient: {
                shade: "light",
                type: "horizontal",
                opacityFrom: 0.5,
                opacityTo: 1,
                stops: [0, 30],
              },
            },
            labels: ["Average Results"],
          }}
          series={[percentage]}
          height={450}
          type="radialBar"
        />

        <div className="text-xs text-white font-bold absolute bottom-16 left-1/2 -translate-x-1/2">
          Amount Raised
        </div>

        <div className="text-2xl underline text-white font-bold absolute bottom-7 left-1/2 -translate-x-1/2">
          ₹{" "}
          {profile && (
            <Counter
              from={0}
              to={donations?.reduce((acc, curr) => acc + curr.amount, 0) | 0}
            />
          )}
        </div>
        <button
          onClick={toggleExpand}
          className={`absolute bottom-2 left-1/2 -translate-x-1/2 border-solid border-x-[10px] border-x-transparent`}
        >
          <div className="arrow" style={{ transformOrigin: "50% 55%" }}>
            <svg width="20" height="20" fill="#ffffff" viewBox="0 0 20 20">
              <path d="M0 7 L 20 7 L 10 16" />
            </svg>
          </div>
        </button>
      </div>

      <div className={`text-white h-0 overflow-hidden animate-height`}>
        <div className="flex gap-10 px-10 justify-between">
          <div className="text-left mb-10">
            <h3 className="text-base font-bold text-left underline mb-5">
              Amount Range
            </h3>
            <div>₹0-3000</div>
            <div>₹3000-4500</div>
            <div>₹4500-9000</div>
            <div>₹9000-18000</div>
            <div>₹18,000+</div>
          </div>
          <div className="text-right">
            <h3 className="text-base font-bold text-right underline mb-5">
              Number of Donations
            </h3>
            {!isLoading ? (
              <>
                <div>
                  {
                    donations.filter((donation) => donation.amount < 3000)
                      .length
                  }
                </div>
                <div>
                  {
                    donations.filter(
                      (donation) =>
                        donation.amount >= 3000 && donation.amount < 4500,
                    ).length
                  }
                </div>
                <div>
                  {
                    donations.filter(
                      (donation) =>
                        donation.amount >= 4500 && donation.amount < 9000,
                    ).length
                  }
                </div>
                <div>
                  {
                    donations.filter(
                      (donation) =>
                        donation.amount >= 9000 && donation.amount < 18000,
                    ).length
                  }
                </div>
                <div>
                  {
                    donations.filter((donation) => donation.amount >= 18000)
                      .length
                  }
                </div>
              </>
            ) : null}
          </div>
        </div>
        <div className="flex text-black font-bold text-[10px] justify-between px-5 gap-3">
          <Link
            to="/user/hacks/hack-2"
            className="bg-white rounded-lg p-3 flex w-1/2 items-center"
          >
            <div className="w-3/4">
              HOW TO CRACK{" "}
              <span className="text-primary">BIGGER DONATIONS</span>
            </div>
            <div className="w-1/4">
              <img className="w-[35px] h-[38px]" src={Bulb} />
            </div>
          </Link>
          <Link
            to="/user/hacks/hack-3"
            className="bg-white rounded-lg p-3 flex w-1/2  items-center"
          >
            <div className="w-3/4">
              HOW TO <span className="text-primary">OPTIMISE YOUR NETWORK</span>{" "}
              FOR RAISING FUNDS
            </div>
            <div className="w-1/4">
              <img className="w-[32px] h-[38px]" src={Bulb} />
            </div>
          </Link>
        </div>
      </div>
    </div>
  );
};

export default Chart;
