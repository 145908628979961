import { ConfigProvider, Slider } from "antd";

const Step4 = ({ goal, setGoal, profile, setProfile }) => {
  const marks = {
    18000: "18k",
    36000: "36k",
    54000: "54k",
    72000: "72k",
    90000: "90k",
    108000: "108k",
  };

  return (
    <>
      <h2 className="text-white font-normal text-lg mb-5">
        Set a Goal for your Campaign
      </h2>
      <div className="max-w-[80%] m-auto mt-8">
        <ConfigProvider
          theme={{
            token: {
              colorPrimary: "#564292",
              colorPrimaryBorder: "#564292",
              colorPrimaryBorderHover: "#564292",
            },
          }}
        >
          <Slider
            marks={marks}
            step={18000}
            defaultValue={18000}
            min={18000}
            max={108000}
            value={goal}
            onChange={(v) => {
              setGoal(v);
            }}
          />
        </ConfigProvider>
      </div>
      <p className="my-4 text-[10px] text-center text-white">
        *Will help us fund the education of{" "}
        <span className="text-white">{Math.ceil((goal || 1) / 18000)}</span>{" "}
        {goal / 18000 === 1 ? "child" : "children"} for an entire year.
      </p>

      <button
        disabled={!goal}
        onClick={() => {
          setProfile({ ...profile, goal, welcomed: true });
        }}
        className="bg-secondary disabled:bg-[#aaa] w-fit rounded-[11px] px-6 py-4 shadow-[1px_3px_8px_0_rgba(0,0,0,0.5)] text-xs text-white font-bold"
      >
        FINISH
      </button>
    </>
  );
};

export default Step4;
