import Avatar1 from "assets/images/avatars/Avatar_1.webp";
import Avatar2 from "assets/images/avatars/Avatar_2.webp";
import Avatar3 from "assets/images/avatars/Avatar_3.webp";
import Avatar4 from "assets/images/avatars/Avatar_4.webp";
import Avatar5 from "assets/images/avatars/Avatar_5.webp";
import Avatar6 from "assets/images/avatars/Avatar_6.webp";
import { getFirstFourAlphabets, isValidReferenceCode } from "config/helpers";
import { useEffect, useState } from "react";

const Step3 = ({ avatar, setAvatar, profile, setProfile }) => {
  const [referenceCode, setReferenceCode] = useState(
    profile?.referenceCode || "",
  );

  const getReferenceCode = () => {
    return (
      getFirstFourAlphabets(
        profile.name + profile.email + "abcd",
      ).toLowerCase() + ("" + Math.random()).substring(2, 6)
    );
  };
  const createReferenceCode = async () => {
    try {
      let isReferenceCodeValid = false;
      let code = "";
      while (!isReferenceCodeValid) {
        code = getReferenceCode();
        isReferenceCodeValid = await isValidReferenceCode(code);
      }

      setReferenceCode(code);
    } catch (err) {
      console.log(err);
    }
  };

  useEffect(() => {
    if (profile?.referenceCode) return;
    createReferenceCode();
  }, [profile]);

  return (
    <>
      <h2 className="text-white font-normal text-lg mb-5">
        Select an Avatar that best describes you
      </h2>
      <div className="flex items-center flex-wrap justify-evenly mb-5 gap-y-5">
        <div className="basis-1/3 flex items-center justify-center">
          <img
            src={Avatar1}
            alt="Avatar"
            className={`w-20 h-20 cursor-pointer ${
              avatar == "1" ? "border-[5px] border-secondary rounded-[50%]" : ""
            }`}
            onClick={() => setAvatar("1")}
          />
        </div>
        <div className="basis-1/3 flex items-center justify-center">
          <img
            src={Avatar2}
            alt="Avatar"
            className={`w-20 h-20 cursor-pointer ${
              avatar == "2" ? "border-[5px] border-secondary rounded-[50%]" : ""
            }`}
            onClick={() => setAvatar("2")}
          />
        </div>
        <div className="basis-1/3 flex items-center justify-center">
          <img
            src={Avatar3}
            alt="Avatar"
            className={`w-20 h-20 cursor-pointer ${
              avatar == "3" ? "border-[5px] border-secondary rounded-[50%]" : ""
            }`}
            onClick={() => setAvatar("3")}
          />
        </div>
        <div className="basis-1/3 flex items-center justify-center">
          <img
            src={Avatar4}
            alt="Avatar"
            className={`w-20 h-20 cursor-pointer ${
              avatar == "4" ? "border-[5px] border-secondary rounded-[50%]" : ""
            }`}
            onClick={() => setAvatar("4")}
          />
        </div>
        <div className="basis-1/3 flex items-center justify-center">
          <img
            src={Avatar5}
            alt="Avatar"
            className={`w-20 h-20 cursor-pointer ${
              avatar == "5" ? "border-[5px] border-secondary rounded-[50%]" : ""
            }`}
            onClick={() => setAvatar("5")}
          />
        </div>
        <div className="basis-1/3 flex items-center justify-center">
          <img
            src={Avatar6}
            alt="Avatar"
            className={`w-20 h-20 cursor-pointer ${
              avatar == "6" ? "border-[5px] border-secondary rounded-[50%]" : ""
            }`}
            onClick={() => setAvatar("6")}
          />
        </div>
      </div>

      <button
        disabled={!avatar || !referenceCode}
        onClick={() => {
          setProfile({
            ...profile,
            avatar,
            goal: 36000,
            welcomed: true,
            referenceCode,
          });
        }}
        className="bg-secondary disabled:bg-[#aaa] w-fit rounded-[11px] px-6 py-4 shadow-[1px_3px_8px_0_rgba(0,0,0,0.5)] text-xs text-white font-bold"
      >
        FINISH
      </button>
    </>
  );
};

export default Step3;
