import Avatar from "components/Avatar";
import { AuthContext } from "context/Auth";
import { useAnimate } from "framer-motion";
import { useContext, useEffect, useState } from "react";

const useAnimateOpacity = (isInsightsPopupOpen) => {
  const [scope, animate] = useAnimate();
  useEffect(() => {
    animate(
      ".animate-opacity",
      { opacity: isInsightsPopupOpen ? 1 : 0 },
      { duration: 0.2 },
    );
  }, [isInsightsPopupOpen]);

  return scope;
};

const Profile = () => {
  const { profile, signOut } = useContext(AuthContext);

  const [isLogoutPopupOpen, setIsLogoutPopupOpen] = useState(false);
  const toggleLogoutPopup = () => {
    setIsLogoutPopupOpen(!isLogoutPopupOpen);
  };
  const scope = useAnimateOpacity(isLogoutPopupOpen);
  return (
    <div
      onClick={toggleLogoutPopup}
      ref={scope}
      className="relative cursor-pointer"
    >
      <Avatar avatarNumber={profile?.avatar || "1"} borderColor="#d9d9d9" />
      <button
        onClick={signOut}
        className="animate-opacity z-10 opacity-0 absolute bg-white text-primary right-0 top-[50px] p-2 text-sm font-bold rounded-lg after:content-[''] after:absolute after:right-2 after:top-[-8px] after:mx-0 after:my-auto after:w-0 after:h-0 after:border-x-[10px] after:border-solid after:border-x-transparent after:border-b-[10px] after:border-b-white"
      >
        LOGOUT
      </button>
    </div>
  );
};

export default Profile;
