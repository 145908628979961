import GoogleIcon from "assets/images/icons/google.svg";
import { AuthContext } from "context/Auth";
import useDocumentTitle from "hooks/useDocumentTitle";
import { useContext, useEffect } from "react";
import { useNavigate } from "react-router";

const Login = () => {
  const { signIn, user } = useContext(AuthContext);
  const navigate = useNavigate();
  useDocumentTitle("Muskurahat Foundation | Login");

  useEffect(() => {
    if (user) {
      navigate("/user/dashboard");
    }
  }, [user]);

  return (
    <div className="px-2 text-center flex items-center justify-center flex-col h-screen">
      <h1 className="text-2xl mb-5 text-white">Login</h1>
      <button
        onClick={signIn}
        className="bg-white w-full rounded-xl p-4 flex justify-around items-center shadow-md hover:shadow-lg"
      >
        <img src={GoogleIcon} className="w-10 h-10" /> Sign In With Google
      </button>
    </div>
  );
};

export default Login;
