const CommunityIcon = ({ isActive }) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    viewBox="0 0 408.77 426.86"
    height="24px"
    // width="24px"
    style={{ maxWidth: 24, maxHeight: 24 }}
    className="m-auto"
  >
    <g id="Layer_2" data-name="Layer 2">
      <g id="Layer_1-2" data-name="Layer 1">
        <path
          fill="none"
          strokeLinecap="round"
          strokeLinejoin="round"
          strokeWidth="13.27px"
          className={isActive ? "stroke-primary" : "stroke-black"}
          d="M108.39,311c-15.9,8.54-36.35,7.54-47.17-6.91-9.1-12.17-6.2-40.71,7.48-49.47,15.2-9.72,38-8.63,48.82,5.82S122.42,303.48,108.39,311Zm117.28-24.67c-18.75,10.07-42.86,8.89-55.61-8.15-10.73-14.34-7.31-48,8.81-58.31,17.93-11.47,44.82-10.19,57.56,6.85S242.21,277.46,225.67,286.35Zm122.21,5.34c-6.47,16.64-22.49,29-39.91,25.1-14.66-3.3-30.81-26.63-26.15-42,5.18-17.08,23-30.91,40.45-27S353.59,277,347.88,291.69Z"
        />
        <path
          fill="none"
          strokeLinecap="round"
          strokeLinejoin="round"
          strokeWidth="15.55px"
          className={isActive ? "stroke-primary" : "stroke-black"}
          d="M361.74,80.33c-.71-27-19.9-56.13-51.16-67.5s-193.26,0-193.26,0C77.47,15.19,51.25,40.54,52,83.17S84.64,140,88.9,142.85s-5,41.92,0,42.63,36.24-30.55,40.5-33.39,46.89,0,51.87,0,21.31,37.66,27.53,37.34,21.49-37.34,24.33-39.47,37.66-.71,46.19,0,39.79,44,46.89,39.07-12.08-39.07,2.84-47.6S362.45,107.33,361.74,80.33Zm-103.67,2.1c-1.56,4.59-5.4,8.92-12.16,16.12C239,105.94,210.29,128,208.49,128c-2.38,0-38.65-20.43-49-34.62C152,83.15,149.9,71,158.12,57.25s19.35-15.65,27-15.38,22.73,8.28,23.33,19.62c0,0,4.24-17.77,25.72-17.5S264,65.05,258.07,82.43Z"
        />
        <path
          fill="none"
          strokeLinecap="round"
          strokeLinejoin="round"
          strokeWidth="15.55px"
          className={isActive ? "stroke-primary" : "stroke-black"}
          d="M106,417.82s-64.65,2.84-76.73,0S6.48,410.71,7.91,390.11,16.43,339.66,43.43,329s22.74.71,29.84,2.85,22.74,5,37-3.56c7.32-4.39-14.88,21.7-15.69,45.84C93.77,396.81,98.85,413.55,106,417.82Z"
        />
        <path
          fill="none"
          strokeLinecap="round"
          strokeLinejoin="round"
          strokeWidth="15.55px"
          className={isActive ? "stroke-primary" : "stroke-black"}
          d="M302.81,417.82s64.66,2.84,76.74,0,22.73-7.11,21.31-27.71-8.53-50.45-35.52-61.11-24.33-1.54-31.43.6-21.15,7.22-35.36-1.31c-7.33-4.39,11.93,21,12.74,45.12C312.05,396.09,309.92,413.55,302.81,417.82Z"
        />
        <path
          fill="none"
          strokeLinecap="round"
          strokeLinejoin="round"
          strokeWidth="15.55px"
          className={isActive ? "stroke-primary" : "stroke-black"}
          d="M133.66,419.08H278.25S295,416.63,297.08,396s-4-56-16.08-66.32-37.56-32-45.73-28.77-8.88,16.09-28.78,15.51-28.42-7-33.39-13-29.13,5.68-40.5,19.18-27,36.92-23.87,75.58a5.34,5.34,0,0,0,.42,1.66C110.73,403.44,117.72,417.09,133.66,419.08Z"
        />
      </g>
    </g>
  </svg>
);

export default CommunityIcon;
