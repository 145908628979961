import { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import CalculatingLoader from "./CalculatingLoader";

const FinishScreen = ({ finalAnswers, assignment }) => {
  const [calculationState, setCalculationState] = useState(0);

  const navigate = useNavigate();

  const [score, setScore] = useState(0);

  const calculationStateMap = {
    0: {
      text: "Calculate Your Results",
      component: null,
      onClick: async () => {
        setCalculationState(1);
        setTimeout(() => {
          setCalculationState(2);
        }, 3000);
      },
    },
    1: {
      text: "Calculating Your Results...",
      component: <CalculatingLoader />,
      onClick: () => {},
    },
    2: {
      text: "Back To Dashboard",
      component: (
        <h2 className="text-xl font-bold text-white">
          You have scored {score} out of {assignment.questions.length}
        </h2>
      ),
      onClick: () => {
        navigate("/user/explore");
      },
    },
  };

  useEffect(() => {
    if (finalAnswers) {
      let score = 0;
      finalAnswers.forEach((answer, index) => {
        if (answer === assignment.questions[index].answer) {
          score++;
        }
      });
      setScore(score);
    }
  }, []);

  return (
    <>
      <div className="mb-20">
        {calculationStateMap[calculationState].component}
      </div>

      <button
        disabled={calculationState === 1}
        onClick={calculationStateMap[calculationState].onClick}
        className="bg-white disabled:bg-gray-200 w-fit rounded-3xl px-10 py-6 shadow-[1px_3px_8px_0_rgba(0,0,0,0.5)] text-xl text-secondary font-bold mb-[100px]"
      >
        {calculationStateMap[calculationState].text}
      </button>
    </>
  );
};

export default FinishScreen;
