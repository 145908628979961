import { tips } from "config/tips";
import ShowDashboardContext from "context/ShowDashboard";
import useLayoutConfig from "hooks/useLayoutConfig";
import { useContext, useEffect } from "react";

import logo from "assets/images/icons/logo.png";
import Avatar from "components/Avatar";
import { AuthContext } from "context/Auth";
import { useAnimate } from "framer-motion";
import useDocumentTitle from "hooks/useDocumentTitle";
import dayjs from "dayjs";
import { useNavigate } from "react-router-dom";

const animatePercentage = () => {
  const [scope, animate] = useAnimate();
  useEffect(() => {
    animate(
      ".animate-percentage",
      {
        backgroundImage:
          "conic-gradient(from 0deg at 50% 50%, #564292 0%, #564292 85%, #ffffff00 85%, #ffffff00 100%)",
      },
      { duration: 2 },
    );
  });
  return scope;
};

const DashboardWelcome = () => {
  const { setShowDashboard } = useContext(ShowDashboardContext);
  const navigate = useNavigate();
  const { profile } = useContext(AuthContext);
  useLayoutConfig("red", false);
  useDocumentTitle("Muskurahat Foundation | Welcome Back");

  const scope = animatePercentage();

  const handleShowDashboard = () => {
    localStorage.setItem(
      "show-dashboard",
      dayjs().add(1, "d").format("YYYY-MM-DD HH:mm:ss"),
    );
    setShowDashboard(true);
    navigate("/user/dashboard");
  };

  return (
    <div className="text-white text-center p-10 flex justify-between flex-col h-[100dvh]">
      <img src={logo} className="w-28" />
      <div
        ref={scope}
        className="bg-[url('assets/images/icons/welcome-dashboard-bg.png')] flex justify-end flex-col items-center text-center bg-center h-[180px] bg-contain bg-no-repeat"
      >
        <div className="p-2 -mb-8 rounded-full animate-percentage bg-[conic-gradient(from_0deg_at_50%_50%,_#564292_0%,_#564292_0%,_#ffffff00_0%,_#ffffff00_100%)]">
          <Avatar
            size={150}
            avatarNumber={profile?.avatar}
            borderColor="#d9d9d9"
          />
        </div>
      </div>
      <div>
        <h2 className="text-[32px] font-bold">WELCOME!</h2>
        <h3 className="text-xl font-semibold uppercase">
          {profile?.referenceCode}
        </h3>
      </div>
      <div>
        <h4 className="text-sm font-bold">TIPS:</h4>
        {/* select random index from array */}
        <p className="text-sm font-medium">
          {tips[Math.floor(Math.random() * tips.length)]}
        </p>
      </div>
      <button
        className="bg-white text-secondary w-full py-5 rounded-[20px] text-xl font-bold"
        onClick={handleShowDashboard}
      >
        GO TO DASHBOARD
      </button>
    </div>
  );
};

export default DashboardWelcome;
