import "App.css";
import Router from "components/Router";
import Context from "context";
import { BrowserRouter } from "react-router-dom";

const App = () => {
  return (
    <Context>
      <BrowserRouter>
        <Router />
      </BrowserRouter>
    </Context>
  );
};

export default App;
