import startImage from "assets/images/pages/assignments/start.png";
import FinishScreen from "components/Pages/Assignments/FinishScreen";
import Questions from "components/Pages/Assignments/Questions";
import StartScreen from "components/Pages/Assignments/StartScreen";
import assignments from "config/assignments";
import { AuthContext } from "context/Auth";
import useDocumentTitle from "hooks/useDocumentTitle";
import useLayoutConfig from "hooks/useLayoutConfig";
import { useContext, useEffect, useState } from "react";
import { useParams } from "react-router-dom";

const Assignments = () => {
  let { id } = useParams();
  const { profile } = useContext(AuthContext);
  const [step, setStep] = useState(0);
  const [question, setQuestion] = useState(0);
  const assignment = assignments[id];
  assignment.id = id;
  const [finalAnswers, setFinalAnswers] = useState(
    profile?.assignments ? profile?.assignments[id] : [],
  );
  useDocumentTitle(`Muskurahat Foundation | Assignments - ${id}`);

  useEffect(() => {
    if (profile?.assignments) setFinalAnswers(profile?.assignments[id]);
  }, [profile]);

  const stepsMap = {
    0: <StartScreen assignment={assignment} setStep={setStep} />,
    1: (
      <Questions
        assignment={assignment}
        question={question}
        setStep={setStep}
        setQuestion={setQuestion}
        finalAnswers={finalAnswers}
        setFinalAnswers={setFinalAnswers}
      />
    ),
    2: <FinishScreen finalAnswers={finalAnswers} assignment={assignment} />,
  };

  useLayoutConfig("yellow");
  return (
    <>
      <div className="flex flex-col justify-center items-center h-screen text-center px-5">
        {stepsMap[step]}
      </div>
      {step === 0 ? (
        <img
          className="absolute bottom-0 w-full max-w-[600px] -z-1"
          src={startImage}
        />
      ) : null}
    </>
  );
};

export default Assignments;
