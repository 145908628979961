import dayjs from "dayjs";
import { useState } from "react";

import DataProvider from "./Data";
import NotificationContext from "./Notification";
import ShowDashboardContext from "./ShowDashboard";
import AuthProvider from "./Auth";
import LayoutProvider from "./Layout";
import StudentLoginProvider from "./StudentLogin";

const Context = ({ children }) => {
  const [notificationMessage, setNotificationMessage] = useState(null);

  const [showDashboard, setShowDashboard] = useState(
    localStorage.getItem("show-dashboard") &&
      dayjs().isBefore(
        dayjs(localStorage.getItem("show-dashboard"), "YYYY-MM-DD HH:mm:ss"),
      )
      ? true
      : false,
  );

  return (
    <StudentLoginProvider>
      <AuthProvider>
        <LayoutProvider>
          <NotificationContext.Provider
            value={{ notificationMessage, setNotificationMessage }}
          >
            <DataProvider>
              <ShowDashboardContext.Provider
                value={{ showDashboard, setShowDashboard }}
              >
                {children}
              </ShowDashboardContext.Provider>
            </DataProvider>
          </NotificationContext.Provider>
        </LayoutProvider>
      </AuthProvider>
    </StudentLoginProvider>
  );
};

export default Context;
