import GifContainer from "./GifContainer";

const SelectedGif = ({ gifId, handleCancelGif }) => {
  return (
    gifId && (
      <>
        <div className="inline-block w-full max-w-[170px] border-solid border-[1px] border-black rounded-lg relative">
          <button
            onClick={handleCancelGif}
            className="z-10 absolute bg-gray-200 w-5 h-5 rounded-full flex items-center justify-center right-0 top-0"
          >
            <i className="fa-solid fa-close text-black text-xs"></i>
          </button>
          <GifContainer id={gifId} />
        </div>
      </>
    )
  );
};

export default SelectedGif;
