import { ConfigProvider, Tour } from "antd";
import { AuthContext } from "context/Auth";
import ShowDashboardContext from "context/ShowDashboard";
import { useContext, useEffect, useState } from "react";

const CustomTour = () => {
  const { profile, setProfile } = useContext(AuthContext);
  const [open, setOpen] = useState(false);
  const { showDashboard } = useContext(ShowDashboardContext);

  useEffect(() => {
    if (!profile) return;
    if (!profile.tour && profile.welcomed && showDashboard) {
      setTimeout(() => {
        setOpen(true);
      }, 100);
    }
  }, [profile]);

  const steps = [
    {
      title: `Hello ${profile?.name},`,
      description: (
        <>
          This is your Internship Dashboard!
          <br /> You can track your progress here!!!
        </>
      ),
      target: document.querySelector("#goal-meter"),
      nextButtonProps: { className: "bg-primary" },
    },
    {
      title: `Your reference code is ${profile?.referenceCode}`,
      description:
        "Every donation you crack will be recorded under this referral code.",
      target: document.querySelector("#reference-code"),
      nextButtonProps: { className: "bg-primary" },
    },
    {
      title: "Donation Link",
      description:
        "Copy your unique link from here and share it with your donors.",
      target: document.querySelector("#donation-link"),
      nextButtonProps: { className: "bg-primary" },
    },
    {
      title: "Rewards",
      description: "Ace your goals and reap the rewards. CLICK HERE!",
      target: document.querySelector("#rewards-button"),
      nextButtonProps: { className: "bg-primary" },
    },
    {
      title: "Days Left",
      description: "Your internship ends in 30 days. Make the most of it!",
      target: document.querySelector("#days-left"),
      nextButtonProps: { className: "bg-primary" },
    },
    {
      title: "Get Started",
      description:
        "Eager to get started? Click on start here and we will help you get going.",
      target: document.querySelector("#get-started"),
      nextButtonProps: { className: "bg-primary" },
    },
  ];

  return (
    <div>
      <ConfigProvider
        theme={{
          token: {
            colorPrimary: "#E22226",
          },
        }}
      >
        <Tour
          open={open}
          onClose={() => setOpen(false)}
          steps={steps}
          onFinish={() => {
            setProfile({ ...profile, tour: true });
          }}
        />
      </ConfigProvider>
    </div>
  );
};

export default CustomTour;
