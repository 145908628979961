import { db } from "config/firebase";
import { collection, getDocs, orderBy, query, where } from "firebase/firestore";
import { createContext, useContext, useEffect, useState } from "react";
import { AuthContext } from "../Auth";

export const DonationsContext = createContext();

const DonationsProvider = ({ children }) => {
  const { profile } = useContext(AuthContext);

  const [donations, setDonations] = useState([]);
  const [isLoading, setIsLoading] = useState(false);

  useEffect(() => {
    if (!profile) return;
    const setup = async () => {
      setIsLoading(true);
      const ref = collection(db, "transactions");
      const q = query(
        ref,
        where("referenceCode", "==", profile.referenceCode),
        orderBy("timestamp"),
      );
      const snapshot = await getDocs(q);
      const tempDonations = snapshot.docs.map((doc) => ({
        id: doc.id,
        ...doc.data(),
      }));
      setDonations(tempDonations);
      setIsLoading(false);
    };
    setup();
  }, [profile]);

  return (
    <DonationsContext.Provider value={{ donations, isLoading }}>
      {children}
    </DonationsContext.Provider>
  );
};

export default DonationsProvider;
