import { gf } from "config/giphy";

const { Gif } = require("@giphy/react-components");
const { useEffect, useState } = require("react");

const GifContainer = ({ id }) => {
  const [data, setData] = useState(null);
  useEffect(() => {
    const setup = async () => {
      const { data } = await gf.gif(id);
      setData(data);
    };
    setup();
  }, [id]);

  return (
    data && (
      <Gif
        gif={data}
        height="auto"
        className="rounded-lg"
        onGifClick={(gif, e) => e.preventDefault()}
        hideAttribution
      />
    )
  );
};

export default GifContainer;
