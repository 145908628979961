import React from "react";
import { Link, useLocation } from "react-router-dom";

const MenuItem = ({ text, link, active, id }) => {
  return (
    <Link
      className={`px-4 py-2 cursor-pointer font-semibold capitalize ${
        active ? "text-white" : "text-[#ffffff80]"
      }`}
      to={link}
      id={id}
    >
      {text}
    </Link>
  );
};

const TopMenu = () => {
  const location = useLocation();
  const routes = [
    {
      text: "DASHBOARD",
      link: "/user/dashboard",
      active: location.pathname.includes("dashboard"),
    },
    {
      text: "EXPLORE",
      link: "/user/explore",
      active: location.pathname.includes("explore"),
      id: "explore-menu-item",
    },
  ];
  return (
    <div className="flex space-x-2 mt-6 pl-5">
      {routes.map((route, i) => (
        <MenuItem key={i} {...route} />
      ))}
    </div>
  );
};

export default TopMenu;
