import classes from "./CalculatingLoader.module.css";

const CalculatingLoader = () => {
  return (
    <div className={classes.book}>
      <div className={classes.inner}>
        <div className={classes.left}></div>
        <div className={classes.middle}></div>
        <div className={classes.right}></div>
      </div>
      <ul>
        <li></li>
        <li></li>
        <li></li>
        <li></li>
        <li></li>
        <li></li>
        <li></li>
        <li></li>
        <li></li>
        <li></li>
        <li></li>
        <li></li>
        <li></li>
        <li></li>
        <li></li>
        <li></li>
        <li></li>
        <li></li>
      </ul>
    </div>
  );
};
export default CalculatingLoader;
