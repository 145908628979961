import { useContext } from "react";
import CommunityPostsProvider from "./CommunityPosts";
import DonationsProvider from "./Donations";
import InsightsProvider from "./Insights";
import TrainingModuleDiscussionProvider from "./TrainingModuleDiscussion";
import TrainingModulesProvider from "./TrainingModules";
import { AuthContext } from "context/Auth";

const DataProvider = ({ children }) => {
  const { profile } = useContext(AuthContext);

  if (!profile) return children;
  return (
    <DonationsProvider>
      <CommunityPostsProvider>
        <TrainingModuleDiscussionProvider>
          <InsightsProvider>
            <TrainingModulesProvider>{children}</TrainingModulesProvider>
          </InsightsProvider>
        </TrainingModuleDiscussionProvider>
      </CommunityPostsProvider>
    </DonationsProvider>
  );
};

export default DataProvider;
