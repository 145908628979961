import {
  Grid,
  SearchBar,
  SearchContext,
  SearchContextManager,
} from "@giphy/react-components";
import { useContext } from "react";

const GifPicker = ({ toggleGifPicker, onGifSelect }) => (
  <SearchContextManager apiKey="o0po4OdolMLQiYKYX0xlCsNBkRcjd0Co">
    <GifPickerWithSearch
      toggleGifPicker={toggleGifPicker}
      onGifSelect={onGifSelect}
    />
  </SearchContextManager>
);

const GifPickerWithSearch = ({ toggleGifPicker, onGifSelect }) => {
  const { fetchGifs, term, channelSearch, activeChannel } =
    useContext(SearchContext);
  return (
    <>
      <div
        onClick={toggleGifPicker}
        className="fixed w-full h-full bg-[#0000005e] left-0 top-0 flex justify-center content-center z-10"
      ></div>
      <div className="fixed w-[300px] bg-gray-200 h-[350px] p-2 left-1/2 -translate-x-1/2 top-1/2 -translate-y-1/2 z-20 overflow-auto">
        <SearchBar className="mb-2" />
        <Grid
          key={`${channelSearch} ${term} ${activeChannel?.user.username}`}
          width={284}
          columns={3}
          limit={10}
          fetchGifs={fetchGifs}
          onGifClick={(gif, e) => {
            e.preventDefault();
            onGifSelect(gif.id);
          }}
          hideAttribution
        />
      </div>
    </>
  );
};

export default GifPicker;
