import React from "react";
import banner from "assets/images/Dashboard/d7.png";

const Cards = ({ text, img, paddingLeft, onClick = () => {}, ...rest }) => {
  const padding = paddingLeft ? "pl-4" : "";
  return (
    <div className="relative cursor-pointer" onClick={onClick} {...rest}>
      <img src={banner} alt="Your Image" className="w-full h-auto" />
      <div
        className={`absolute top-0 left-0  flex items-center justify-center ${
          padding ? "w-11/12 h-full" : "w-full h-full"
        }`}
      >
        <p
          className={`text-black font-semibold ${
            padding ? "pr-4 ml-9" : "pl-4"
          }`}
          style={{ fontSize: "11px" }}
        >
          {text}
        </p>
        <img src={img} alt="" className={` ${padding ? " w-32 h-35 " : " "}`} />
      </div>
    </div>
  );
};

export default Cards;
