import React from "react";
import doublecard from "assets/images/pages/dashboard/doublecard.webp";

const DoubleCards = ({ button1, button2, ...rest }) => {
  const padding = "pl-4";
  return (
    <div className="relative" {...rest}>
      <img src={doublecard} alt="Your Image" className="w-full h-auto" />
      <div className="absolute gap-2 top-0 left-0 w-full flex items-center justify-center h-full">
        <div
          onClick={button1.onClick}
          className={`cursor-pointer flex items-center justify-center ${
            padding ? "w-full h-full" : "w-full h-full"
          }`}
        >
          <p
            className={`text-black font-semibold w-[88px] ${
              padding ? "pr-2 ml-3" : "pl-2"
            }`}
            style={{ fontSize: "11px" }}
          >
            {button1.text}
          </p>
          <img
            src={button1.img}
            alt=""
            className={` ${padding ? " w-30 h-35 " : " "}`}
          />
        </div>
        <div className="w-[1px] h-3/5 border-l-[1px] border-black"></div>
        <div
          onClick={button2.onClick}
          className={`cursor-pointer flex items-center justify-around ${
            padding ? "w-10/12 h-full" : "w-full h-full"
          }`}
        >
          <p
            className={`text-black font-semibold ${
              padding ? "w-2/5 mr-2" : "pl-4"
            }`}
            style={{ fontSize: "11px" }}
          >
            {button2.text}
          </p>
          <img
            src={button2.img}
            alt=""
            className={` ${padding ? "w-12 h-35 " : " "}`}
          />
        </div>
      </div>
    </div>
  );
};

export default DoubleCards;
