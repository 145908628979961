import blankBox from "assets/images/Dashboard/d25.png";
import arrow1 from "assets/images/icons/right-arrow.png";
import Bulb from "assets/images/icons/bulb.png";
import { useState } from "react";
import { useNavigate } from "react-router-dom";
import { Swiper, SwiperSlide, useSwiper } from "swiper/react";

const Navigation = ({ activeIndex }) => {
  const swiper = useSwiper();

  return (
    <div className="mt-2 flex gap-2 justify-center">
      {[0, 1, 2, 3, 4, 5, 6].map((item, index) => (
        <button
          onClick={() => swiper.slideTo(item)}
          key={index}
          className={`w-2 h-2 rounded-full ${
            activeIndex === index ? "bg-tertiary" : "bg-gray-100"
          }`}
        />
      ))}
    </div>
  );
};

const NavButtons = () => {
  const swiper = useSwiper();

  return (
    <>
      <button
        className="z-50 absolute top-1/2 left-0 p-2"
        onClick={() => swiper.slidePrev()}
      >
        <i className="fa-solid fa-chevron-left text-white"></i>
      </button>
      <button
        className="z-50 absolute top-1/2 right-0 p-2"
        onClick={() => swiper.slideNext()}
      >
        <i className="fa-solid fa-chevron-right text-white"></i>
      </button>
    </>
  );
};

const HackCarousel = () => {
  const [activeIndex, setActiveIndex] = useState(0);
  const navigate = useNavigate();
  return (
    <div className="">
      <Swiper
        onActiveIndexChange={(swiper) => setActiveIndex(swiper.activeIndex)}
        slidesPerView={1}
        className=""
      >
        {[
          {
            title: (
              <div className="text-base">
                CROWDFUNDING <span className="text-primary">HACKS</span> FOR YOU
              </div>
            ),
            link: "/user/hacks/hack-1",
          },
          {
            title: (
              <div className="text-sm">
                <span className="text-primary">CAPTURING ATTENTION </span>{" "}
                <br />
                FOR YOUR CAUSE
              </div>
            ),
            link: "/user/hacks/hack-5",
          },
          {
            title: (
              <div className="text-sm">
                MASTERING THE{" "}
                <span className="text-primary">ART OF FOLLOW-UP</span>: BOOSTING
                DONOR ENGAGEMENT INTRODUCTION
              </div>
            ),
            link: "/user/hacks/hack-1",
          },
          {
            title: (
              <div className="text-sm">
                <span className="text-primary">STAYING MOTIVATED </span>
                IN THE FACE OF <br />
                REJECTION AND CHALLENGES
              </div>
            ),
            link: "/user/hacks/hack-6",
          },
          {
            title: (
              <div className="text-sm">
                BUILDING <span className="text-primary">CONNECTIONS </span>
                WITH ANYONE: EFFECTIVE STRATEGIES
              </div>
            ),
            link: "/user/hacks/hack-3",
          },
          {
            title: (
              <div className="text-base">
                UNLOCKING <span className="text-primary">BIGGER DONATIONS</span>
              </div>
            ),
            link: "/user/hacks/hack-2",
          },

          {
            title: (
              <div className="text-sm">
                OFFERING VALUE AND
                <span className="text-primary"> EXPANDING YOUR NETWORK</span>
              </div>
            ),
            link: "/user/hacks/hack-4",
          },
        ].map((item, index) => (
          <SwiperSlide key={index}>
            <div className="max-w-sm rounded overflow-hidden pt-10 m-auto">
              <div
                className="relative mx-8"
                onClick={() => navigate(item.link)}
              >
                <img
                  src={blankBox}
                  alt="Main Image"
                  className="w-full mx-auto"
                />
                <div className="absolute inset-0 flex items-center justify-center">
                  <div className="font-bold text-xl mb-2 text-black w-52 pl-10">
                    {item.title}
                    <img src={arrow1} alt="" className="mt-2 w-5" />
                  </div>

                  <img
                    src={Bulb}
                    alt="Hacks"
                    className="w-28 h-28 mx-auto pr-4"
                  />
                </div>
              </div>
            </div>
          </SwiperSlide>
        ))}

        <NavButtons />
        <Navigation activeIndex={activeIndex} />
      </Swiper>
    </div>
  );
};

export default HackCarousel;
