import YellowBg from "assets/images/pages/dashboard/learning-card-1.png";
import WhiteBg from "assets/images/pages/dashboard/learning-card-2.png";
import { useEffect, useState } from "react";

import BookIcon from "assets/images/pages/dashboard/book.png";
import ChartIcon from "assets/images/pages/dashboard/chart.png";
import CommunityIcon from "assets/images/pages/dashboard/community.png";
import MindIcon from "assets/images/pages/dashboard/mind.png";

import academicsZoomed from "assets/images/pages/dashboard/academics.webp";
import keytaabZoomed from "assets/images/pages/dashboard/keytaab.webp";
import mentalZoomed from "assets/images/pages/dashboard/mental.webp";
import socialZoomed from "assets/images/pages/dashboard/social.webp";

import { useAnimate } from "framer-motion";

const useAnimateZoom = (isTileZoomed, activeTile) => {
  const [scope, animate] = useAnimate();
  const translateXMap = {
    0: {
      0: 0,
      1: "-100%",
      2: "-200%",
      3: "-300%",
    },
    1: {
      0: "100%",
      1: 0,
      2: "-100%",
      3: "-200%",
    },
    2: {
      0: "200%",
      1: "100%",
      2: 0,
      3: "-100%",
    },
    3: {
      0: "300%",
      1: "200%",
      2: "100%",
      3: 0,
    },
  };

  useEffect(() => {
    animate(
      ".z-index-animate.tile-0",
      {
        // opacity: activeTile === null ? 1 : activeTile === 0 ? 1 : 0,
        translateX: isTileZoomed ? translateXMap[0][activeTile] : 0,
      },
      {
        type: "spring",
        duration: 0.5,
      },
    );
    animate(
      ".z-index-animate.tile-1",
      {
        // opacity: activeTile === null ? 1 : activeTile === 1 ? 1 : 0,
        translateX: isTileZoomed ? translateXMap[1][activeTile] : 0,
      },
      {
        type: "spring",
        duration: 0.5,
      },
    );
    animate(
      ".z-index-animate.tile-2",
      {
        // opacity: activeTile === null ? 1 : activeTile === 2 ? 1 : 0,
        translateX: isTileZoomed ? translateXMap[2][activeTile] : 0,
      },
      {
        type: "spring",
        duration: 0.5,
      },
    );
    animate(
      ".z-index-animate.tile-3",
      {
        // opacity: activeTile === null ? 1 : activeTile === 3 ? 1 : 0,
        translateX: isTileZoomed ? translateXMap[3][activeTile] : 0,
      },
      {
        type: "spring",
        duration: 0.5,
      },
    );

    animate(
      ".zoom-animate",
      {
        width: isTileZoomed ? "100%" : "50%",
        height: isTileZoomed ? "365px" : "176px",
        position: isTileZoomed ? "absolute" : "relative",
        left: isTileZoomed ? "0" : "auto",
        top: isTileZoomed ? "0" : "auto",
      },
      {
        duration: 0.5,
      },
    );
  }, [isTileZoomed, activeTile]);

  return scope;
};

const Tile = ({
  title,
  icon,
  isYellow,
  zoomedImage,
  i,
  activeTile,
  isTileZoomed,
  setIsTileZoomed,
  handleNextTile,
  handlePrevTile,
  handleSelectTile,
}) => {
  return (
    <div
      className={`p-2 zoom-animate z-index-animate tile-${i}
       
      ${activeTile === i ? "z-10" : ""}
      `}
      onClick={() => {
        if (isTileZoomed) return;
        setIsTileZoomed(true);
        handleSelectTile(i);
      }}
    >
      <div
        style={{
          backgroundImage: `url('${isYellow ? YellowBg : WhiteBg}')`,
        }}
        className={`rounded-lg bg-cover bg-center bg-no-repeat shadow-[0px_2px_4px_rgba(0,0,0,0.25)] h-full`}
      >
        <div className="flex flex-col justify-between items-center p-4 h-full">
          {isTileZoomed ? (
            <div className="flex justify-between w-full items-start">
              <button
                onClick={() => {
                  setIsTileZoomed(false);
                }}
              >
                <i className="fa-solid fa-close text-black"></i>
              </button>
              <img src={icon} className="text-3xl w-16 self-end" />
            </div>
          ) : (
            <img src={icon} className="text-3xl w-16 self-end" />
          )}

          {isTileZoomed ? (
            <>
              <img className="max-w-3/4 max-h-[70%]" src={zoomedImage} />
              <div className="flex justify-between w-full">
                <div>
                  {i != 0 && (
                    <button
                      className="bg-secondary text-white font-bold text-xs px-3 py-2 shadow-[0px_2px_4px_rgba(0,0,0,0.25)] rounded-xl"
                      onClick={handlePrevTile}
                    >
                      PREVIOUS
                    </button>
                  )}
                </div>
                <div>
                  {i != 3 && (
                    <button
                      className="bg-white font-bold text-xs px-3 py-2 shadow-[0px_2px_4px_rgba(0,0,0,0.25)] rounded-xl"
                      onClick={handleNextTile}
                    >
                      NEXT
                    </button>
                  )}
                </div>
              </div>
            </>
          ) : (
            <span
              className={`text-2xl ${
                isYellow ? "text-white" : "text-gray-800"
              } text-base text-left font-semibold`}
            >
              {title}
            </span>
          )}
        </div>
      </div>
    </div>
  );
};

const LearningCards = () => {
  const data = [
    {
      title: "PROJECT KEYTAAB",
      icon: BookIcon,
      zoomedImage: keytaabZoomed,
      isYellow: true,
    },
    {
      title: "ACADEMIC LEARNING",
      icon: ChartIcon,
      zoomedImage: academicsZoomed,
      isYellow: false,
    },
    {
      title: "SOCIAL EMOTIONAL LEARNING",
      icon: CommunityIcon,
      zoomedImage: socialZoomed,
      isYellow: false,
    },
    {
      title: "MENTAL WELL BEING",
      icon: MindIcon,
      zoomedImage: mentalZoomed,
      isYellow: true,
    },
  ];

  const [activeTile, setActiveTile] = useState(null);
  const [isTileZoomed, setIsTileZoomed] = useState(false);
  const scope = useAnimateZoom(isTileZoomed, activeTile);

  const handleNextTile = () => {
    setActiveTile(activeTile + 1);
  };

  const handlePrevTile = () => {
    setActiveTile(activeTile - 1);
  };

  const handleSelectTile = (i) => {
    setActiveTile(i);
  };

  return (
    <div
      ref={scope}
      className="flex overflow-hidden justify-between my-4 flex-wrap mx-4 max-w-[500px] m-auto h-[370px] relative"
    >
      {data.map(({ title, icon, isYellow, zoomedImage }, i) => (
        <Tile
          key={i}
          title={title}
          icon={icon}
          isYellow={isYellow}
          i={i}
          zoomedImage={zoomedImage}
          activeTile={activeTile}
          isTileZoomed={isTileZoomed}
          setIsTileZoomed={setIsTileZoomed}
          handleNextTile={handleNextTile}
          handlePrevTile={handlePrevTile}
          handleSelectTile={handleSelectTile}
        />
      ))}
    </div>
  );
};

export default LearningCards;
