import smileIcon from "assets/images/icons/smile.png";
import handshakeIcon from "assets/images/icons/handshake.png";
import mindIcon from "assets/images/icons/mind.png";
import videoThumbnail from "assets/images/pages/orientation/video-thumbnail.png";

const chapters = [
  // 1
  {
    title: "Welcome To",
    specialTitle: "Muskurahat",
    specialTitle2: "",

    primaryIcon: smileIcon,
    videoId: "1007894752",
    videoTitle: "How crowdfunding enables ideas to become reality!",
    videoThumbnail: videoThumbnail,

    description:
      "This orientation is your first step towards joining Muskurahat in its mission to provide quality education to the children-in-need. Get started, stay disciplined and by the time we reach the end of this orientation let’s hope for a deeper understanding about the cause and how we are going to solve this massive problem.",
    discussionTitle: (
      <h2 className="text-secondary text-center">
        What Do You Know About <span className="text-primary">Muskurahat?</span>
      </h2>
    ),
    nextTitle: "Journey Then & Life Now!",
    nextDescription: "Learn more about Muskurahat’s Journey",
    nextImage: videoThumbnail,
    popupAt: [3, 7],
  },
  // 2
  {
    title: "Journey Then",
    specialTitle: "& Life Now",
    specialTitle2: "",

    primaryIcon: handshakeIcon,
    videoId: "1007938442",
    videoTitle: "How crowdfunding enables ideas to become reality!",
    videoThumbnail: videoThumbnail,
    description:
      "Ten years of consistently working with our children has brought us closer to understanding their challenges. Our programs have been intricately designed to support their holistic development and make a deeper impact in their lives. Let’s envision a brighter future for our children together.",
    discussionTitle: (
      <h2 className="text-secondary text-center">
        The <span className="text-primary">Manifestation</span> Wall! ✨
      </h2>
    ),
    nextTitle: "Mental Well Being Of The Children",
    nextDescription:
      "Learn More About Muskurahat’s Program for Children-In-Need",
    nextImage: videoThumbnail,
    popupAt: [3, 7],
  },
  // 3
  {
    title: "Mental Well Being",
    specialTitle: "Of The Children",
    specialTitle2: (
      <>
        First pillar of Quality Education &<br /> Holistic Development!
      </>
    ),
    primaryIcon: mindIcon,
    videoId: "1007999433",
    videoTitle: "How crowdfunding enables ideas to become reality!",
    videoThumbnail: videoThumbnail,
    description:
      "Our children are constantly exposed to some of the most discouraging and traumatic experiences in their lives. The need for a conducive environment which fosters a good state of mind, tenacity and sustainable solutions to cope with the challenges of life is too high. Thus, mental wellbeing makes for the first pillar of holistic development.",
    nextTitle: "Master the art of...",
    discussionTitle: (
      <h2 className="text-secondary text-center">Post Your doubts/questions</h2>
    ),
    nextDescription:
      "Learn More About Muskurahat’s Program for Children-In-Need",
    nextImage: videoThumbnail,
    popupAt: [3, 7],
  },
];

export default chapters;
