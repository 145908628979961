import Card from "assets/images/pages/explore/training-module-card.png";
import { Link } from "react-router-dom";
import ByLine from "../ByLine";

const TrainingModule = ({ title, subtitle, id }) => {
  return (
    // id contains podcast

    <Link
      to={
        id.includes("podcast")
          ? id == "podcast-1"
            ? "https://www.youtube.com/watch?v=w4_iVKxaxJg&ab_channel=MuskurahatFoundation"
            : id == "podcast-2"
            ? "https://www.youtube.com/watch?v=x0Z4LsaKLc4&ab_channel=MuskurahatFoundation"
            : "/user/podcast-3"
          : `/user/training-modules/${id}`
      }
      target={id.includes("podcast") ? "_blank" : "_self"}
    >
      <div className={`relative ${id.includes("podcast") ? "mb-10" : ""}`}>
        <img src={Card} />
        <div className="absolute top-1/2 text-left -translate-y-1/2 px-8">
          <h3
            className={`text-2xl text-tertiary font-bold mb-4 before:content-[' '] before:bg-contain before:block before:absolute before:bg-[url('assets/images/icons/underline.webp')] before:w-full before:h-[10px] before:bg-no-repeat before:-bottom-2 relative inline-block ${
              id.includes("podcast") ? "mt-[25px]" : ""
            }`}
          >
            {title}
          </h3>

          <h4 className="text-sm font-semibold">{subtitle}</h4>
        </div>
        {!id.includes("podcast") && <ByLine id={id} />}
      </div>
    </Link>
  );
};

export default TrainingModule;
