import EmojiPicker from "emoji-picker-react";

const EmojiPickerCustom = ({ emojiPicker, toggleEmojiPicker, onClick }) => {
  return (
    emojiPicker && (
      <>
        <div
          onClick={toggleEmojiPicker}
          className="fixed w-full h-full bg-[#0000005e] left-0 top-0 flex justify-center content-center z-10"
        ></div>
        <div className="fixed w-[300px] h-[350px] left-1/2 -translate-x-1/2 top-1/2 -translate-y-1/2 z-20">
          <EmojiPicker
            previewConfig={{ showPreview: false }}
            onEmojiClick={onClick}
            width="300px"
            height="350px"
            suggestedEmojisMode={false}
          />
        </div>
      </>
    )
  );
};

export default EmojiPickerCustom;
