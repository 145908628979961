import { createContext, useState } from "react";

export const StudentLoginContext = createContext();

const StudentLoginProvider = ({ children }) => {
  const [isStudent, setIsStudent] = useState(false);

  return (
    <StudentLoginContext.Provider
      value={{
        isStudent,
        setIsStudent,
      }}
    >
      {children}
    </StudentLoginContext.Provider>
  );
};

export default StudentLoginProvider;
