import { analytics, auth, db } from "config/firebase";
import { StudentLoginContext } from "context/StudentLogin";
import dayjs from "dayjs";
import { setUserProperties } from "firebase/analytics";
import {
  GoogleAuthProvider,
  onIdTokenChanged,
  signInWithEmailAndPassword,
  signInWithPopup,
  signOut,
} from "firebase/auth";
import {
  collection,
  doc,
  getDoc,
  getDocs,
  query,
  setDoc,
  updateDoc,
  where,
} from "firebase/firestore";
import { useContext, useEffect, useState } from "react";

const useAuth = () => {
  const [user, setUser] = useState(null);
  const [profile, setProfile] = useState(null);
  const [isLoading, setIsLoading] = useState(true);
  const { setIsStudent } = useContext(StudentLoginContext);

  const populateOnboardingData = async (user) => {
    // query a collection from firestore
    const collectionRef = collection(db, "intern-applications");
    const q = query(collectionRef, where("Email Id", "==", user.email));
    const querySnap = await getDocs(q);
    if (querySnap.empty) return;
    const docSnap = querySnap.docs[0];

    const data = docSnap.data();
    updateDoc(doc(db, "users", user.uid), {
      onboarding: {
        ...data,
      },
    });
  };

  const handleUser = async (user) => {
    if (user) {
      setUser(user);
      if (user.email.includes("muskurahat-schools.org.in")) setIsStudent(true);

      // get a document from firestore
      const docRef = doc(db, "users", user.uid);
      const docSnap = await getDoc(docRef);
      if (docSnap.exists()) {
        const data = docSnap.data();
        setProfile({
          id: docSnap.id,
          ...data,
        });
      } else {
        setDoc(doc(db, "users", user.uid), {
          googleAuthProvider: {
            name: user.displayName,
            photo: user.photoURL,
          },
          email: user.email,
          createdAt: new Date(),
          expiresAt: new Date(
            dayjs().add(30, "day").format("YYYY-MM-DDTHH:mm:ss"),
          ),
        });
        await populateOnboardingData(user);
        return handleUser(user);
      }
    } else {
      setUser(null);
    }

    setIsLoading(false);
  };

  const signIn = () => {
    const provider = new GoogleAuthProvider();
    signInWithPopup(auth, provider)
      .then((result) => {
        handleUser(result.user);
      })
      .catch((error) => console.log(error));
  };

  const signInWithEmail = (email, password) => {
    signInWithEmailAndPassword(auth, email, password)
      .then((result) => {
        handleUser(result.user);
      })
      .catch((error) => console.log(error));
  };

  const logOut = () => {
    signOut(auth).then(() => handleUser(null));
  };

  useEffect(() => {
    const unsubscribe = onIdTokenChanged(auth, handleUser);
    return () => unsubscribe();
  }, []);

  useEffect(() => {
    if (!profile) return;
    setUserProperties(analytics, {
      goal: profile.goal,
      avatar: profile.avatar,
    });
    const profileWithoutId = { ...profile };
    delete profileWithoutId.id;
    updateDoc(doc(db, "users", profile.id), profileWithoutId);
  }, [profile]);

  return {
    user,
    profile,
    setProfile,
    isLoading,
    signIn,
    signInWithEmail,
    signOut: logOut,
  };
};

export default useAuth;
