import backArrowWhite from "assets/images/icons/back-arrow-white.png";
import communityStories from "config/community-stories";
import useDocumentTitle from "hooks/useDocumentTitle";
import useLayoutConfig from "hooks/useLayoutConfig";
import { useNavigate, useParams } from "react-router-dom";

const CommunityStory = () => {
  useLayoutConfig("white", true);
  let { id } = useParams();
  // const storyIndex =
  //   communityStories.findIndex((item) => item.link === `/${id}`) + 1;
  const story = communityStories[id - 1];

  const navigate = useNavigate();

  useDocumentTitle(`Muskurahat Foundation | Community Story - ${id}`);

  const handleBackClick = () => {
    navigate(-1);
  };

  return (
    <>
      <div className="flex justify-center items-center relative">
        <img
          src={backArrowWhite}
          alt=""
          onClick={handleBackClick}
          className="absolute left-5 top-12 w-5"
        />
      </div>
      <img src={story.image} className="w-full" />
      <div className="p-5">
        <h3 className="font-bold text-xl text-secondary text-left uppercase mb-5">
          {story.title}
        </h3>
        <div className="flex justify-between">
          <span className="text-primary text-[10px]">{story.author}</span>
          <span className="text-quaternary text-[10px]">
            Posted {story.date}
          </span>
        </div>
        <div
          className="text-xs mt-5 text-justify story-content"
          dangerouslySetInnerHTML={{ __html: story.body }}
        ></div>
        <div className="border-black border-t-[1px] max-w-[75%] m-auto" />
        <div className="flex justify-between mt-5">
          <button
            className="bg-tertiary text-white py-2 px-3 text-xs rounded-lg disabled:bg-[#d9d9d9] disabled:cursor-not-allowed"
            disabled={parseInt(id) === 1}
            onClick={() => {
              navigate("/user/community/" + (parseInt(id) - 1));
            }}
          >
            BACK
          </button>

          <button
            className="bg-secondary text-white py-2 px-3 text-xs rounded-lg disabled:bg-[#d9d9d9] disabled:cursor-not-allowed"
            disabled={parseInt(id) === communityStories.length}
            onClick={() => {
              navigate("/user/community/" + (parseInt(id) + 1));
            }}
          >
            NEXT STORY
          </button>
        </div>
      </div>
    </>
  );
};

export default CommunityStory;
