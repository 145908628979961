const Step2 = ({ phone, setPhone, profile, setProfile, setStep }) => {
  const validPhone = (phone) => {
    // regex for 10 digit mobile number
    const re = /^[6-9]\d{9}$/;
    return re.test(phone);
  };

  return (
    <>
      <h2 className="text-white font-normal text-lg mb-5">
        What&apos;s your WhatsApp number?
      </h2>
      <input
        className="w-full h-12 rounded-[20px] px-4 mb-5 text-center"
        value={phone}
        onChange={(e) => setPhone(e.target.value)}
      />
      <button
        disabled={!phone || !validPhone(phone)}
        onClick={() => {
          setProfile({ ...profile, phone });
          setStep(2);
        }}
        className="bg-secondary disabled:bg-[#aaa] w-fit rounded-[11px] px-6 py-4 shadow-[1px_3px_8px_0_rgba(0,0,0,0.5)] text-xs text-white font-bold"
      >
        NEXT
      </button>
    </>
  );
};

export default Step2;
