import Bulb from "assets/images/icons/bulb.png";

import UserLoader from "components/UserLoader";
import { AuthContext } from "context/Auth";
import { InsightsContext } from "context/Data/Insights";
import { useAnimate, useInView } from "framer-motion";
import { useContext, useEffect, useRef, useState } from "react";
import Chart from "react-apexcharts";
import VerticalLockedCard from "../VerticalLockedCard";

const useAnimateOpacity = (isHintVisible) => {
  const [scope, animate] = useAnimate();

  useEffect(() => {
    animate(
      ".animate-opacity",
      { opacity: isHintVisible ? 1 : 0 },
      { duration: 0.2 },
    );
  }, [isHintVisible]);
  return scope;
};

const BiggestDonation = ({ isLocked }) => {
  const { profile } = useContext(AuthContext);
  const [graphData, setGraphData] = useState([]);
  const [isHintVisible, setIsHintVisibile] = useState(false);
  const scope = useAnimateOpacity(isHintVisible);
  const { insights, isLoading } = useContext(InsightsContext);

  useEffect(() => {
    if (!insights) return;
    if (!profile) return;
    setGraphData([insights["max-donation"].amount, profile?.maxDonation | 0]);
  }, [profile, insights]);

  const toggleHint = () => {
    setIsHintVisibile(!isHintVisible);
  };

  const ref = useRef(null);
  const isInView = useInView(ref, { margin: "0px 0px -80px 0px", once: true });

  return (
    <div
      ref={scope}
      className={`p-5 bg-gray-200 bg-[url('assets/images/pages/insights/square.png')] bg-cover shadow-[1px_3px_8px_0_rgba(0,0,0,0.25)] rounded-[20px] h-full`}
    >
      <div className="flex items-center justify-between text-secondary text-lg font-bold">
        <div className="text-left" ref={ref}>
          <h3 className="text-xs">
            <span className="text-tertiary">BIGGEST</span> DONATION
          </h3>
        </div>
        <div className="relative">
          <img onClick={toggleHint} className="w-[47px] mr-1" src={Bulb} />
          <div className="animate-opacity opacity-0 absolute text-black font-normal text-[8px] w-52 border-tertiary border-solid border-[1px] leading-4 -top-[85px] -right-5 bg-white shadow-[1px_3px_8px_0_rgba(0,0,0,0.25)] rounded-xl p-3 after:absolute after:right-6 after:top-full after:-mt-[6px] after:w-3 after:h-3 after:border-[1px] after:border-solid after:border-tertiary after:rotate-45 after:bg-white after:border-t-0 after:border-l-0">
            Your biggest donation compared to the{" "}
            <span className="font-medium text-primary">
              Biggest Donation Cracked by an Intern
            </span>{" "}
            from your batch.
          </div>
        </div>
      </div>
      {isLoading ? (
        <UserLoader />
      ) : (
        <div className="relative">
          <div className={isLocked ? "blur-sm" : ""}>
            {isInView && (
              <Chart
                type="donut"
                width="100%"
                height={150}
                series={graphData}
                options={{
                  colors: ["#564292", "#ddd"],
                  chart: {
                    type: "donut",
                    toolbar: {
                      show: false,
                    },
                  },
                  dataLabels: {
                    enabled: false,
                  },
                  legend: {
                    show: false,
                  },
                }}
              />
            )}
            <div className="flex flex-col pt-3">
              <div className="flex text-[10px] justify-between w-full">
                <div className="w-4 h-4 rounded-full bg-[#ddd]"></div>
                <div className="w-4/5">
                  <div>Your biggest donation</div>
                  <div className="text-primary font-semibold underline">
                    ₹ {graphData[1]}
                  </div>
                </div>
              </div>
              <div className="flex text-[10px] gap-3 w-full mt-3">
                <div className="w-4 h-4 rounded-full bg-secondary"></div>
                <div>
                  <div>Biggest donation</div>
                  <div className="text-primary font-semibold underline">
                    ₹ {graphData[0]}
                  </div>
                </div>
              </div>
            </div>
          </div>
          {isLocked && (
            <VerticalLockedCard title1="BIGGEST" title2="DONATION" />
          )}
        </div>
      )}
    </div>
  );
};

export default BiggestDonation;
