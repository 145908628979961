/* eslint-disable no-unused-vars */
import PlayIcon from "assets/images/icons/play.svg";
import { useRef, useState } from "react";
import ReactPlayer from "react-player/vimeo";
import screenfull from "screenfull";

const VideoPlayer = ({ video, thumbnail }) => {
  const [playing, setPlaying] = useState(false);
  const [width, setWidth] = useState(420);
  const [height, setHeight] = useState(250);
  const [showPopup, setShowPopup] = useState(false);
  const containerRef = useRef(null);
  const [player, setPlayer] = useState(null);
  const videoRef = (player) => setPlayer(player);

  const [hearts, setHearts] = useState([]);

  const handleLikeClick = () => {
    const newHeart = {
      id: Date.now(), // Unique ID for each heart
      x: Math.random() * 80 - 40, // Random horizontal shift for variety
    };

    setHearts((prevHearts) => [...prevHearts, newHeart]);

    // Remove the heart after the animation is complete
    setTimeout(() => {
      console.log("ending now");
      setHearts((prevHearts) =>
        prevHearts.filter((heart) => heart.id !== newHeart.id),
      );
    }, 2000); // Match the animation duration
  };

  // const toggleFullscreen = () => {
  //   const videoContainer = document.getElementById("video");
  //   const video = videoContainer.querySelector("iframe");

  //   if (video.requestFullscreen) {
  //     video.requestFullscreen();
  //   } else if (video.mozRequestFullScreen) {
  //     video.mozRequestFullScreen();
  //   } else if (video.webkitRequestFullscreen) {
  //     video.webkitRequestFullscreen();
  //   } else if (video.msRequestFullscreen) {
  //     video.msRequestFullscreen();
  //   }
  // };

  // const togglePlay = () => {
  //   if (paused) {
  //     toggleFullscreen();
  //   }
  //   setPaused((prev) => !prev);
  // };

  // document.addEventListener("fullscreenchange", () => {
  //   if (!document.fullscreenElement) {
  //     setPaused(true);
  //   }
  // });

  const togglePlay = (updatePlaying = null) => {
    if (updatePlaying !== null) {
      setPlaying(updatePlaying);
    } else {
      setPlaying((prev) => !prev);
    }
  };

  const togglePopup = (updatePopup = null) => {
    console.log("togglePopup", updatePopup);
    if (updatePopup !== null) {
      setShowPopup(updatePopup);
    } else {
      setShowPopup((prev) => !prev);
    }
  };

  if (screenfull.isEnabled) {
    screenfull.on("change", () => {
      if (!screenfull.isFullscreen) {
        setPlaying(false);
        setShowPopup(false);
        setWidth(420);
        setHeight(250);
      }
    });
  }

  const onProgress = (e) => {
    if (e.playedSeconds > 3 && e.playedSeconds < 3.5) {
      togglePlay(false);
      togglePopup(true);
    }
  };

  const makeFullScreen = () => {
    if (screenfull.isEnabled) {
      if (!screenfull.isFullscreen) {
        screenfull.request(containerRef.current);
        switch (screen.orientation.type) {
          case "landscape-primary":
            console.log("That looks good.");
            break;
          case "landscape-secondary":
            console.log("Mmmh… the screen is upside down!");
            break;
          case "portrait-secondary":
          case "portrait-primary":
            console.log("Mmmh… you should rotate your device to landscape");
            break;
          default:
            console.log(
              "The orientation API isn't supported in this browser :(",
            );
        }
        setWidth("100%");
        setHeight("100%");
      }
    }
  };

  const checkForQuestions = (currentTime) => {
    // check if current time has any questions
    if (currentTime > 3 && currentTime < 4) {
      return true;
    }
    return false;
  };

  // when play button is clicked:
  // 1. makeFullscreen()
  // 2. handle progress

  // when progress is made:
  // 1. handle progress

  const handleVideoProgress = () => {
    if (!playing) {
      return;
    }
  };

  // when PLAY button is clicked or progress is made:
  const handlePlayButtonClicked = () => {
    console.log("here");

    // 0. check if video is in full screen, if not then make it full screen
    makeFullScreen();
    // 1. check if the video is played for the first time
    const currentTime = player.getCurrentTime();
    if (currentTime === null) {
      console.log("here2");

      // video is not played yet
      // 2. if yes, then play the video
      togglePlay(true);
    } else {
      console.log("here3");

      // 3. if no, then check if current time has any questions
      const hasQuestions = checkForQuestions(currentTime);
      if (hasQuestions) {
        // 4. if yes, then show the question popup
        togglePopup(true);
      } else {
        // 5. if no, then continue playing the video
        togglePlay(true);
      }
    }
  };

  // when popup is shown:
  // 0. pause the video
  // 1. show the popup
  // 2. when user answers the question, hide the popup
  // 3. continue playing the video

  return (
    <>
      <div className="relative w-full" ref={containerRef}>
        <div
          style={{ background: `url('${thumbnail}')`, backgroundSize: "cover" }}
          className={`relative pt-[56.25%]`}
        >
          <ReactPlayer
            ref={videoRef}
            width="100%"
            height="100%"
            playing={playing && !showPopup}
            className="absolute top-0 left-0"
            controls={false}
            url={`https://vimeo.com/${video}`}
            onProgress={(e) => {
              if (e.playedSeconds > 0) handlePlayButtonClicked(e);
            }}
          />
        </div>
        <div
          className={`${
            showPopup ? "w-1/3" : "w-0"
          } h-full bg-white rounded-l-3xl absolute top-0 right-0 transition-all duration-500`}
        ></div>

        {(!playing || showPopup) && (
          <button
            className="absolute left-1/2 top-1/2 -translate-x-1/2 -translate-y-1/2"
            onClick={handlePlayButtonClicked}
          >
            <img src={PlayIcon} />
          </button>
        )}
      </div>
    </>
  );
};

export default VideoPlayer;
