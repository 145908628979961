import CustomCarousel from "components/CustomCarousel";
import { SwiperSlide } from "swiper/react";

import image1 from "assets/images/Dashboard/image1.png";
import image2 from "assets/images/Dashboard/image2.png";
import { Link } from "react-router-dom";

const ExperienceCarousel = () => {
  const data = [
    {
      id: 1,
      firstName: "ANUSHKA",
      lastName: "SOLANKI",
      imageUrl: image1,
      article: "/user/community/1",
    },
    {
      id: 2,
      firstName: "ETHI",
      lastName: "VERMA",
      imageUrl: image2,
      article: "/user/community/2",
    },
    {
      id: 3,
      firstName: "ETHI",
      lastName: "VERMA",
      imageUrl: image2,
      article: "/user/community/2",
    },
  ];

  return (
    <CustomCarousel slidesPerView={2.5} spaceBetween={20}>
      {data.map((item, index) => (
        <SwiperSlide key={index}>
          <Link to={item.article}>
            <div className="rounded-xl bg-secondary relative">
              <img src={item.imageUrl} alt={item.name} />
              <h1 className="text-right text-18px font-semibold text-white bottom-0 w-full pr-4 pb-4 absolute">
                {item.firstName}
                <br /> {item.lastName}
              </h1>
            </div>
          </Link>
        </SwiperSlide>
      ))}
    </CustomCarousel>
  );
};

export default ExperienceCarousel;
