import { AuthContext } from "context/Auth";
import NotificationContext from "context/Notification";
import { useContext } from "react";

const CopyReferenceCode = () => {
  const { profile } = useContext(AuthContext);
  const { setNotificationMessage } = useContext(NotificationContext);

  const copyReferenceCode = () => {
    // copy to clipboard
    navigator.clipboard.writeText(profile.referenceCode);
    setNotificationMessage("Reference Code is copied");
  };

  return (
    <button
      id="reference-code"
      className="text-white text-xs border-[1px] rounded-[50px] border-white px-4 py-2 uppercase"
      onClick={copyReferenceCode}
    >
      {profile?.referenceCode || "HIMA2024"}
    </button>
  );
};

export default CopyReferenceCode;
