const ExploreIcon = ({ isActive }) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    viewBox="0 0 471.33 408.52"
    height="24px"
    width="24px"
    className="m-auto"
  >
    <g id="Layer_2" data-name="Layer 2">
      <g id="Layer_1-2" data-name="Layer 1">
        <path
          fill="none"
          strokeLinecap="round"
          strokeLinejoin="round"
          strokeWidth="17.07px"
          className={isActive ? "stroke-primary" : "stroke-black"}
          d="M224.63,302.38c-5.34,1.85-6.21,5.87-7.55,11.35-6.92,28.3-1.58,70,2.68,73.88,5.5,5,15,8.1,61.79-41.81,32.24-34.38,48.1-71.51,48.1-71.63.82-11.68-12.46-18.93-22-12.1a227.22,227.22,0,0,1-38.92,22.75C249.43,293.41,233.76,299.21,224.63,302.38Z"
        />
        <path
          fill="none"
          strokeLinecap="round"
          strokeLinejoin="round"
          strokeWidth="17.07px"
          className={isActive ? "stroke-primary" : "stroke-black"}
          d="M133.07,198.65c-2.63,5.42-4.7,10.27-10.67,9.44-20.42-2.84-64.69-9.38-66.42-13-2.24-4.77,28.84-45.64,59.32-66.16s58.79-25.39,63.61-26.22c11.22-2,11.59,17.82,7.94,23.47-7.41,11.45-20.74,22.23-28.08,34C149.28,175.4,137.64,189.2,133.07,198.65Z"
        />
        <path
          fill="none"
          strokeLinecap="round"
          strokeLinejoin="round"
          strokeWidth="13.27px"
          className={isActive ? "stroke-primary" : "stroke-black"}
          d="M461.05,12.9C451.37-1,387.37,9.39,326,33.1c-30.3,11.7-65,33.19-86.79,51.06-68.73,56.26-103.67,97.67-113.49,150.58a13.94,13.94,0,0,0,3,11.53l48.67,57.9a13.81,13.81,0,0,0,10.79,4.95c17.6-.25,62.09-7.61,134.64-64.63,32.84-25.81,68.47-67.19,93-106C452.53,80.4,473.6,30.94,461.05,12.9ZM318.27,173.75c-21.56-5-39.59-22.51-37.83-44.58,1.48-18.58,27.28-42.51,47-39.49,21.88,3.36,42,22.85,40.24,44.92S337.3,178.18,318.27,173.75Z"
        />
        <path
          fill="none"
          strokeLinecap="round"
          strokeLinejoin="round"
          strokeWidth="17.07px"
          className={isActive ? "stroke-primary" : "stroke-black"}
          d="M138.14,291.42s42.86-44.1,65.18-64.75,63.87-48.24,63.87-48.24"
        />
        <path
          fill="none"
          strokeLinecap="round"
          strokeLinejoin="round"
          strokeWidth="13px"
          className={isActive ? "stroke-primary" : "stroke-black"}
          d="M6.5,402s99.62-87.36,103.78-90.09"
        />
        <line
          fill="none"
          strokeLinecap="round"
          strokeLinejoin="round"
          strokeWidth="13px"
          className={isActive ? "stroke-primary" : "stroke-black"}
          x1="19.51"
          y1="353.88"
          x2="81.43"
          y2="300.92"
        />
        <line
          fill="none"
          strokeLinecap="round"
          strokeLinejoin="round"
          strokeWidth="13px"
          className={isActive ? "stroke-primary" : "stroke-black"}
          x1="36.43"
          y1="306.05"
          x2="97.54"
          y2="252.34"
        />
        <line
          fill="none"
          strokeLinecap="round"
          strokeLinejoin="round"
          strokeWidth="13px"
          className={isActive ? "stroke-primary" : "stroke-black"}
          x1="55.4"
          y1="394.55"
          x2="116.65"
          y2="341.72"
        />
        <line
          fill="none"
          strokeLinecap="round"
          strokeLinejoin="round"
          strokeWidth="13px"
          className={isActive ? "stroke-primary" : "stroke-black"}
          x1="107.04"
          y1="385.54"
          x2="168.07"
          y2="332.71"
        />
      </g>
    </g>
  </svg>
);

export default ExploreIcon;
