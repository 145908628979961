import { useState } from "react";

const ReactionPopup = ({ addReaction, closePopup }) => {
  const [reaction, setReaction] = useState(null);
  return (
    <div className="fixed h-full w-full top-0 left-0 bg-[#0000009c]">
      <div className="absolute top-1/2 left-1/2 -translate-x-1/2 -translate-y-1/2 shadow-[1px_3px_8px_0_rgba(0,0,0,0.5)] bg-white z-20 w-3/4 rounded-md p-5">
        <h3 className="font-bold text-lg mb-5">How was this lesson?</h3>
        {/* 5 radio button with labels */}
        <div className="flex items-center mb-3">
          <input
            type="radio"
            name="reaction"
            onChange={() => setReaction("reaction1")}
            id="reaction1"
            className="mr-2"
          />
          <label className="text-sm" htmlFor="reaction1">
            Mindblowing 😍
          </label>
        </div>
        <div className="flex items-center mb-3">
          <input
            type="radio"
            name="reaction"
            onChange={() => setReaction("reaction2")}
            id="reaction2"
            className="mr-2"
          />
          <label className="text-sm" htmlFor="reaction2">
            Learned something new ✨
          </label>
        </div>
        <div className="flex items-center mb-3">
          <input
            type="radio"
            name="reaction"
            onChange={() => setReaction("reaction3")}
            id="reaction3"
            className="mr-2"
          />
          <label className="text-sm" htmlFor="reaction3">
            Already knew this information 👍🏻
          </label>
        </div>
        <div className="flex items-center mb-3">
          <input
            type="radio"
            name="reaction"
            onChange={() => setReaction("reaction4")}
            id="reaction4"
            className="mr-2"
          />
          <label className="text-sm" htmlFor="reaction4">
            I understood some parts 😊
          </label>
        </div>
        <div className="flex items-center mb-3">
          <input
            type="radio"
            name="reaction"
            onChange={() => setReaction("reaction5")}
            id="reaction5"
            className="mr-2"
          />
          <label className="text-sm" htmlFor="reaction5">
            It was difficult to understand 😞
          </label>
        </div>
        <div className="flex justify-between items-center mt-5">
          <button
            onClick={() => addReaction(reaction)}
            className="bg-primary text-white px-4 py-2 rounded-md"
          >
            Submit
          </button>
          <button onClick={closePopup} className="text-primary">
            Skip
          </button>
        </div>
      </div>
    </div>
  );
};

export default ReactionPopup;
