import { getFormattedDate } from "config/helpers";
import Avatar from "../Avatar";

function UserCards({ name, message, color, isActive, timestamp, avatar }) {
  return (
    <div
      className={`flex p-4 mb-4 px-8 py-4 w-11/12 mx-auto ${
        isActive ? "bg-blue-100 border border-black-600 rounded-lg" : ""
      }`}
    >
      <div className="flex-shrink-0 flex items-center justify-center">
        <Avatar avatarNumber={avatar} borderColor="white" />
      </div>
      <div className="ml-4 w-full">
        <div className="flex sm:flex-row justify-between items-center mb-2">
          <h2
            className="text-xs sm:text-base font-bold sm:mb-0 text-center"
            style={{ color }}
          >
            {name?.split(" ")[0]}
          </h2>
          <p className="text-gray text-xs sm:text-[12px] font-light text-center ">
            {getFormattedDate(timestamp.toDate())}
          </p>
        </div>

        <p className="text-xs sm:text-base font-medium sm:text-left text-black text-justify">
          {message}
        </p>
      </div>
    </div>
  );
}

export default UserCards;
