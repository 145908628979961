import checks from "assets/images/backgrounds/checks.png";
import backArrowWhite from "assets/images/icons/back-arrow-white.png";
import insightsIcon from "assets/images/icons/insights.png";
import searchIcon from "assets/images/icons/search.png";
import Chart from "components/Chart";
import Donation from "components/Pages/Donations/Donation";
import UserLoader from "components/UserLoader";
import { DonationsContext } from "context/Data/Donations";
import { motion, useAnimate } from "framer-motion";
import useDocumentTitle from "hooks/useDocumentTitle";
import useLayoutConfig from "hooks/useLayoutConfig";
import { useContext, useEffect, useRef, useState } from "react";
import { useNavigate } from "react-router-dom";

const useAnimateExpand = (isSearchOpen) => {
  const [scope, animate] = useAnimate();

  useEffect(() => {
    animate(
      ".animate-expand",
      {
        width: isSearchOpen ? "100%" : 0,
        borderWidth: isSearchOpen ? 1 : 0,
        padding: isSearchOpen ? "0px 32px" : 0,
        marginLeft: isSearchOpen ? "-28px" : 0, // ml-7
      },
      { duration: 0.3, ease: "linear" },
    );
  }, [isSearchOpen]);

  return scope;
};

const Donations = () => {
  useLayoutConfig("red", true);
  const navigate = useNavigate();

  const { donations, isLoading } = useContext(DonationsContext);

  const [expanded, setExpanded] = useState(false);
  const [search, setSearch] = useState("");
  const [isSearchOpen, setIsSearchOpen] = useState(false);
  useDocumentTitle("Muskurahat Foundation | Donations");

  const inputRef = useRef();

  const toggleSearch = () => {
    setIsSearchOpen(!isSearchOpen);
    inputRef.current.focus();
  };

  const toggleExpand = () => {
    setExpanded(!expanded);
  };

  const handleBackClick = () => {
    navigate(-1);
  };

  const overlayStyle = {
    backgroundPosition: "center center",
    borderRadius: "50px 50px 35px 35px",
    backgroundColor: "#fff",
    backgroundImage: `url(${checks})`,
    paddingBottom: 10,
    marginTop: 30,
    boxShadow: "0px -4px 4px 0px rgba(0, 0, 0, 0.25)",
    padding: "40px 20px",
    minHeight: "50vh",
  };

  const scope = useAnimateExpand(isSearchOpen);

  return isLoading ? (
    <UserLoader />
  ) : (
    <>
      <div className="flex justify-center items-center mt-10 relative mb-14">
        <img
          src={backArrowWhite}
          alt="back"
          onClick={handleBackClick}
          className="absolute left-5 top-2 w-5"
        />
        <h1 className="font-bold text-white text-xl text-center flex-grow">
          DONATIONS
        </h1>

        <motion.div
          style={{
            width: 50,
            height: 50,
          }}
          animate={{ y: [0, -6, 0, -6, 0] }}
          transition={{
            ease: "easeIn",
            // repeatDelay: 1,
            duration: 1,
            repeat: Infinity,
          }}
        >
          <img
            src={insightsIcon}
            alt="insights"
            onClick={toggleExpand}
            className="absolute right-5 -top-2 w-10"
          />
        </motion.div>
      </div>
      <Chart
        expanded={expanded}
        setExpanded={setExpanded}
        toggleExpand={toggleExpand}
      />

      <div style={overlayStyle}>
        <div className="text-secondary font-bold text-xl text-center mb-5">
          ALL DONATIONS
        </div>

        <div className="flex justify-between mb-3">
          <span className="text-xs"></span>
          <div className="flex justify-end" ref={scope}>
            <button className="flex z-10 mt-[2px]" onClick={toggleSearch}>
              <img className="w-6" src={searchIcon} />
            </button>
            <input
              ref={inputRef}
              value={search}
              onChange={(e) => setSearch(e.target.value)}
              className="animate-expand w-0 text-xs border-[0px] border-black border-solid rounded-xl h-7 flex justify-center items-center"
            />
          </div>
        </div>

        {donations.length === 0 ? (
          <div className="text-center">Nothing here</div>
        ) : (
          <>
            {donations
              .filter((donation) =>
                donation.name.toLowerCase().includes(search.toLowerCase()),
              )
              .map((donation, i) => {
                return <Donation donation={donation} key={i} i={i} />;
              })}
          </>
        )}
      </div>
    </>
  );
};

export default Donations;
