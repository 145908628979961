import React from "react";
import Avatar from "../Avatar";
import greenArrow from "assets/images/pages/insights/green-arrow.png";
import redArrow from "assets/images/pages/insights/red-arrow.png";

function TableCard({ index, name, rank, isWhite, growth }) {
  const displayName = name.split(" ")[0];
  const cardColorClass = isWhite
    ? "bg-white text-black"
    : "bg-[#F3F0FB] text-black";

  const formattedUsername = (username) => {
    // Allow only 10 characters and add ... if more than 10
    if (username.length > 10) {
      return username.substring(0, 10) + "...";
    }
    return username;
  };

  const growthMap = {
    up: greenArrow,
    down: redArrow,
    neutral: null,
  };

  const selectRandomAvatar = () => {
    // Select a random avatar from 1 to 6
    return String(Math.floor(Math.random() * 6) + 1);
  };

  return (
    <div
      className={`rounded-lg shadow-md ${cardColorClass} flex items-center py-1 w-[345px] justify-between`}
    >
      {growth !== "neutral" ? (
        <img
          src={growthMap[growth]}
          alt={`${displayName}'s avatar`}
          className="w-4 h-4 mx-4 w-[10px]]"
        />
      ) : (
        <div className="w-4 h-4 mx-4 w-[10px]]"></div>
      )}
      <p className="text-md w-[10px]">{index + 1}</p>
      <div className="w-[50px] flex justify-center">
        <Avatar avatarNumber={selectRandomAvatar()} borderColor="primary" />
      </div>

      <div className="flex w-[140px]">
        <p className="text-md">{formattedUsername(displayName)}</p>
      </div>
      <div className="flex w-[60px]">
        <p className="text-sm">{rank}</p>
      </div>
    </div>
  );
}

export default TableCard;
