import { AuthContext } from "context/Auth";
import { collection, getDocs, query, where } from "firebase/firestore";
import { useContext } from "react";
import { db } from "./firebase";
import dayjs from "dayjs";
import * as calendar from "dayjs/plugin/calendar";
import * as advancedFormat from "dayjs/plugin/advancedFormat";
import * as relativeTime from "dayjs/plugin/relativeTime";

export const getFirstFourAlphabets = (inputString) => {
  let result = "";
  let count = 0;

  for (let i = 0; i < inputString.length; i++) {
    let char = inputString.charAt(i);
    if (/[A-Za-z]/.test(char)) {
      result += char;
      count++;
      if (count === 4) {
        break;
      }
    }
  }

  return result;
};

export const isValidReferenceCode = async (ref) =>
  isValidRegex(ref) && !(await isDuplicate(ref));

const isDuplicate = async (ref) => {
  const q = query(collection(db, "users"), where("referenceCode", "==", ref));
  const querySnapshot = await getDocs(q);
  return !querySnapshot.empty; // false if duplicate
};

// regex for 4 alphabets followed by 4 numbers
const isValidRegex = (ref) => /^[a-z0-9]{4}[0-9]{4}$/g.test(ref);

export const getFormattedDate = (date) => {
  let formattedDate = "";
  // if date is 10 hours ago or less
  if (dayjs().subtract(10, "hours").isBefore(date)) {
    dayjs.extend(relativeTime);
    formattedDate = dayjs(date).fromNow();
  } else if (dayjs().subtract(2, "days").isBefore(date)) {
    // if date is 2 days ago or less
    dayjs.extend(calendar);
    formattedDate = dayjs(date).calendar();
  } else {
    // if date is more than 2 days ago
    formattedDate = dayjs(date).format("DD/MM/YYYY h:mm A");
  }
  return formattedDate;
};

export const getCasuallyFormattedDate = (date) => {
  let formattedDate = "";
  // date.toDate();
  dayjs.extend(advancedFormat);
  formattedDate = dayjs(date.toDate()).format("Do MMMM, YYYY");
  return formattedDate;
};

export const getInternshipDaysCompleted = () => {
  const { profile } = useContext(AuthContext);
  // day js get number of days difference between two dates
  const internshipDay = dayjs().diff(profile?.createdAt.toDate(), "days");
  return internshipDay;
};

export const getInternshipWeeksCompleted = () => {
  const { profile } = useContext(AuthContext);
  // day js get number of days difference between two dates
  const internshipWeek = dayjs().diff(profile?.createdAt.toDate(), "weeks");
  return internshipWeek;
};

export const getRandomNumbers = (min, max, count) => {
  const result = [];
  for (let i = 0; i < count; i++) {
    const randomNumber = Math.floor(Math.random() * (max - min + 1)) + min;
    result.push(randomNumber);
  }
  return result;
};

export const convertTransactionsToGraphData = (transactions, createdAt) => {
  const graphData = [];
  const totalDays = dayjs().diff(createdAt.toDate(), "days");
  for (let i = 0; i < totalDays; i++) {
    graphData.push(0);
  }
  transactions.forEach((transaction) => {
    const { timestamp, amount } = transaction;
    const day = dayjs(timestamp.toDate()).diff(createdAt.toDate(), "days");
    graphData[day] = graphData[day] ? graphData[day] + amount : amount;
  });

  // above code gives us array for each day's total amount
  // ...
  // below code will give us compiled array of total
  graphData.forEach((data, index) => {
    if (index !== 0) {
      graphData[index] = graphData[index] + graphData[index - 1];
    }
  });

  return graphData;
};

export const convertTransactionsToGraphData2 = (transactions, createdAt) => {
  const graphData = [];
  const totalDays = dayjs().diff(createdAt.toDate(), "days");
  for (let i = 0; i < totalDays; i++) {
    graphData.push({ sum: 0, count: 0 });
  }

  transactions.forEach((transaction) => {
    const { timestamp, amount } = transaction;
    const day = dayjs(timestamp.toDate()).diff(createdAt.toDate(), "days");
    graphData[day] = graphData[day]
      ? { sum: graphData[day].sum + amount, count: graphData[day].count + 1 }
      : { sum: amount, count: 1 };
  });

  // above code gives us array for each day's total amount
  // ...
  // below code will give us compiled array of total
  graphData.forEach((data, index) => {
    if (index !== 0) {
      graphData[index].count =
        graphData[index].count + graphData[index - 1].count;
      graphData[index].sum = graphData[index].sum + graphData[index - 1].sum;
    }
  });

  const newGraphData = graphData.map((data) => {
    return data.count === 0 ? 0 : data.sum / data.count;
  });
  return newGraphData.map((d) => Math.ceil(d));
};

export const formatAmount = (amount) => {
  return amount.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",");
};

export const getRandomColor = () => {
  const letters = "0123456789ABCDEF";
  let color = "#";
  for (let i = 0; i < 6; i++) {
    color += letters[Math.floor(Math.random() * 16)];
  }
  return color;
};
