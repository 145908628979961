import checks from "assets/images/backgrounds/checks.png";
import CopyReferenceCode from "components/CopyReferenceCode";
import DaysLeft from "components/DaysLeft";
import Button from "components/Pages/Explore/Button";
import TrainingModule from "components/Pages/Explore/TrainingModule";
import Profile from "components/Profile";
import TopMenu from "components/TopMenu";
import useDocumentTitle from "hooks/useDocumentTitle";
import useLayoutConfig from "hooks/useLayoutConfig";

const overlayStyle = {
  backgroundPosition: "center center",
  borderRadius: "50px 50px 35px 35px",
  backgroundColor: "#fff",
  backgroundImage: `url(${checks})`,
};

const Explore = () => {
  useLayoutConfig("purple", true);
  useDocumentTitle("Muskurahat Foundation | Explore");

  return (
    <div>
      <div className="flex m-4">
        <div className="w-1/2">
          <DaysLeft />
        </div>
        <div className="w-1/2">
          <div className="flex justify-end gap-4">
            <CopyReferenceCode />
            <Profile />
          </div>
        </div>
      </div>

      <TopMenu />
      <div style={overlayStyle}>
        <div className="text-center py-8">
          <h1 className="text-secondary font-bold text-2xl">TRAINING</h1>
          <h1 className="text-primary font-bold text-2xl">MODULES</h1>
          <div className="px-4">
            <TrainingModule
              title="MODULE 1"
              subtitle="How to deal with rejections"
              id="module-1"
            />
            <TrainingModule
              title="MODULE 2"
              subtitle="How to follow up strategically"
              id="module-2"
            />
            <Button />
          </div>
        </div>
      </div>

      <div style={overlayStyle} className="mt-10">
        <div className="text-center py-8">
          <h1 className="text-primary font-bold text-2xl">PODCASTS</h1>

          <div className="px-4">
            <TrainingModule
              title="PODCAST 1"
              subtitle="An Exclusive For Crowdfunding Interns"
              id="podcast-1"
            />
            <TrainingModule
              title="PODCAST 2"
              subtitle="10 Years Of Muskurahat Foundation"
              id="podcast-2"
            />
          </div>
        </div>
      </div>
    </div>
  );
};

export default Explore;
