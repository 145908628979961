import Bulb from "assets/images/icons/bulb.png";
import UserLoader from "components/UserLoader";
import { db } from "config/firebase";
import { getInternshipDaysCompleted } from "config/helpers";
import { AuthContext } from "context/Auth";
import {
  collection,
  getCountFromServer,
  query,
  where,
} from "firebase/firestore";
import { useAnimate, useInView } from "framer-motion";
import { useContext, useEffect, useRef, useState } from "react";
import Chart from "react-apexcharts";
import VerticalLockedCard from "../VerticalLockedCard";

const useAnimateOpacity = (isHintVisible) => {
  const [scope, animate] = useAnimate();

  useEffect(() => {
    animate(
      ".animate-opacity",
      { opacity: isHintVisible ? 1 : 0 },
      { duration: 0.2 },
    );
  }, [isHintVisible]);
  return scope;
};

const NumberOfDonors = ({ setTotalDonors, isLocked }) => {
  const [isLoading, setIsLoading] = useState(true);
  const [graphData, setGraphData] = useState([]);
  const { profile } = useContext(AuthContext);

  const [isHintVisible, setIsHintVisibile] = useState(false);
  const scope = useAnimateOpacity(isHintVisible);
  const numberOfDays = getInternshipDaysCompleted();

  useEffect(() => {
    const setup = async () => {
      setIsLoading(true);
      const collectionRef = collection(db, "transactions");
      const q = query(
        collectionRef,
        where("referenceCode", "==", profile?.referenceCode),
      );
      const count = (await getCountFromServer(q)).data().count;
      const defaultNumberOfDonorsData = [
        0.1, 0.7, 3.2, 4.1, 5.3, 6.8, 7.4, 8.5, 8.8, 8.9, 9.1, 9.2, 9.3, 9.7,
        10.2, 10.2, 10.6, 10.9, 11.3, 12.0, 12.4, 12.5, 12.6, 12.7, 12.9, 13.4,
        13.8, 14.1, 14.1, 14.2, 14.2, 14.2, 14.2, 14.2, 14.2, 14.2, 14.2, 14.2,
        14.2, 14.2, 14.2, 14.2, 14.2, 14.2, 14.2, 14.2, 14.2, 14.2, 14.2, 14.2,
        14.2, 14.2, 14.2, 14.2, 14.2, 14.2, 14.2, 14.2, 14.2, 14.2, 14.2, 14.2,
        14.2, 14.2, 14.2, 14.2, 14.2, 14.2, 14.2, 14.2, 14.2, 14.2, 14.2, 14.2,
        14.2, 14.2, 14.2, 14.2, 14.2, 14.2, 14.2, 14.2, 14.2, 14.2, 14.2, 14.2,
        14.2, 14.2, 14.2, 14.2, 14.2, 14.2, 14.2, 14.2, 14.2, 14.2, 14.2, 14.2,
        14.2, 14.2, 14.2, 14.2, 14.2, 14.2, 14.2, 14.2, 14.2, 14.2, 14.2, 14.2,
        14.2, 14.2, 14.2, 14.2, 14.2, 14.2, 14.2, 14.2, 14.2, 14.2, 14.2, 14.2,
        14.2, 14.2, 14.2, 14.2, 14.2, 14.2, 14.2, 14.2, 14.2, 14.2, 14.2, 14.2,
        14.2, 14.2, 14.2, 14.2, 14.2, 14.2, 14.2, 14.2, 14.2, 14.2, 14.2, 14.2,
        14.2, 14.2, 14.2, 14.2, 14.2, 14.2, 14.2, 14.2, 14.2, 14.2, 14.2, 14.2,
        14.2, 14.2, 14.2, 14.2, 14.2, 14.2, 14.2, 14.2, 14.2, 14.2, 14.2, 14.2,
        14.2, 14.2, 14.2, 14.2, 14.2, 14.2, 14.2, 14.2, 14.2, 14.2, 14.2, 14.2,
        14.2,
      ];
      setGraphData([count, defaultNumberOfDonorsData[numberOfDays]]);
      setTotalDonors(count);
      setIsLoading(false);
    };

    profile && setup();
  }, [profile]);

  const toggleHint = () => {
    setIsHintVisibile(!isHintVisible);
  };

  const ref = useRef(null);
  const isInView = useInView(ref, { margin: "0px 0px -80px 0px", once: true });

  return (
    <div
      ref={scope}
      className={`p-5 bg-gray-200 bg-[url('assets/images/pages/insights/square.png')] bg-cover shadow-[1px_3px_8px_0_rgba(0,0,0,0.25)] rounded-[20px] h-full`}
    >
      <div className="flex items-center justify-between text-secondary text-lg font-bold">
        <div className="text-left" ref={ref}>
          <h3 className="text-xs">
            NUMBER OF <span className="text-primary">DONORS</span>
          </h3>{" "}
        </div>
        <div className="relative">
          <img className="w-[47px] mr-1" src={Bulb} onClick={toggleHint} />
          <div className="animate-opacity absolute text-black font-normal text-[8px] w-80 border-tertiary border-solid border-[1px] leading-4 -top-[95px] -left-20 bg-white shadow-[1px_3px_8px_0_rgba(0,0,0,0.25)] rounded-xl p-3 after:absolute after:left-24 after:top-full after:-mt-[6px] after:w-3 after:h-3 after:border-[1px] after:border-solid after:border-tertiary after:rotate-45 after:bg-white after:border-t-0 after:border-l-0">
            Total number of donors compared to{" "}
            <span className="font-medium">Average Number of Donors</span>{" "}
            cracked by interns from your batch
            <br />
            <br />
            <span className="text-secondary">Average Number Of Donors =</span>
            <div className="relative w-[100px] inline-block">
              <div className="absolute w-full h-full top-[-15px] left-[5px]">
                <span className="text-primary border-b-[0.8px] border-solid border-[#000000] pb-[2px]">
                  Total Number Of Donors
                </span>
                <br />
                <span className="text-primary">Total Number Of Interns</span>
              </div>
            </div>
          </div>
        </div>
      </div>
      {isLoading ? (
        <UserLoader />
      ) : (
        <div className="relative">
          <div className={isLocked ? "blur-sm" : ""}>
            {isInView && (
              <Chart
                type="bar"
                width="150px"
                height={200}
                series={[
                  {
                    name: "No. of donors",
                    data: graphData,
                  },
                ]}
                options={{
                  colors: ["#fff"],
                  fill: {
                    type: "gradient",
                    gradient: {
                      type: "vertical",
                      gradientToColors: ["#F6A71C"],
                      colorStops: [
                        [
                          {
                            offset: 0,
                            color: "#F6A71C",
                            opacity: 1,
                          },
                          {
                            offset: 70,
                            color: "#F6A71C",
                            opacity: 1,
                          },
                          {
                            offset: 100,
                            color: "#fae8c8",
                            opacity: 1,
                          },
                        ],
                      ],
                    },
                  },

                  chart: {
                    width: "150px",
                    type: "bar",
                    parentHeightOffset: 0,
                    offsetX: -10,
                    offsetY: 10,
                    toolbar: {
                      show: false,
                    },
                  },
                  plotOptions: {
                    bar: {
                      dataLabels: {
                        position: "top",
                      },
                      columnWidth: "80%",
                    },
                  },

                  dataLabels: {
                    enabled: true,
                    style: {
                      fontSize: "12px",
                      colors: ["#fff"],
                    },
                  },
                  yaxis: {
                    show: false,
                  },
                  legend: {
                    show: false,
                  },
                  xaxis: {
                    show: false,
                    axisBorder: {
                      show: false,
                    },
                    axisTicks: {
                      show: false,
                    },
                    crosshairs: {
                      show: false,
                    },
                    tooltip: {
                      enabled: false,
                    },
                    offsetX: 0,
                    offsetY: 0,
                    position: "top",
                    labels: {
                      show: false,
                    },
                    categories: [
                      "Number of donors",
                      "Average number of donors",
                    ],
                  },
                }}
              />
            )}
            <div className="border-t-[1px] border-black flex justify-between pt-3">
              <div className="text-[10px] text-center w-1/2">
                <div>Number of donors</div>
              </div>
              <div className="text-[10px] text-center w-1/2">
                <div>Average number of donors</div>
              </div>
            </div>
          </div>
          {isLocked && (
            <VerticalLockedCard title1="NUMBER OF" title2="DONORS" />
          )}
        </div>
      )}
    </div>
  );
};

export default NumberOfDonors;
