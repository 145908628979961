const InsightsIcon = ({ isActive }) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    viewBox="0 0 442.92 439.55"
    height="24px"
    width="24px"
    className="m-auto"
  >
    <g id="Layer_2" data-name="Layer 2">
      <g id="Layer_1-2" data-name="Layer 1">
        <path
          fill="none"
          strokeWidth="19.69px"
          className={isActive ? "stroke-primary" : "stroke-black"}
          d="M17.23,426.57s-16.61-111.94,0-133.8,37.49-23.61,58.1-23.61,47.5,2,54.71,7.87c14,11.37,4.37,149.54,4.37,149.54Z"
        />
        <path
          fill="none"
          strokeWidth="19.69px"
          className={isActive ? "stroke-primary" : "stroke-black"}
          d="M134.41,426.57s-5.24-181.9,9.62-192.39,38.48-14,65.59-13.12,83.07-8.74,90.94,10.5.88,188,3.5,195S134.41,426.57,134.41,426.57Z"
        />
        <path
          fill="none"
          strokeWidth="19.69px"
          className={isActive ? "stroke-primary" : "stroke-black"}
          d="M304.06,426.57s.38-89.2,0-102c-.36-12.4,11.37-21.29,58.59-20.41s62.09,4.37,66.46,27.11,6.13,98,0,98.43S304.06,426.57,304.06,426.57Z"
        />
        <path
          fill="none"
          strokeWidth="17.13px"
          strokeLinecap="round"
          strokeLinejoin="round"
          className={isActive ? "stroke-primary" : "stroke-black"}
          d="M167.64,171.22s50.83-28.86,55.58-28,49.07,35.27,54.61,28.86c4.88-5.65-14-61.21-14-61.21L304.44,68s-53.72,1.75-57.22-7.87S224.84,8.56,219,8.56,186,68,186,68s-45.89-5.25-51.37,1.75S175.51,103,176.39,106.5,152.78,166,167.64,171.22Z"
        />
      </g>
    </g>
  </svg>
);

export default InsightsIcon;
