import lock from "assets/images/pages/insights/lock.png";

const VerticalLockedCard = ({ title1, title2 }) => {
  return (
    <div className="absolute w-full h-full bg-transparent top-0">
      <div className="flex justify-center items-center h-full">
        <div className="flex flex-col items-center bg-[#F7F7F7] p-5 rounded-2xl">
          <div className="text-center">
            <h3 className="text-xl text-tertiary font-bold">{title1}</h3>
            <h3 className="text-xl text-secondary font-bold">{title2}</h3>
          </div>
          <div>
            <img
              src={lock}
              alt="Top Image"
              className="max-w-[70px] max-h-full w-full"
            />
            <p className="text-[10px] text-black text-center">(LOCKED)</p>
          </div>
        </div>
      </div>
    </div>
  );
};
export default VerticalLockedCard;
