import checks from "assets/images/backgrounds/checks.png";
import backArrow from "assets/images/icons/back-arrow.png";
import drive from "assets/images/pages/hacks/drive.png";
import rejection from "assets/images/pages/hacks/rejection.png";
import stagnation from "assets/images/pages/hacks/stagnation.png";
import yes from "assets/images/pages/hacks/yes.png";
import useLayoutConfig from "hooks/useLayoutConfig";
import { useNavigate } from "react-router-dom";

const overlayStyle = {
  backgroundPosition: "center center",
  borderRadius: "50px 50px 35px 35px",
  backgroundColor: "#fff",
  backgroundImage: `url(${checks})`,
  paddingBottom: 10,
  marginTop: 30,
  boxShadow: "0px -4px 4px 0px rgba(0, 0, 0, 0.25)",
  padding: "50px 0px",
  zIndex: 9,
  position: "relative",
};

const HackSix = () => {
  const navigate = useNavigate();
  useLayoutConfig("white", true);
  const handleBackClick = () => {
    navigate(-1);
  };

  return (
    <>
      <div className="flex justify-center items-center mt-10 relative">
        <img
          src={backArrow}
          alt=""
          onClick={handleBackClick}
          className="absolute left-5 top-1/2 -translate-y-1/2 w-5"
        />
        <h1
          className={`font-bold text-secondary text-xl text-center flex-grow`}
        >
          CROWDFUNDING
          <br />
          <span className="text-tertiary">HACKS FOR YOU</span>
        </h1>
      </div>
      <div className="text-center p-5 mt-10 mx-5 bg-tertiary rounded-[10px] shadow-[0px_2px_4px_rgba(0,0,0,0.25)]">
        <h2 className="text-xs text-white font-bold">
          <span className="text-secondary">STAYING MOTIVATED </span>
          IN THE FACE OF <br />
          REJECTION AND CHALLENGES
        </h2>
        <p className="text-[10px] mt-5 text-white">
          Working as an intern in our fundraising department is a journey filled
          with both rewarding moments and challenges. At times, you may face
          rejection or feel stuck, but it&apos;s essential to maintain your
          motivation and continue your growth. Here are strategies to help you
          stay motivated when facing hurdles.
        </p>
      </div>
      <div style={overlayStyle}>
        <div className="relative">
          <div className="w-3/4 mt-5 pb-2 bg-cover bg-[url('assets/images/pages/hacks/note1.png')]">
            <h2 className="font-bold pt-5 pb-7 pl-5 text-primary text-lg text-left">
              EMBRACE REJECTION AS
              <span className="relative text-secondary before:content-[' '] before:bg-contain before:block before:absolute before:bg-[url('assets/images/icons/underline.webp')] before:w-full before:h-[10px] before:bg-no-repeat before:-bottom-2 inline-block">
                A GROWTH OPPORTUNITY
              </span>
            </h2>
          </div>
          <img src={rejection} className="w-24 absolute right-0 top-0" />
          <div className="mx-5 bg-white rounded-[10px] shadow-[0px_2px_4px_rgba(0,0,0,0.25)] p-5">
            <ul className="ml-3">
              <li className="text-xs font-bold text-primary before:content-['\2022'] relative before:top-0 before:-left-2 before:-mt-3 before:absolute before:text-3xl before:text-tertiary before:font-bold before:inline-block before:w-5 before:-ml-4">
                Strategy -
              </li>
              <li className="text-xs mt-2">
                Accept rejection as part of the process and a catalyst for your
                personal growth.
              </li>
              <li className="text-xs mt-4 font-bold text-primary before:content-['\2022'] relative before:top-0 before:-left-2 before:-mt-3 before:absolute before:text-3xl before:text-tertiary before:font-bold before:inline-block before:w-5 before:-ml-4">
                Why -
              </li>
              <li className="text-xs mt-2">
                The more rejections you encounter, the faster you&apos;ll
                evolve, expand your comfort zone, and develop crucial skills.
              </li>
              <li className="text-xs mt-4 font-bold text-primary before:content-['\2022'] relative before:top-0 before:-left-2 before:-mt-3 before:absolute before:text-3xl before:text-tertiary before:font-bold before:inline-block before:w-5 before:-ml-4">
                Tip -
              </li>
              <li className="text-xs mt-2">
                Use rejections as stepping stones to success and keep pushing
                forward.
              </li>
            </ul>
          </div>
        </div>

        <div className="relative">
          <div className="flex justify-between mt-16">
            <div></div>
            <div className="w-2/3 pb-2 bg-cover bg-[url('assets/images/pages/hacks/note2.png')]">
              <h2 className="font-bold pt-5 pb-7 pr-5 text-tertiary text-lg text-right">
                FOCUS ON THE <br />
                <span className="relative text-secondary before:content-[' '] before:bg-contain before:block before:absolute before:bg-[url('assets/images/icons/underline.webp')] before:w-full before:h-[10px] before:bg-no-repeat before:-bottom-2 inline-block">
                  POWER OF &apos;YES&apos;
                </span>
              </h2>
            </div>
          </div>
          <img src={yes} className="w-20 absolute left-5 -top-2" />

          <div className="mx-5 mt-3 bg-white rounded-[10px] shadow-[0px_2px_4px_rgba(0,0,0,0.25)] p-5">
            <ul className="ml-3">
              <li className="text-xs font-bold text-primary before:content-['\2022'] relative before:top-0 before:-left-2 before:-mt-3 before:absolute before:text-3xl before:text-tertiary before:font-bold before:inline-block before:w-5 before:-ml-4">
                Strategy -
              </li>
              <li className="text-xs mt-2">
                Remember that you may receive many &apos;no&apos; responses, but
                all it takes is that one &apos;yes.&apos;For newcomers,
                follow-ups might feel intrusive.
              </li>
              <li className="text-xs mt-4 font-bold text-primary before:content-['\2022'] relative before:top-0 before:-left-2 before:-mt-3 before:absolute before:text-3xl before:text-tertiary before:font-bold before:inline-block before:w-5 before:-ml-4">
                Why -
              </li>
              <li className="text-xs mt-2">
                The one &apos;yes&apos; can make all the difference and change
                the trajectory of your efforts.
              </li>
              <li className="text-xs mt-4 font-bold text-primary before:content-['\2022'] relative before:top-0 before:-left-2 before:-mt-3 before:absolute before:text-3xl before:text-tertiary before:font-bold before:inline-block before:w-5 before:-ml-4">
                Tip -
              </li>
              <li className="text-xs mt-2">
                Keep your focus on the positive outcome you&apos;re working
                towards.
              </li>
            </ul>
          </div>
        </div>

        <div className="relative">
          <div className="flex mt-16">
            <div className="w-2/3 pb-2 bg-cover bg-[url('assets/images/pages/hacks/note3.png')]">
              <h2 className="font-bold pt-5 pb-7 pl-5 text-primary text-lg text-left">
                TURN STAGNATION
                <br />
                <span className="relative text-secondary before:content-[' '] before:bg-contain before:block before:absolute before:bg-[url('assets/images/icons/underline.webp')] before:w-full before:h-[10px] before:bg-no-repeat before:-bottom-2 inline-block">
                  INTO OPPORTUNITY
                </span>
              </h2>
            </div>
          </div>
          <img src={stagnation} className="w-20 absolute right-5 -top-2" />

          <div className="mx-5 mt-3 bg-white rounded-[10px] shadow-[0px_2px_4px_rgba(0,0,0,0.25)] p-5">
            <ul className="ml-3">
              <li className="text-xs font-bold text-primary before:content-['\2022'] relative before:top-0 before:-left-2 before:-mt-3 before:absolute before:text-3xl before:text-tertiary before:font-bold before:inline-block before:w-5 before:-ml-4">
                Strategy -
              </li>
              <li className="text-xs mt-2">
                When you feel stuck, see it as an opportunity to challenge
                yourself and move to the next level.
              </li>
              <li className="text-xs mt-4 font-bold text-primary before:content-['\2022'] relative before:top-0 before:-left-2 before:-mt-3 before:absolute before:text-3xl before:text-tertiary before:font-bold before:inline-block before:w-5 before:-ml-4">
                Why -
              </li>
              <li className="text-xs mt-2">
                Overcoming these moments of stagnation is how you develop
                essential people skills and resilience.
              </li>
              <li className="text-xs mt-4 font-bold text-primary before:content-['\2022'] relative before:top-0 before:-left-2 before:-mt-3 before:absolute before:text-3xl before:text-tertiary before:font-bold before:inline-block before:w-5 before:-ml-4">
                Tip -
              </li>
              <li className="text-xs mt-2">
                Seek guidance and reflect on the lessons learned during these
                challenging times.
              </li>
            </ul>
          </div>
        </div>

        <div className="relative">
          <div className="flex justify-between mt-16">
            <div></div>
            <div className="w-2/4 pb-2 bg-cover bg-[url('assets/images/pages/hacks/note2.png')]">
              <h2 className="font-bold pt-5 pb-7 pr-5 text-tertiary text-lg text-right">
                Radiate Optimism
                <br />
                <span className="relative text-secondary before:content-[' '] before:bg-contain before:block before:absolute before:bg-[url('assets/images/icons/underline.webp')] before:w-full before:h-[10px] before:bg-no-repeat before:-bottom-2 inline-block">
                  and Drive
                </span>
              </h2>
            </div>
          </div>
          <img src={drive} className="w-28 absolute left-5 -top-2" />

          <div className="mx-5 mt-3 bg-white rounded-[10px] shadow-[0px_2px_4px_rgba(0,0,0,0.25)] p-5">
            <ul className="ml-3">
              <li className="text-xs font-bold text-primary before:content-['\2022'] relative before:top-0 before:-left-2 before:-mt-3 before:absolute before:text-3xl before:text-tertiary before:font-bold before:inline-block before:w-5 before:-ml-4">
                Strategy -
              </li>
              <li className="text-xs mt-2">
                Recognize that your optimism and determination are contagious
                and can inspire people to say &apos;YES&apos; to your cause.
              </li>
              <li className="text-xs mt-4 font-bold text-primary before:content-['\2022'] relative before:top-0 before:-left-2 before:-mt-3 before:absolute before:text-3xl before:text-tertiary before:font-bold before:inline-block before:w-5 before:-ml-4">
                Why -
              </li>
              <li className="text-xs mt-2">
                Your positive attitude and unwavering drive can be a powerful
                force that attracts support.
              </li>
              <li className="text-xs mt-4 font-bold text-primary before:content-['\2022'] relative before:top-0 before:-left-2 before:-mt-3 before:absolute before:text-3xl before:text-tertiary before:font-bold before:inline-block before:w-5 before:-ml-4">
                Tip -
              </li>
              <li className="text-xs mt-2">
                Always maintain a can-do attitude and let your enthusiasm shine
                through.
              </li>
            </ul>
          </div>
        </div>
      </div>

      <div className="bg-secondary pt-20 -mt-8 pb-6 -mb-8 text-center">
        <div className="bg-white mx-5 rounded-[10px] shadow-[0px_2px_4px_rgba(0,0,0,0.25)] p-5">
          <h3 className="text-primary font-bold">CONCLUSION</h3>
          <p className="text-[10px] mt-4">
            As an intern, you&apos;re in a unique position to learn, grow, and
            make a significant impact. By embracing rejection as a growth
            opportunity, focusing on the power of &apos;yes,&apos; turning
            stagnation into a stepping stone, and radiating optimism and drive,
            you&apos;ll not only overcome challenges but also inspire others to
            join your cause. Remember, perseverance and an unwavering belief in
            your mission are the keys to success.
          </p>
        </div>
        <div className="mx-5 rounded-[10px] py-5">
          <div className="border-white border-t-[1px] max-w-[75%] m-auto" />
          <div className="flex justify-between mt-5">
            <button
              className="bg-tertiary text-white py-2 px-3 text-xs rounded-lg disabled:bg-[#d9d9d9] disabled:cursor-not-allowed"
              onClick={() => {
                navigate("/user/hacks/hack-5");
              }}
            >
              BACK
            </button>

            <button
              className="bg-primary text-white py-2 px-3 text-xs rounded-lg disabled:bg-[#d9d9d9] disabled:cursor-not-allowed"
              disabled
            >
              NEXT HACK
            </button>
          </div>
        </div>
      </div>
    </>
  );
};

export default HackSix;
