/* eslint-disable no-unused-vars */
import sidebar from "assets/images/Dashboard/c1.png";
import wrapper from "assets/images/Dashboard/c5.png";
import send from "assets/images/Dashboard/send.png";
import checks from "assets/images/backgrounds/checks.png";
import backArrow from "assets/images/icons/back-arrow.png";
import PlayIcon from "assets/images/icons/play.svg";
import StudentWithBook from "assets/images/pages/orientation/student-with-book.png";
import ReactionPopup from "components/ReactionPopup";
import chapters from "config/chapters";
import { db } from "config/firebase";

import { AuthContext } from "context/Auth";
import { TrainingModuleDiscussionContext } from "context/Data/TrainingModuleDiscussion";
import NotificationContext from "context/Notification";
import {
  addDoc,
  collection,
  doc,
  increment,
  serverTimestamp,
  writeBatch,
} from "firebase/firestore";
import useDocumentTitle from "hooks/useDocumentTitle";
import useLayoutConfig from "hooks/useLayoutConfig";
import { useContext, useState } from "react";
import { Link, useNavigate, useParams } from "react-router-dom";
import VideoPlayer from "./VideoPlayer";

const Chapter = () => {
  const { profile, setProfile } = useContext(AuthContext);
  useLayoutConfig("white", true);
  const { setNotificationMessage } = useContext(NotificationContext);

  const [message, setMessage] = useState("");

  const [isReactionPopupVisible, setIsReactionPopupVisible] = useState(false);
  const { discussion } = useContext(TrainingModuleDiscussionContext);

  const { id } = useParams();
  const chapterIndex = id.split("-")[1] - 1;
  const chapter = chapters[chapterIndex];
  useDocumentTitle(`Muskurahat Foundation | Training Module - ${id}`);

  useLayoutConfig("red", true);

  const navigate = useNavigate();

  const handleBackClick = () => {
    navigate(-1);
  };

  const overlayStyle = {
    backgroundPosition: "center center",
    backgroundColor: "#fff",
    backgroundImage: `url(${checks})`,
  };

  const addMessage = async () => {
    if (message) {
      await addDoc(collection(db, "training-modules", id, "discussion"), {
        message,
        user: {
          referenceCode: profile.referenceCode,
          avatar: profile.avatar,
          id: profile.id,
          name: profile.name,
        },
        timestamp: serverTimestamp(),
        isApproved: false,
      });

      setNotificationMessage("Your message is sent for an approval");
      setMessage("");
    }
  };

  const addReaction = async (reaction) => {
    const batch = writeBatch(db);

    const reactionRef = doc(
      collection(db, "training-modules", id, "reactions"),
      profile.id,
    );

    batch.set(reactionRef, {
      user: {
        referenceCode: profile.referenceCode,
        avatar: profile.avatar,
        id: profile.id,
      },
      timestamp: serverTimestamp(),
      reaction: reaction,
    });

    // create json with all reactions

    const reactionMap = {
      reaction1: {
        reaction1: increment(1),
      },
      reaction2: {
        reaction2: increment(1),
      },
      reaction3: {
        reaction3: increment(1),
      },
      reaction4: {
        reaction4: increment(1),
      },
      reaction5: {
        reaction5: increment(1),
      },
    };

    const moduleRef = doc(db, "training-modules", id);

    batch.set(
      moduleRef,
      {
        reactions: reactionMap[reaction],
      },
      { merge: true },
    );

    await batch.commit();

    setIsReactionPopupVisible(false);
  };

  const updateTrainingModuleProgress = async () => {
    setProfile({
      ...profile,
      trainingModules: profile.trainingModules
        ? [...profile.trainingModules, { id, complete: true }]
        : [{ id, complete: true }],
    });
    setIsReactionPopupVisible(true);
  };

  console.log(Number(chapterIndex));
  console.log(Number(chapterIndex) === chapters.length + 1);

  return (
    <div className="bg-primary">
      {Number(chapterIndex) === chapters.length ? (
        <>
          <div
            style={overlayStyle}
            className="shadow-lg pt-1 rounded-b-[50px]  pb-[202px] mb-[100px] relative"
          >
            <div className="text-white text-center py-12">
              <img
                src={backArrow}
                alt=""
                onClick={handleBackClick}
                className="absolute left-5 top-8 -translate-y-1/2 w-5"
              />
              <div className="px-10">
                <div className="h-1 w-full bg-[#D9D9D9] rounded-xl my-10 relative">
                  <div
                    className={`absolute left-0 top-0 h-full rounded-xl bg-primary`}
                    style={{
                      width: `${Number(
                        ((chapterIndex + 1) / (chapters.length + 1)) * 100,
                      ).toFixed(2)}%`,
                    }}
                  ></div>
                </div>
              </div>
              <h2 className="text-primary text-center font-bold text-[24px]">
                You have reached the
                <br /> end of your orientation
              </h2>
              <p className="text-secondary text-xl mt-7">
                <strong>Thank you</strong> for choosing{" "}
                <strong className="text-primary">Muskurahat</strong>, choosing
                quality education for underprivileged children and taking{" "}
                <strong className="text-primary">responsibility</strong>{" "}
                <strong>for their quality education.</strong>
              </p>
              <p className="text-secondary font-bold text-xl mt-7">
                The Future Will Remember Us!
              </p>

              <Link
                to="/user/dashboard"
                className="inline-block bg-secondary text-white text-xl font-bold text-center py-5 px-3 rounded-[9px] mt-7"
              >
                Back To Dashboard ✨
              </Link>
              <div className="absolute w-full">
                <img
                  src={StudentWithBook}
                  className="mt-14 w-[300px] text-center m-auto"
                />
              </div>
            </div>
          </div>
        </>
      ) : (
        <>
          <div className="main bg-[#F1F1F1] relative rounded-b-[33px] shadow-[0px_3px_13px_6px_#00000040] pb-10">
            <div style={overlayStyle} className="shadow-lg">
              <div className="text-white text-center py-12">
                <img
                  src={backArrow}
                  alt=""
                  onClick={handleBackClick}
                  className="absolute left-5 top-8 -translate-y-1/2 w-5"
                />
                <div className="px-10">
                  <div className="h-1 w-full bg-[#D9D9D9] rounded-xl my-10 relative">
                    <div
                      className={`absolute left-0 top-0 h-full rounded-xl bg-primary`}
                      style={{
                        width: `${Number(
                          ((chapterIndex + 1) / (chapters.length + 1)) * 100,
                        ).toFixed(2)}%`,
                      }}
                    ></div>
                  </div>
                </div>
                <div className="relative flex lg:justify-between md:justify-between">
                  <img
                    src={sidebar}
                    alt="Your Image"
                    className="w-64 h-auto lg:w-[25rem] md:w-[18rem]"
                  />
                  <div className="absolute top-0 left-0 w-full h-full text-left justify-center">
                    <h2 className="font-bold  pt-3 text-secondary text-lg pl-4 lg:text-2xl lg:pt-[3.25rem] lg:pl-[2rem]">
                      {chapter.title}
                    </h2>
                    <h2 className="font-bold  text-primary text-lg pl-4 lg:text-2xl lg:pl-[2rem] before:content-[' '] before:bg-contain before:block before:absolute before:bg-[url('assets/images/icons/underline.webp')] before:w-full before:h-[10px] before:bg-no-repeat before:-bottom-2 relative inline-block">
                      {chapter.specialTitle}
                    </h2>
                    <h4 className="text-secondary text-[11px] pl-4 mt-3">
                      {chapter.specialTitle2}
                    </h4>
                  </div>
                  <div>
                    <img
                      src={chapter.primaryIcon}
                      alt=""
                      className="w-[107px] lg:w-[207px] lg:pr-[30px] md:w-[137px] md:pr-[30px]"
                    />
                  </div>
                </div>
                <div className="mx-5 p-5 bg-[#F7F7F7] rounded-md shadow-[0px_2px_4px_0px_#00000040]">
                  <div>
                    <VideoPlayer
                      video={chapter.videoId}
                      thumbnail={chapter.videoThumbnail}
                    />
                  </div>
                  {/* <div className="text-secondary font-bold text-sm text-left w-64 mx-auto pt-6 ml-11">
            <h2>{chapter.videoTitle}</h2>
          </div>
          <div className="flex justify-around mx-auto pt-8">
            <button
              disabled={
                profile &&
                profile.trainingModules?.find(
                  (trainingModule) => trainingModule.id === id,
                )?.complete
              }
              className="disabled:bg-gray-300 bg-tertiary w-32 h-11 shadow-md rounded-2xl flex items-center justify-between p-2 relative"
            >
              <div
                className="text-white text-xs font-bold leading-4 cursor-pointer"
                onClick={updateTrainingModuleProgress}
              >
                I FINISHED THIS VIDEO
              </div>
              <img src={correct} alt="" className="w-9 h-9 mr-[-30px]" />
            </button>
            <div className="bg-primary w-32 h-11 shadow-md rounded-2xl flex items-center justify-between p-2">
              <div
                className="text-white text-xs font-bold leading-4 p-5"
                onClick={() => {
                  let discussion = document.getElementById("discussion");
                  discussion.scrollIntoView({ behavior: "smooth" });
                }}
              >
                DISCUSS
              </div>
              <img src={chat} alt="" className="w-9 h-9 ml-2" />
            </div>
          </div> */}
                  <div className="mx-auto">
                    <h1 className="font-bold text-secondary text-sm text-left pt-8">
                      Description:
                    </h1>
                    <p className="font-base text-black text-xs sm:text-sm md:text-base text-justify pt-4 leading-4">
                      {chapter.description}
                    </p>
                  </div>
                </div>
              </div>
            </div>
            <div className="relative bg-[#F1F1F1]" id="discussion">
              <img src={wrapper} className="w-4/5 h-full mx-auto" alt="" />

              <h1 className="text-tertiary text-lg lg:text-2xl absolute top-1/2 left-1/2 font-bold transform -translate-x-1/2 -translate-y-2/3">
                {chapter.discussionTitle}
              </h1>
            </div>
            <div className="bg-[#F1F1F1]">
              {/* {!discussion[id].length ? (
          <h1 className="text-center mb-10">No discussion yet</h1>
        ) : (
          discussion[id].map((message, index) => (
            <UserCards
              key={index}
              referenceCode={message.user.referenceCode}
              name={message.user.name}
              avatar={message.user.avatar}
              message={message.message}
              color={message.color}
              timestamp={message.timestamp}
              isActive={false}
            />
          ))
        )} */}
            </div>
            <div className="bg-[#F1F1F1]">
              <div className="relative w-80 mx-auto">
                <input
                  value={message}
                  onChange={(e) => setMessage(e.target.value)}
                  onKeyDown={(e) => {
                    if (e.key === "Enter") {
                      addMessage();
                    }
                  }}
                  className="w-full h-12 border border-black rounded-3xl px-4 pr-10 text-xs"
                  type="text"
                  placeholder="SHARE YOUR THOUGHTS"
                />
                <button onClick={addMessage}>
                  <img
                    src={send}
                    alt="Send"
                    className="absolute top-0 right-0 h-full w-12 text-white flex items-center justify-center rounded-r-2xl cursor-pointer"
                  />
                </button>
              </div>
            </div>

            {isReactionPopupVisible && (
              <ReactionPopup
                addReaction={addReaction}
                closePopup={() => setIsReactionPopupVisible(false)}
              />
            )}
          </div>
          <div className="bg-white m-5 mt-10 mb-5 p-7 rounded-[9px] shadow-[0px_4px_4px_0px_#00000040]">
            <Link to={`/user/orientation/chapter-${chapterIndex + 2}`}>
              <div className="p-7">
                <h3 className="text-tertiary text-sm font-bold">Up Next:</h3>
                <p className="text-xs text-secondary font-medium">
                  {chapter.nextDescription}
                </p>
              </div>
              <div className="flex bg-tertiary rounded-lg">
                <div className="w-[300px] rounded-lg">
                  <div
                    style={{ backgroundImage: `url(${chapter.nextImage})` }}
                    className={`bg-cover relative pt-[56.25%] rounded-lg`}
                  >
                    <img
                      className="absolute left-1/2 top-1/2 -translate-x-1/2 -translate-y-1/2"
                      width={30}
                      src={PlayIcon}
                    />
                  </div>
                </div>
                <div className="px-2 flex">
                  <p className="text-xl self-center text-white font-bold line-clamp-2 overflow-ellipsis max-w-[255px]">
                    {chapter.nextTitle}
                  </p>
                </div>
              </div>
            </Link>
          </div>
        </>
      )}
    </div>
  );
};

export default Chapter;
