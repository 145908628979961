import { Outlet } from "react-router";
import classes from "./index.module.css";
import purple from "assets/images/backgrounds/purple.webp";
import red from "assets/images/backgrounds/red.webp";
import white from "assets/images/backgrounds/white.png";
import yellow from "assets/images/backgrounds/yellow.webp";
import Page from "components/Animation/Page";
import BottomMenu from "components/BottomMenu";
import Notification from "components/Notification";
import UserLoader from "components/UserLoader";
import { AuthContext } from "context/Auth";
import { LayoutContext } from "context/Layout";
import { useContext } from "react";

const Layout = () => {
  const { background, showBottomMenu } = useContext(LayoutContext);
  const backgroundMap = { red, yellow, purple, white };

  const { isUserLoading } = useContext(AuthContext);

  return (
    <div className="w-full h-full bg-gray-200">
      <div className="max-w-[500px] m-auto">
        <div
          className={classes.main}
          style={{ backgroundImage: `url("${backgroundMap[background]}")` }}
        >
          {isUserLoading ? (
            <UserLoader />
          ) : (
            <Page>
              <Notification />
              <Outlet />
            </Page>
          )}
          {showBottomMenu ? <BottomMenu /> : null}
        </div>
      </div>
    </div>
  );
};

export default Layout;
