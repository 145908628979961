import checks from "assets/images/backgrounds/checks.png";
import backArrow from "assets/images/icons/back-arrow.png";
import personally from "assets/images/pages/hacks/personally.png";

import authentic from "assets/images/pages/hacks/authentic.png";
import conviction from "assets/images/pages/hacks/conviction.png";
import curate from "assets/images/pages/hacks/curate.png";
import empathize from "assets/images/pages/hacks/empathize.png";
import independant from "assets/images/pages/hacks/independant.png";
import useLayoutConfig from "hooks/useLayoutConfig";
import { useNavigate } from "react-router-dom";

const overlayStyle = {
  backgroundPosition: "center center",
  borderRadius: "50px 50px 35px 35px",
  backgroundColor: "#fff",
  backgroundImage: `url(${checks})`,
  paddingBottom: 10,
  marginTop: 30,
  boxShadow: "0px -4px 4px 0px rgba(0, 0, 0, 0.25)",
  padding: "50px 0px",
  zIndex: 9,
  position: "relative",
};

const HackThree = () => {
  const navigate = useNavigate();
  useLayoutConfig("white", true);
  const handleBackClick = () => {
    navigate(-1);
  };

  return (
    <>
      <div className="flex justify-center items-center mt-10 relative">
        <img
          src={backArrow}
          alt=""
          onClick={handleBackClick}
          className="absolute left-5 top-1/2 -translate-y-1/2 w-5"
        />
        <h1
          className={`font-bold text-secondary text-xl text-center flex-grow`}
        >
          CROWDFUNDING
          <br />
          <span className="text-tertiary">HACKS FOR YOU</span>
        </h1>
      </div>
      <div className="text-center p-5 mt-10 mx-5 bg-tertiary rounded-[10px] shadow-[0px_2px_4px_rgba(0,0,0,0.25)]">
        <h2 className="text-xs text-white font-bold">
          BUILDING <span className="text-secondary">CONNECTIONS </span>WITH
          ANYONE: <br /> EFFECTIVE STRATEGIES
        </h2>
        <p className="text-[10px] mt-5 text-white">
          The ability to connect with people from all walks of life is a
          valuable skill, especially in the world of fundraising. Building
          strong connections can open doors to opportunities and support for
          your cause. Here are some effective strategies, along with valuable
          mindset pointers, to help you connect with anyone.
        </p>
      </div>
      <div style={overlayStyle}>
        <div className="relative">
          <div className="w-3/4 mt-5 pb-2 bg-cover bg-[url('assets/images/pages/hacks/note1.png')]">
            <h2 className="font-bold pt-5 pb-7 pl-5 text-secondary text-lg text-left">
              <span className="relative text-secondary before:content-[' '] before:bg-contain before:block before:absolute before:bg-[url('assets/images/icons/underline.webp')] before:w-full before:h-[10px] before:bg-no-repeat before:-bottom-2 inline-block">
                EMPATHIZE AND RELATE
              </span>
            </h2>
          </div>
          <img src={empathize} className="w-24 absolute right-0 top-0" />
          <div className="mx-5 bg-white rounded-[10px] shadow-[0px_2px_4px_rgba(0,0,0,0.25)] p-5">
            <ul className="ml-3">
              <li className="text-xs font-bold text-primary before:content-['\2022'] relative before:top-0 before:-left-2 before:-mt-3 before:absolute before:text-3xl before:text-tertiary before:font-bold before:inline-block before:w-5 before:-ml-4">
                Strategy -
              </li>
              <li className="text-xs mt-2">
                Put yourself in the other person&apos;s shoes and try to
                understand their perspective.
              </li>
              <li className="text-xs mt-4 font-bold text-primary before:content-['\2022'] relative before:top-0 before:-left-2 before:-mt-3 before:absolute before:text-3xl before:text-tertiary before:font-bold before:inline-block before:w-5 before:-ml-4">
                Why -
              </li>
              <li className="text-xs mt-2">
                Empathy fosters a sense of connection and helps you relate to
                their feelings and concerns.
              </li>
              <li className="text-xs mt-4 font-bold text-primary before:content-['\2022'] relative before:top-0 before:-left-2 before:-mt-3 before:absolute before:text-3xl before:text-tertiary before:font-bold before:inline-block before:w-5 before:-ml-4">
                Tip -
              </li>
              <li className="text-xs mt-2">
                Ask about their experiences and share relevant stories to
                establish common ground.
              </li>
            </ul>
          </div>
        </div>

        <div className="relative">
          <div className="flex justify-between mt-16">
            <div></div>
            <div className="w-2/3 pb-2 bg-cover bg-[url('assets/images/pages/hacks/note2.png')]">
              <h2 className="font-bold pt-5 pb-7 pr-5 text-tertiary text-lg text-right">
                EMBRACE
                <br />
                <span className="relative text-secondary before:content-[' '] before:bg-contain before:block before:absolute before:bg-[url('assets/images/icons/underline.webp')] before:w-full before:h-[10px] before:bg-no-repeat before:-bottom-2 inline-block">
                  CONVICTION
                </span>
              </h2>
            </div>
          </div>
          <img src={conviction} className="w-20 absolute left-5 -top-2" />

          <div className="mx-5 mt-3 bg-white rounded-[10px] shadow-[0px_2px_4px_rgba(0,0,0,0.25)] p-5">
            <ul className="ml-3">
              <li className="text-xs font-bold text-primary before:content-['\2022'] relative before:top-0 before:-left-2 before:-mt-3 before:absolute before:text-3xl before:text-tertiary before:font-bold before:inline-block before:w-5 before:-ml-4">
                Strategy -
              </li>
              <li className="text-xs mt-2">
                Cultivate unwavering conviction in your cause and abilities.
              </li>
              <li className="text-xs mt-4 font-bold text-primary before:content-['\2022'] relative before:top-0 before:-left-2 before:-mt-3 before:absolute before:text-3xl before:text-tertiary before:font-bold before:inline-block before:w-5 before:-ml-4">
                Why -
              </li>
              <li className="text-xs mt-2">
                Your confidence is contagious; when you trust yourself, others
                are more likely to trust you.
              </li>
              <li className="text-xs mt-4 font-bold text-primary before:content-['\2022'] relative before:top-0 before:-left-2 before:-mt-3 before:absolute before:text-3xl before:text-tertiary before:font-bold before:inline-block before:w-5 before:-ml-4">
                Tip -
              </li>
              <li className="text-xs mt-2">Trust yourself.</li>
            </ul>
          </div>
        </div>

        <div className="relative">
          <div className="flex mt-16">
            <div className="w-2/3 pb-2 bg-cover bg-[url('assets/images/pages/hacks/note3.png')]">
              <h2 className="font-bold pt-5 pb-7 pl-5 text-primary text-lg text-left">
                DON&apos;T TAKE THINGS
                <br />
                <span className="relative text-secondary before:content-[' '] before:bg-contain before:block before:absolute before:bg-[url('assets/images/icons/underline.webp')] before:w-full before:h-[10px] before:bg-no-repeat before:-bottom-2 inline-block">
                  PERSONALLY
                </span>
              </h2>
            </div>
          </div>
          <img src={personally} className="w-20 absolute right-5 -top-2" />

          <div className="mx-5 mt-3 bg-white rounded-[10px] shadow-[0px_2px_4px_rgba(0,0,0,0.25)] p-5">
            <ul className="ml-3">
              <li className="text-xs font-bold text-primary before:content-['\2022'] relative before:top-0 before:-left-2 before:-mt-3 before:absolute before:text-3xl before:text-tertiary before:font-bold before:inline-block before:w-5 before:-ml-4">
                Strategy -
              </li>
              <li className="text-xs mt-2">
                Develop resilience and avoid taking rejection or criticism
                personally.
              </li>
              <li className="text-xs mt-4 font-bold text-primary before:content-['\2022'] relative before:top-0 before:-left-2 before:-mt-3 before:absolute before:text-3xl before:text-tertiary before:font-bold before:inline-block before:w-5 before:-ml-4">
                Why -
              </li>
              <li className="text-xs mt-2">
                Separating your personal worth from external feedback is
                essential for maintaining emotional well-being and building
                connections.
              </li>
              <li className="text-xs mt-4 font-bold text-primary before:content-['\2022'] relative before:top-0 before:-left-2 before:-mt-3 before:absolute before:text-3xl before:text-tertiary before:font-bold before:inline-block before:w-5 before:-ml-4">
                Tip -
              </li>
              <li className="text-xs mt-2">
                Focus on the bigger picture and the positive impact you can
                make.
              </li>
            </ul>
          </div>
        </div>

        <div className="relative">
          <div className="flex justify-between mt-16">
            <div></div>
            <div className="w-2/4 pb-2 bg-cover bg-[url('assets/images/pages/hacks/note2.png')]">
              <h2 className="font-bold pt-5 pb-7 pr-5 text-tertiary text-lg text-right">
                CURATE CHERISHABLE
                <br />
                <span className="relative text-secondary before:content-[' '] before:bg-contain before:block before:absolute before:bg-[url('assets/images/icons/underline.webp')] before:w-full before:h-[10px] before:bg-no-repeat before:-bottom-2 inline-block">
                  EXPERIENCES:
                </span>
              </h2>
            </div>
          </div>
          <img src={curate} className="w-28 absolute left-5 -top-2" />

          <div className="mx-5 mt-3 bg-white rounded-[10px] shadow-[0px_2px_4px_rgba(0,0,0,0.25)] p-5">
            <ul className="ml-3">
              <li className="text-xs font-bold text-primary before:content-['\2022'] relative before:top-0 before:-left-2 before:-mt-3 before:absolute before:text-3xl before:text-tertiary before:font-bold before:inline-block before:w-5 before:-ml-4">
                Strategy -
              </li>
              <li className="text-xs mt-2">
                Engage people by curating experiences that resonate with them
                and create lasting memories.
              </li>
              <li className="text-xs mt-4 font-bold text-primary before:content-['\2022'] relative before:top-0 before:-left-2 before:-mt-3 before:absolute before:text-3xl before:text-tertiary before:font-bold before:inline-block before:w-5 before:-ml-4">
                Why -
              </li>
              <li className="text-xs mt-2">
                Personalised experiences help build connections and leave a
                positive impression.
              </li>
              <li className="text-xs mt-4 font-bold text-primary before:content-['\2022'] relative before:top-0 before:-left-2 before:-mt-3 before:absolute before:text-3xl before:text-tertiary before:font-bold before:inline-block before:w-5 before:-ml-4">
                Tip -
              </li>
              <li className="text-xs mt-2">
                Try to look at the world from their point of view.
              </li>
            </ul>
          </div>
        </div>

        <div className="relative">
          <div className="flex mt-16">
            <div className="w-2/3 pb-2 bg-cover bg-[url('assets/images/pages/hacks/note3.png')]">
              <h2 className="font-bold pt-5 pb-7 pl-5 text-primary text-lg text-left">
                <span className="relative text-secondary before:content-[' '] before:bg-contain before:block before:absolute before:bg-[url('assets/images/icons/underline.webp')] before:w-full before:h-[10px] before:bg-no-repeat before:-bottom-2 inline-block">
                  Be Authentic
                </span>
              </h2>
            </div>
          </div>
          <img src={authentic} className="w-24 absolute right-5 top-0" />

          <div className="mx-5 mt-3 bg-white rounded-[10px] shadow-[0px_2px_4px_rgba(0,0,0,0.25)] p-5">
            <ul className="ml-3">
              <li className="text-xs font-bold text-primary before:content-['\2022'] relative before:top-0 before:-left-2 before:-mt-3 before:absolute before:text-3xl before:text-tertiary before:font-bold before:inline-block before:w-5 before:-ml-4">
                Strategy -
              </li>
              <li className="text-xs mt-2">
                Be yourself and avoid pretending to be someone you&apos;re not.
              </li>
              <li className="text-xs mt-4 font-bold text-primary before:content-['\2022'] relative before:top-0 before:-left-2 before:-mt-3 before:absolute before:text-3xl before:text-tertiary before:font-bold before:inline-block before:w-5 before:-ml-4">
                Why -
              </li>
              <li className="text-xs mt-2">
                Authenticity builds trust and helps people feel comfortable in
                your presence.
              </li>
              <li className="text-xs mt-4 font-bold text-primary before:content-['\2022'] relative before:top-0 before:-left-2 before:-mt-3 before:absolute before:text-3xl before:text-tertiary before:font-bold before:inline-block before:w-5 before:-ml-4">
                Tip -
              </li>
              <li className="text-xs mt-2">
                Embrace your unique qualities and values; people appreciate
                genuine interactions.
              </li>
            </ul>
          </div>
        </div>

        <div className="relative">
          <div className="flex justify-between mt-16">
            <div></div>
            <div className="w-3/5 pb-2 bg-cover bg-[url('assets/images/pages/hacks/note2.png')]">
              <h2 className="font-bold pt-5 pb-7 pl-5 text-primary text-lg text-right">
                TRUST YOURSELF AND
                <br />
                <span className="relative text-secondary before:content-[' '] before:bg-contain before:block before:absolute before:bg-[url('assets/images/icons/underline.webp')] before:w-full before:h-[8px] before:bg-no-repeat before:-bottom-3 inline-block">
                  BE INDEPENDENT
                </span>
              </h2>
            </div>
          </div>

          <img src={independant} className="w-20 absolute left-10 -top-2" />

          <div className="mx-5 mt-3 bg-white rounded-[10px] shadow-[0px_2px_4px_rgba(0,0,0,0.25)] p-5">
            <ul className="ml-3">
              <li className="text-xs font-bold text-primary before:content-['\2022'] relative before:top-0 before:-left-2 before:-mt-3 before:absolute before:text-3xl before:text-tertiary before:font-bold before:inline-block before:w-5 before:-ml-4">
                Strategy -
              </li>
              <li className="text-xs mt-2">
                Don&apos;t worry about people&apos;s opinions and judgments;
                think for yourself and trust your instincts.
              </li>
              <li className="text-xs mt-4 font-bold text-primary before:content-['\2022'] relative before:top-0 before:-left-2 before:-mt-3 before:absolute before:text-3xl before:text-tertiary before:font-bold before:inline-block before:w-5 before:-ml-4">
                Why -
              </li>
              <li className="text-xs mt-2">
                Self-confidence and independent thinking are key to building
                connections.
              </li>
              <li className="text-xs mt-4 font-bold text-primary before:content-['\2022'] relative before:top-0 before:-left-2 before:-mt-3 before:absolute before:text-3xl before:text-tertiary before:font-bold before:inline-block before:w-5 before:-ml-4">
                Tip -
              </li>
              <li className="text-xs mt-2">
                Embrace your beliefs and trust your ability to make a positive
                impact.
              </li>
            </ul>
          </div>
        </div>
      </div>

      <div className="bg-secondary pt-20 -mt-8 pb-6 -mb-8 text-center">
        <div className="bg-white mx-5 rounded-[10px] shadow-[0px_2px_4px_rgba(0,0,0,0.25)] p-5">
          <h3 className="text-primary font-bold">CONCLUSION</h3>
          <p className="text-[10px] mt-4">
            Connecting with anyone is an essential skill that can help you
            succeed in fundraising and beyond. By combining active listening,
            empathy, authenticity, positive body language, and the mindset
            pointers of trusting yourself, embracing conviction, not taking
            things personally, and curating cherishable experiences, you&apos;ll
            build strong and meaningful connections that can lead to valuable
            support for your cause. Remember, genuine connections are built on
            trust, empathy, respect, and self-assurance.
          </p>
        </div>
        <div className="mx-5 rounded-[10px] py-5">
          <div className="border-white border-t-[1px] max-w-[75%] m-auto" />
          <div className="flex justify-between mt-5">
            <button
              className="bg-tertiary text-white py-2 px-3 text-xs rounded-lg disabled:bg-[#d9d9d9] disabled:cursor-not-allowed"
              onClick={() => {
                navigate("/user/hacks/hack-2");
              }}
            >
              BACK
            </button>

            <button
              className="bg-primary text-white py-2 px-3 text-xs rounded-lg disabled:bg-[#d9d9d9] disabled:cursor-not-allowed"
              onClick={() => {
                navigate("/user/hacks/hack-4");
              }}
            >
              NEXT HACK
            </button>
          </div>
        </div>
      </div>
    </>
  );
};

export default HackThree;
