import { db } from "config/firebase";
import { getRandomColor } from "config/helpers";
import {
  collection,
  getDocs,
  limit,
  orderBy,
  query,
  where,
} from "firebase/firestore";
import { createContext, useEffect, useState } from "react";

export const TrainingModuleDiscussionContext = createContext();

const TrainingModuleDiscussionProvider = ({ children }) => {
  const [discussion, setDiscussion] = useState({
    "module-1": [],
    "module-2": [],
  });

  useEffect(() => {
    const getModuleOneData = async () => {
      const ref = collection(db, "training-modules", "module-1", "discussion");

      const q = query(
        ref,
        where("isApproved", "==", true),
        orderBy("timestamp", "desc"),
        limit(10),
      );

      const snapshot = await getDocs(q);

      return snapshot.docs.map((doc) => ({
        id: doc.id,
        color: getRandomColor(),
        ...doc.data(),
      }));
    };

    const getModuleTwoData = async () => {
      const ref = collection(db, "training-modules", "module-2", "discussion");

      const q = query(
        ref,
        where("isApproved", "==", true),
        orderBy("timestamp", "desc"),
        limit(10),
      );

      const snapshot = await getDocs(q);

      return snapshot.docs.map((doc) => ({
        id: doc.id,
        color: getRandomColor(),
        ...doc.data(),
      }));
    };

    const setup = async () => {
      const moduleOneData = await getModuleOneData();
      const moduleTwoData = await getModuleTwoData();

      setDiscussion({ "module-1": moduleOneData, "module-2": moduleTwoData });
    };

    setup();
  }, []);

  return (
    <TrainingModuleDiscussionContext.Provider value={{ discussion }}>
      {children}
    </TrainingModuleDiscussionContext.Provider>
  );
};

export default TrainingModuleDiscussionProvider;
