import { AuthContext } from "context/Auth";
import { useContext, useEffect, useState } from "react";

const ProgressLine = ({ question, totalQuestions }) => (
  <div className="bg-white h-1 w-full">
    <div
      className={`bg-secondary h-1`}
      style={{
        width: `${((question + 1) / totalQuestions) * 100}%`,
      }}
    ></div>
  </div>
);

const Questions = ({
  assignment,
  question,
  setStep,
  setQuestion,
  finalAnswers,
  setFinalAnswers,
}) => {
  const [answers, setAnswers] = useState([]);
  const { profile, setProfile } = useContext(AuthContext);

  useEffect(() => {
    setAnswers(finalAnswers);
  }, [finalAnswers]);

  const getClassNames = (option) => {
    let classnames = "";
    if (finalAnswers[question]) {
      if (assignment.questions[question].answer === option) {
        classnames += "bg-green-300 text-[#000!important]";
      } else {
        if (answers[question] === option) {
          classnames += "bg-red-300 text-[#000!important]";
        }
      }
    } else {
      if (answers[question] === option) {
        classnames += "bg-tertiary text-white";
      }
    }
    return classnames;
  };

  return (
    <>
      <p className="text-left w-full text-xs text-white mb-3">
        {question + 1} of {assignment.questions.length}
      </p>
      <ProgressLine
        question={question}
        totalQuestions={assignment.questions.length}
      />
      <div className="bg-white mt-6 p-5 rounded-[25px]">
        <ol
          className="text-black text-xl text-left font-bold my-6 list-decimal pl-5"
          start={question + 1}
        >
          <li>{assignment.questions[question].question}</li>
        </ol>
        {assignment.questions[question].options.map((option, index) => (
          <div
            onClick={() => {
              if (finalAnswers[question]) return;
              const tempAnswers = [...answers];
              tempAnswers[question] = option;
              setAnswers(tempAnswers);
            }}
            className={`flex items-center mt-4 text-left rounded-[28.5px] shadow-[1px_3px_8px_0_rgba(0,0,0,0.25)] p-4 cursor-pointer ${getClassNames(
              option,
            )}`}
            key={index}
          >
            <input
              hidden
              type="radio"
              name="option"
              readOnly
              id={`option-${index}`}
              checked={answers[question] === option}
              className="mr-4"
            />
            <ol className="list-[lower-alpha] pl-4" start={index + 1}>
              <li className={`text-base`}>{option}</li>
            </ol>
          </div>
        ))}
        <div className="w-full text-right mt-8">
          <button
            disabled={!answers[question] || finalAnswers[question]}
            className="mr-2 bg-secondary disabled:bg-[#aaa] w-fit rounded-[11px] px-6 py-4 shadow-[1px_3px_8px_0_rgba(0,0,0,0.5)] text-xs text-white font-bold"
            onClick={() => {
              setFinalAnswers(answers);
              setProfile({
                ...profile,
                assignments: {
                  "assignment-1": answers,
                },
              });
            }}
          >
            SUBMIT
          </button>
          <button
            disabled={!finalAnswers[question]}
            className="bg-secondary disabled:bg-[#aaa] w-fit rounded-[11px] px-6 py-4 shadow-[1px_3px_8px_0_rgba(0,0,0,0.5)] text-xs text-white font-bold"
            onClick={() => {
              if (question === assignment.questions.length - 1) {
                setStep(2);
                setFinalAnswers(answers);
              } else {
                setQuestion(question + 1);
              }
            }}
          >
            {question === assignment.questions.length - 1 ? "FINISH" : "NEXT"}
          </button>
        </div>
      </div>
    </>
  );
};

export default Questions;
