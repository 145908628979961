import checks from "assets/images/backgrounds/checks.png";
import CopyReferenceCode from "components/CopyReferenceCode";
import DaysLeft from "components/DaysLeft";
import Profile from "components/Profile";
import useDocumentTitle from "hooks/useDocumentTitle";
import useLayoutConfig from "hooks/useLayoutConfig";
import StudentWithLaptop from "assets/images/pages/orientation/student-laptop.png";
import { useNavigate } from "react-router-dom";

const overlayStyle = {
  backgroundPosition: "center center",
  borderRadius: "50px 50px 35px 35px",
  backgroundColor: "#fff",
  backgroundImage: `url(${checks})`,
};

const Orientation = () => {
  const navigate = useNavigate();
  useLayoutConfig("red", false);
  useDocumentTitle("Muskurahat Foundation | Explore");

  return (
    <div>
      <div className="flex m-4">
        <div className="w-1/2">
          <DaysLeft />
        </div>
        <div className="w-1/2">
          <div className="flex justify-end gap-4">
            <CopyReferenceCode />
            <Profile />
          </div>
        </div>
      </div>
      <div className="text-center text-[32px] leading-[35px] font-semibold text-white my-5">
        <h1>WELCOME TO</h1>
        <h1>muskurahat</h1>
      </div>
      <div style={overlayStyle}>
        <div className="text-center py-8">
          <h4 className="text-secondary max-w-[300px] m-auto text-[20px] leading-[30px] font-medium">
            We&apos;ve designed an interactive journey to give you an{" "}
            <span className="text-primary font-semibold">
              in-depth understanding
            </span>{" "}
            about Muskurahat & your internship.
          </h4>
          <button
            onClick={() => {
              navigate("/user/orientation/chapter-1");
            }}
            className="bg-secondary rounded-[9px] margin-auto mt-5 text-white px-5 py-3 text-xl font-bold"
          >
            Your Journey Begins Here ✨
          </button>
          <img
            src={StudentWithLaptop}
            alt="student with laptop"
            className="w-[300px] m-auto mt-24"
          />
        </div>
      </div>
    </div>
  );
};

export default Orientation;
