import { createContext, useState } from "react";

export const LayoutContext = createContext();

const LayoutProvider = ({ children }) => {
  const [background, setBackground] = useState("red");
  const [showBottomMenu, setShowBottomMenu] = useState(false);
  return (
    <LayoutContext.Provider
      value={{ background, setBackground, showBottomMenu, setShowBottomMenu }}
    >
      {children}
    </LayoutContext.Provider>
  );
};

export default LayoutProvider;
