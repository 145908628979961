import angle from "assets/images/icons/angle.png";
import assignment from "assets/images/pages/explore/assignment.png";
import assignments from "config/assignments";
import { AuthContext } from "context/Auth";
import { useContext } from "react";
import { useNavigate } from "react-router-dom";

const Button = () => {
  const { profile } = useContext(AuthContext);
  const navigate = useNavigate();
  const getScore = () => {
    let score = 0;
    profile?.assignments &&
      profile?.assignments["assignment-1"].forEach((answer, index) => {
        assignments["assignment-1"].questions[index].answer === answer &&
          score++;
      });

    return `${score}/7`;
  };
  return (
    <div className="relative">
      <div className="relative">
        <button
          disabled={
            profile?.assignments &&
            profile?.assignments["assignment-1"].length === 7
          }
          onClick={() => {
            navigate("/user/assignments/assignment-1");
          }}
          className="bg-tertiary mt-10 h-32 rounded-2xl flex justify-center items-center disabled:cursor-not-allowed"
          data-before="You have already completed this assignment"
        >
          <div className=" text-white font-bold ml-4 basis-[60%]">
            <h3 className="text-2xl">
              {profile?.assignments &&
              profile?.assignments["assignment-1"].length === 7
                ? "ASSIGNMENT COMPLETE ✅"
                : "ASSIGNMENT ✍"}
            </h3>
          </div>
          <div className="basis-[40%] -mt-8">
            <img src={assignment} alt="" />
          </div>
        </button>
      </div>
      {profile?.assignments && profile?.assignments["assignment-1"] && (
        <div className="flex justify-end items-center gap-x-[10px]">
          <img src={angle} className="w-[25px]" />
          <span className="text-xs text-primary underline mt-2">
            Score: {getScore()}
          </span>
        </div>
      )}
    </div>
  );
};

export default Button;
