import angle from "assets/images/icons/angle.png";
import { TrainingModulesContext } from "context/Data/TrainingModules";
import { useContext } from "react";

const ByLine = ({ id }) => {
  const { modules, isLoading } = useContext(TrainingModulesContext);

  const getByLine = () => {
    if (isLoading) return "Loading...";
    const moduleDoc = modules.find((m) => m.id === id);
    // get max reactions from moduleDoc
    const reaction1 = {
      count: moduleDoc?.reactions?.reaction1 || 0,
      label: "found this Mindblowing",
    };
    const reaction2 = {
      count: moduleDoc?.reactions?.reaction2 || 0,
      label: "learned something new",
    };
    const reaction3 = {
      count: moduleDoc?.reactions?.reaction3 || 0,
      label: "already knew this information",
    };
    const reaction4 = {
      count: moduleDoc?.reactions?.reaction4 || 0,
      label: "understood some parts of it",
    };
    const reaction5 = {
      count: moduleDoc?.reactions?.reaction5 || 0,
      label: "found it difficult to understand",
    };

    const totalReactions =
      reaction1.count +
      reaction2.count +
      reaction3.count +
      reaction4.count +
      reaction5.count;

    const maxReactions = [
      reaction1,
      reaction2,
      reaction3,
      reaction4,
      reaction5,
    ].reduce(
      (prev, current) => (prev.count > current.count ? prev : current),
      0,
    );

    const percentage = Math.round((maxReactions.count / totalReactions) * 100);

    // get variable with max reactions

    const byLine = `${percentage}% ${maxReactions.label}`;

    return byLine;
  };
  return (
    <div className="flex justify-end items-center gap-x-[10px]">
      <img src={angle} className="w-[25px]" />
      {isLoading ? (
        ""
      ) : (
        <span className="text-xs text-primary underline mt-2">
          {getByLine()}
        </span>
      )}
    </div>
  );
};

export default ByLine;
