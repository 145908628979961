const HomeIcon = ({ isActive }) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    viewBox="0 0 420.33 414.54"
    height="24px"
    width="24px"
    className="m-auto"
  >
    <g id="Layer_2" data-name="Layer 2">
      <g id="Layer_1-2" data-name="Layer 1">
        <path
          fill="none"
          className={isActive ? "stroke-primary" : "stroke-black"}
          strokeWidth="17.59px"
          strokeLinecap="round"
          strokeLinejoin="round"
          d="M8.8,233.52s62.37-77.78,97-111.66c25.87-25.29,74.9-66.88,98.11-86.35a18.56,18.56,0,0,1,24.77.81c26,24.88,86.13,82.36,98.13,92.86,15.4,13.47,84.71,72.3,84.71,89.47"
        />
        <path
          fill="none"
          d="M65,239.33a649,649,0,0,1,80.09-83.94c47-40.81,81.62-65.18,81.62-65.18s44.67,51.6,67.77,74.56S363,220.08,363.79,227s-12.32,70.84-13.09,82.39-9.24,73.93-12.32,78.55-6.93,10.78-17.71,11.55-62.37,5.39-67.76-6.93-1.54-127.06-4.62-127.06-62.38-4.62-62.38-.77S183.6,396.31,181.29,401s-79.32,7.65-83.17,0S65,239.33,65,239.33Z"
        />
        <path
          fill="none"
          strokeLinecap="round"
          strokeLinejoin="round"
          strokeWidth="20.11px"
          className={isActive ? "stroke-primary" : "stroke-black"}
          d="M65,238.14A649.69,649.69,0,0,1,145.1,154.2c47-40.81,81.62-65.18,81.62-65.18s44.67,51.6,67.77,74.56,68.53,55.31,69.3,62.24-12.32,70.84-13.09,82.4-9.24,73.92-12.32,78.54-6.93,10.78-17.71,11.55-62.37,5.39-67.76-6.93-1.54-127.06-4.62-127.06-62.38-4.62-62.38-.77-2.31,131.57-4.62,136.25-79.32,7.64-83.17,0S65,238.14,65,238.14Z"
        />
        <path
          fill="none"
          d="M306.48,106.84V15.57S341-1,367.47,8.83s2,99.3,0,114.56-5.58,33.93-5.58,33.93Z"
        />
        <path
          fill="none"
          strokeLinecap="round"
          strokeWidth="17.59px"
          strokeLinejoin="round"
          className={isActive ? "stroke-primary" : "stroke-black"}
          d="M306.48,109.91V18.64s34.54-16.53,61-6.74,2,99.3,0,114.56-5.58,33.93-5.58,33.93Z"
        />
      </g>
    </g>
  </svg>
);

export default HomeIcon;
