import useAuth from "hooks/useAuth";
import { createContext } from "react";

export const AuthContext = createContext();

const AuthProvider = ({ children }) => {
  const {
    profile,
    setProfile,
    isLoading: isUserLoading,
    signIn,
    signInWithEmail,
    signOut,
    user,
  } = useAuth();
  return (
    <AuthContext.Provider
      value={{
        profile,
        setProfile,
        isUserLoading,
        signIn,
        signInWithEmail,
        signOut,
        user,
      }}
    >
      {children}
    </AuthContext.Provider>
  );
};

export default AuthProvider;
