import { AuthContext } from "context/Auth";
import useDocumentTitle from "hooks/useDocumentTitle";
import { useContext, useEffect, useState } from "react";
import { useNavigate } from "react-router";

const StudentLogin = () => {
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");

  const { signInWithEmail, user } = useContext(AuthContext);
  const navigate = useNavigate();
  useDocumentTitle("Muskurahat Foundation | Login");

  useEffect(() => {
    if (user) {
      navigate("/user/dashboard");
    }
  }, [user]);

  return (
    <div className="px-2 text-center flex items-center justify-center flex-col h-screen">
      <div className="text-white text-left max-w-[400px]">
        <label>Username</label>
        <input
          value={email}
          onChange={(e) => setEmail(e.target.value)}
          type="text"
          className="w-full border-2 border-gray-300 p-2 rounded-lg mb-5 text-black"
          placeholder="Enter your username"
        />
        <label>Password</label>
        <input
          value={password}
          onChange={(e) => setPassword(e.target.value)}
          type="password"
          className="w-full border-2 border-gray-300 p-2 rounded-lg mb-5 text-black"
          placeholder="Enter your password"
        />
        <button
          className="w-full bg-secondary text-white p-2 rounded-lg"
          onClick={() =>
            signInWithEmail(email + "@muskurahat-schools.org.in", password)
          }
        >
          Login
        </button>
      </div>
    </div>
  );
};

export default StudentLogin;
