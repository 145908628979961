import "swiper/css";
import { Swiper } from "swiper/react";

const CustomCarousel = ({
  slidesPerView = 4,
  spaceBetween = 30,

  children,
}) => (
  <Swiper
    slidesPerView={slidesPerView}
    spaceBetween={spaceBetween}
    centeredSlides={false}
  >
    {children}
  </Swiper>
);

export default CustomCarousel;
