import { AuthContext } from "context/Auth";
import { useContext } from "react";

const StartScreen = ({ assignment, setStep }) => {
  const { profile } = useContext(AuthContext);
  return (
    <>
      <h1 className="text-white text-4xl text-center mb-8 font-bold">
        {assignment.title}
      </h1>
      <p className="text-white text-base text-center mb-8">
        {assignment.description}
      </p>
      <button
        className="bg-white w-fit rounded-3xl px-10 py-6 shadow-[1px_3px_8px_0_rgba(0,0,0,0.5)] text-xl text-secondary font-bold mb-[280px]"
        onClick={() => {
          setStep(1);
        }}
      >
        {profile?.assignments && profile?.assignments["assignment-1"]
          ? "RESUME TEST"
          : "BEGIN TEST"}
      </button>
    </>
  );
};

export default StartScreen;
